import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
import Button from '@material-ui/core/Button';

// components
import MonthlySalesAmount from '../../Common/MonthlySalesAmount';

// material Ui
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core//Checkbox';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

// util 
import { showTaxIncludedAmount } from '../../../../utils';

// const 
import { CONSUMPTION_TAX } from '../../../../const';

// dayjs
import dayjs from 'dayjs';
import { Typography } from '@material-ui/core';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/objectSupport'));
dayjs.tz.setDefault('Asia/Tokyo');

const drawerWidth = 250;

const styles = (theme) =>
	({
		homeWrapper : {
			width           : '100%',
			height          : '100%',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			overflowX       : 'auto',
			backgroundColor : '#FFF',
			borderRadius    : '5px'
		},
		monthlyWrapper : {
			width           : '100%',
			height          : '100%',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			backgroundColor : 'transparent',
			borderRadius    : '5px'
		},
		root :
		{
			width                        : '100%',
			height                       : '100%',
			display                      : 'flex',
			flexDirection                : 'column',
			maxWidth                     : '1670px',
			padding                      : '0 20px',
			position                     : 'relative',
			color                        : 'var(--text-color)',
			fontSize                     : '0.9rem',
			overflowX                    : 'auto',
			[theme.breakpoints.up('sm')] : {
				width : `calc( 100vw - ${drawerWidth+40}px )`
			}
		},
		homeInfoSection : {
			marginTop : '10px'
		},
		infoBox : {
			width     : '100%',
			maxWidth  : '1290px',
			border    : '1px solid #292929',
			position  : 'relative',
			height    : '125px',
			marginTop : '40px',
			padding   : '8px'
		},
		infoTitle : {
			backgroundColor : '#FFF',
			position        : 'absolute',
			top             : '-18px',
			left            : '40px',
			height          : '20px',
			width           : '120px',
			textAlign       : 'center'
		},
		infoContentBox : {
			width     : '100%',
			height    : '100%',
			overflow  : 'auto',
			listStyle : 'inside'
		},
		infoContent : {
			width     : '100%',
			marginTop : '10px'
		},
		infoContentItem : {
			display    : 'list-item',
			color      : '#292929',
			fontSize   : '0.8rem',
			margin     : '3px 5px',
			fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
		},
		infoContentTitle : {
			textDecoration : 'underline',
			cursor         : 'pointer'
		},
		notificationDate : {
			marginRight : '14px'
		},
		homeContentSection : {
			minHeight : '400px',
			marginTop : '20px'
		},
		homeContentBox : {
			width        : '100%',
			maxWidth     : '1290px',
			minHeight    : '180px',
			marginTop    : '50px',
			padding      : '15px',
			borderRadius : '10px',
			boxShadow    : '1px 1px 3px #bbb inset'
		},
		homeContentBoxSold : {
			backgroundColor : '#F1FFFF'
		},
		homeContentBoxPurchased : {
			backgroundColor : '#FFE7F0'
		},
		detailButton : {
			marginTop : '15px',
			color     : '#FFF',
			padding   : '2px 30px'
		},
		detailButtonSales : {
			backgroundColor : '#036EB6',
			'&:hover'       :
			{
				backgroundColor : '#036EB6'
			}
		},
		detailButtonPurchase : {
			backgroundColor : '#CD2C82',
			'&:hover'       :
			{
				backgroundColor : '#CD2C82'
			}
		},
		listHeaderSection : {
			marginTop : '10px'
		},
		listActionSection : {
			marginTop : '10px'
		},
		listTableSection : {
			marginTop : '10px'
		},
		title : {
			color      : '#292929',
			fontSize   : '1.1rem',
			fontWeight : 'bold'
		},
		tableSection : {
			overflowY       : 'auto',
			flexGrow        : '1',
			padding         : '10px',
			backgroundColor : '#FFF',
			borderRadius    : '10px'
		},
		tableContainer : {
			maxHeight : '100%'
		},
		table : {
			overflowX : 'hidden'
		},
		navigationSection :
		{
			marginTop      : '10px',
			display        : 'flex',
			justifyContent : 'flex-between',
			fontSize       : '1rem',
			marginBottom   : '10px'
		},
		dateNavigation : {
			display        : 'flex',
			justifyContent : 'flex-start',
			color          : '#292929'
		},
		dateNavigationButtons : {
			display        : 'flex',
			justifyContent : 'flex-between',
			marginLeft     : '10px'
		},
		dateNavigationButton : {
			height   : '23px',
			padding  : '0 10px',
			fontSize : '0.9rem',
			color    : '#292929',
			border   : '1px solid #292929'
		},
		dateNavigationCurrent : {
			width     : '150px',
			textAlign : 'center'
		},
		exportButtonWrapper : {
			marginLeft : '40px',
			marginTop  : '-2px'
		},
		exportButton : {
			height   : '23px',
			padding  : '0 10px',
			fontSize : '0.9rem',
			color    : '#292929',
			border   : '1px solid #292929'
		},
		tableBg :
		{
			display  : 'flex',
			position : 'absolute',
			height   : '80%',
			width    : '92%',
			minWidth : 1205,
			zIndex   : '0',
			overflow : 'auto'
		},
		tableBgLine :
		{
			height      : '100%',
			borderRight : '1px solid #ccc'
		},
		headerSection : {
			backgroundColor : '#FFF',
			width           : '100%',
			borderRadius    : '20px',
			padding         : '20px 20px 21px 20px'
		},
		tableHeader :
		{
			paddingTop : '10px',
			display    : 'flex',
			width      : '100%'
		},
		tableHeaderSales : {
			borderTop : '2px solid #0A6EB2'
		},
		tableHeaderPurchase : {
			borderTop : '2px solid #CC2E80'
		},
		tableHeaderText : {
			fontSize  : '0.8rem',
			textAlign : 'center',
			padding   : '1rem 0'
		},
		tableData :
		{
			display      : 'flex',
			listStyle    : 'none',
			marginBottom : '0.8rem',
			width        : '100%'
		},
		tableDataBg : {
			display      : 'flex',
			width        : '100%',
			border       : 'none',
			borderRadius : '0.5rem',
			background   : 'transparent',
			alignItems   : 'center',
			zIndex       : 1
		},
		tableDataValues : {
			display      : 'flex',
			width        : '100%',
			border       : 'none',
			borderRadius : '0.5rem',
			background   : 'rgba(0,0,0,0.1)',
			alignItems   : 'center',
			zIndex       : 1
		},
		tableDataValue : {
			paddingTop    : '1.2rem',
			paddingBottom : '1rem',
			margin        : '0 0 0 0',
			color         : '#888',
			textAlign     : 'center',
			fontSize      : '0.8rem'
		},
		commissionPercent : {
			borderRight : '1px dashed rgba(224, 224, 224, 1) !important'
		},
		code :
		{
			width          : '65%',
			paddingLeft    : '0.5%',
			paddingRight   : '0.5%',
			display        : 'flex',
			justifyContent : 'flex-between',
			'& > input'    : {
				backgroundColor : 'white',
				border          : '1px solid #ccc',
				borderRadius    : '0.5rem',
				padding         : '0.3rem 0.5rem',
				boxShadow       : '2px 2px 4px inset #eee',
				color           : '#999898',
				fontSize        : '0.9rem',
				width           : '95%'
			}
		},
		datetime :
		{
			display        : 'flex',
			justifyContent : 'center',
			width          : '17%'
		},
		codeBg : {
			textAlign : 'left'
		},
		datetimeBg : {
			width        : '80%',
			background   : '#999898',
			padding      : '0.2rem 0.3rem',
			borderRadius : '0.3rem',
			color        : 'white',
			textAlign    : 'center',
			height       : '1.5rem'
		},
		center :
		{
			width        : `calc( 100% - ${drawerWidth}px )`,
			height       : 'calc( 100% - 40px )',
			overflow     : 'auto',
			marginTop    : '5px',
			marginBottom : '1%'
		},
		adminButtons :
		{
			width          : '100%',
			height         : '40px',
			display        : 'flex',
			justifyContent : 'end'
		},
		adminBtn :
		{
			background   : '#e26b04',
			border       : '1px solid #e26b04',
			borderRadius : '0.3rem',
			color        : '#fff',
			padding      : '0.1rem 1rem',
			fontSize     : '0.8rem',
			'&:hover'    :
			{
				backgroundColor : '#fff',
				color           : '#e26b04',
				opacity         : 0.8
			}
		},
		codeValue : {
			width : 'calc( 100% - 50px )'
		},
		codeBtn :
		{
			width : '50px'
		},
		valueInput : {
			'&:disabled' : {
				backgroundColor : '#F7F9F9'
			}
		},
		drawer : {
			[theme.breakpoints.up('sm')] : {
				width : drawerWidth
			}

		},
		toolbar     : theme.mixins.toolbar,
		drawerPaper : {
			width : drawerWidth
		},
		drawerBox : {
			display        : 'flex',
			flexDirection  : 'column',
			flexShrink     : 0,
			height         : '100%',
			justifyContent : 'space-between'

		},
		selectedItem : {
			backgroundColor : '#ffdc00',
			color           : '#FFF',
			'&:hover'       :
			{
				backgroundColor : '#ffdc00',
				color           : '#FFF'
			}
		},
		appBar : {
			backgroundColor : '#ffdc00',
			transition      : theme.transitions.create([ 'margin', 'width' ], {
				easing   : theme.transitions.easing.sharp,
				duration : theme.transitions.duration.leavingScreen
			}),
			[theme.breakpoints.up('sm')] : {
				display : 'none'
			}
		},
		appBarShift : {
			width      : `calc(100% - ${drawerWidth}px)`,
			marginLeft : drawerWidth,
			transition : theme.transitions.create([ 'margin', 'width' ], {
				easing   : theme.transitions.easing.easeOut,
				duration : theme.transitions.duration.enteringScreen
			}),
			[theme.breakpoints.up('sm')] : {
				display : 'none'
			}
		},
		menuButton : {
			marginRight : theme.spacing(2)
		},
		hide : {
			display : 'none'
		},
		drawerHeader : {
			display                      : 'flex',
			alignItems                   : 'center',
			padding                      : theme.spacing(0, 1),
			justifyContent               : 'flex-end',
			[theme.breakpoints.up('sm')] : {
				display : 'none'
			}
		},
		drawerText : {
			fontSize : '0.9rem'
		},
		selectedDrawerItemIcon : {
			color : '#FFF'
		},
		content : {
			flexGrow : 1,
			padding  : theme.spacing(3)
		},
		drawerLogoutBtnSection : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'space-between',
			alignItems     : 'center',
			marginBottom   : '10px'
		},
		drawerLogoutBtn : {
			backgroundColor : '#343532',
			padding         : '3px 15px',
			color           : '#FFF',
			'&:hover'       :
			{
				backgroundColor : '#484A46'
			}
		},
		drawerBtmDevider : {
			backgroundColor : 'rgba(0, 0, 0, 0.20)',
			margin          : '10px 0 5px 0',
			width           : '100%'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiTableCell : {
			root : {
				borderRight : '1px solid rgba(224, 224, 224, 1)'
			}
		}
	}
});

const today = new Date();

const thisYear = today.getFullYear();

const thisMonth = today.getMonth()+1;

let nextMonth = thisMonth+1;

let nextMonthYear = thisYear;

if (nextMonth > 12)
{
	nextMonth = nextMonth - 12;
	nextMonthYear = nextMonthYear + 1;
}

const showNumberHint = (accountIdentifier, numberHint) =>
{
	if (accountIdentifier && numberHint)
	{
		return `${accountIdentifier?.toUpperCase()}********${numberHint}`;
	}
	else
	{
		return '';
	}
};

const showMinutesFromSeconds = (s) =>
{
	if (s === -1)
	{
		return '無制限';
	}
	else if (s)
	{
		return Math.ceil(s/60);
	}
	else
	{
		return 0;
	}
};

const calcMinutesFromSeconds = (s) =>
{
	if (s === -1)
	{
		return null;
	}
	else if (s)
	{
		return Math.ceil(s/60);
	}
	else
	{
		return 0;
	}
};

const includeConsumptionTax = (amount) =>
{

	return Math.floor(amount*(1+CONSUMPTION_TAX));
};

const ConnectUrls = ({
	classes,
	connections,
	setConnections
}) =>
{

	const [ purchasedAmount, setPurchasedAmount ] = useState(null);

	const [ holesalePurchasedAmount, setHolesalePurchasedAmount ] = useState(null);

	const [ purchasedCount, setPurchasedCount ] = useState(0);

	const [ connectionsFiltered, setConnectionsFiltered ] = useState([]);

	const [ targetDate, setTargetDate ] = useState(
		{
			year          : thisYear,
			month         : thisMonth,
			nextMonthYear : nextMonthYear,
			nextMonth     : nextMonth
		}
	);

	useEffect(() =>
	{

		const date = dayjs({
			y : targetDate.year,
			M : targetDate.month -1,
			d : 1
		});

		let salesAmountVal = 0;

		let holesaleAmountVal = 0;

		let purchaseCount = 0;

		const startMonth = date.startOf('month').valueOf();

		const endMonth = date.endOf('month').valueOf();

		const items = [];

		connections.forEach((item) =>
		{
			if (item.createdAt >= startMonth && item.createdAt <= endMonth)
			{

				if (item.orderCanceled)
				{
					items.push({
						...item,
						selected       : item.selected ? item.selected : false,
						salesAmount    : 0,
						holesaleAmount : 0
					});
				}
				else
				{
					items.push({
						...item,
						selected : item.selected ? item.selected : false
					});
					purchaseCount += 1;
					salesAmountVal += includeConsumptionTax(item.salesAmount);
					holesaleAmountVal += includeConsumptionTax(item.holesaleAmount);
				}
			}
		});

		setPurchasedAmount(salesAmountVal);
		setPurchasedCount(purchaseCount);
		setHolesalePurchasedAmount(holesaleAmountVal);

		items.sort((a, b) =>
		{
			if (a.salesAmount < b.salesAmount) { return -1; }
			if (a.salesAmount > b.salesAmount) { return 1; }

			return 0;
		});

		items.sort((a, b) =>
		{
			if (a.createdAt < b.createdAt) { return 1; }
			if (a.createdAt > b.createdAt) { return -1; }

			return 0;
		});

		setConnectionsFiltered([ ...items ]);

	}, [ connections, targetDate ]);

	const showDateTime = (timestamp) =>
	{
		if (!timestamp)
		{
			return '';
		}
		else
		{
			return dayjs(timestamp).format('YYYY.MM.DD HH:mm');
		}
	};

	const togglePurchasedConnectionFiltered = (itemId) =>
	{
		setConnections((state) =>
		{
			const items = [ ...state ];

			const targetIndex = items.findIndex((item) => item.id === itemId);

			if (targetIndex !== -1)
			{
				items[targetIndex].selected = !items[targetIndex].selected;
			}

			return [ ...items ];
		});
	};

	const changeTargetMonth = (val) =>
	{
		setTargetDate((state) =>
		{
			let newMonth = state.month+val;

			let newYear = state.year;

			if (newMonth < 1)
			{
				newYear = newYear - 1;
				newMonth = 12;
			}
			else if (newMonth > 12)
			{
				newYear = newYear + 1;
				newMonth = 1;
			}

			let newNextMonthYear = newYear;

			let newNextMonth = newMonth + 1;

			if (newNextMonth > 12)
			{
				newNextMonthYear = newNextMonthYear + 1;
				newNextMonth = 1;
			}

			return {
				year          : newYear,
				month         : newMonth,
				nextMonthYear : newNextMonthYear,
				nextMonth     : newNextMonth
			};
		});

	};

	const downloadFile = ({ data, fileName, fileType }) =>
	{
		const bom = new Uint8Array([ 0xEF, 0xBB, 0xBF ]);
		const blob = new Blob([ bom, data ], { type: fileType });
		const a = document.createElement('a');

		a.download = fileName;
		a.href = window.URL.createObjectURL(blob);

		const clickEvent = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });

		a.dispatchEvent(clickEvent);
		a.remove();
	};

	const exportPurchaseToCsv = () =>
	{
		const headers = [ '日付,接続コード,メールアドレス,追加メール送信先1,追加メール送信先2,利用分数,御社販売価格, 卸売価格' ];

		const body = [];

		connectionsFiltered.forEach((d) =>
		{
			const { createdAt,
				accountParamKey,
				numberHint,
				email,
				additionalEmail1,
				additionalEmail2,
				duration,
				salesAmount,
				holesaleAmount,
				orderCanceled
			} = d;

			body.push([ showDateTime(createdAt), orderCanceled ? '購入キャンセル' : showNumberHint(accountParamKey, numberHint), email, additionalEmail1, additionalEmail2, calcMinutesFromSeconds(duration), salesAmount, holesaleAmount ].join(','));
		});

		downloadFile({
			data     : [ ...headers, ...body ].join('\n'),
			fileName : 'purchase.csv',
			fileType : 'text/csv'
		});
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.monthlyWrapper}>
				<Box className={classes.root}>
					<Box className={classes.headerSection}>
						<Box className={`${classes.tableHeader} ${classes.tableHeaderPurchase}`}>
							<Typography className={classes.title}>
								購入情報
							</Typography>
						</Box>
						< MonthlySalesAmount
							originPage={'manage'}
							data={'purchase'}
							year={targetDate.year}
							month={targetDate.month}
							paymentYear={targetDate.nextMonthYear}
							paymentMonth={targetDate.nextMonth}
							ticketsListLength={purchasedCount}
							salesAmount={purchasedAmount}
							holesaleAmount={holesalePurchasedAmount}
							commissionAmount={null}
						/>
					</Box>
					<Box className={classes.navigationSection}>
						<Box className={classes.dateNavigation}>
							<Box className={classes.dateNavigationButtons}>
								<Button
									className={classes.dateNavigationButton}
									startIcon={<NavigateBeforeIcon />}
									onClick={() => changeTargetMonth(-1)}
								>
									前月
								</Button>
								<Box className={classes.dateNavigationCurrent}>
									{targetDate.year}年{targetDate.month}月分
								</Box>
								{ (thisYear !== targetDate?.year || thisMonth !== targetDate?.month) &&
									<Button
										className={classes.dateNavigationButton}
										startIcon={<NavigateNextIcon />}
										onClick={() => changeTargetMonth(1)}
									>
										翌月
									</Button>
								}
							</Box>
						</Box>

						<Box className={classes.exportButtonWrapper}>
							<Button
								className={classes.exportButton}
								onClick={exportPurchaseToCsv}
							>
								CSV出力
							</Button>
						</Box>
					</Box>

					<Box className={classes.tableSection}>
						<TableContainer component={Paper}>
							<Table stickyHeader className={classes.table} aria-label='simple table'>
								<TableHead>
									<TableRow>
										<TableCell> </TableCell>
										<TableCell align='center'>日付</TableCell>
										<TableCell align='center'>接続コード</TableCell>
										<TableCell align='center'>追加メール送信先1</TableCell>
										<TableCell align='center'>追加メール送信先2</TableCell>
										<TableCell align='center'>利用分数</TableCell>
										<TableCell align='center'>定価</TableCell>
										<TableCell align='center'>御社購入価格</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{connectionsFiltered.map((row) => (
										<TableRow key={row.id}>
											<TableCell padding='checkbox' align='center'>
												<Checkbox
													checked={row.selected}
													inputProps={{ 'aria-labelledby': `checkBox-${row.id}` }}
													onClick={() => togglePurchasedConnectionFiltered(row.id)}
												/>
											</TableCell>
											<TableCell align='center'>{showDateTime(row.createdAt)}</TableCell>
											<TableCell align='center'>{ row.orderCanceled ? '購入キャンセル' : row.connectionKey }</TableCell>
											<TableCell align='center'>{row.additionalEmail1}</TableCell>
											<TableCell align='center'>{row.additionalEmail2}</TableCell>
											<TableCell align='center'>{showMinutesFromSeconds(row.duration)}</TableCell>
											<TableCell align='center'>{showTaxIncludedAmount(row.salesAmount)}円</TableCell>
											<TableCell align='center'>{showTaxIncludedAmount(row.holesaleAmount)}円</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			</Box>
		</MuiThemeProvider>
	);

};

ConnectUrls.propTypes =
{
	classes        : PropTypes.object.isRequired,
	connections    : PropTypes.array.isRequired,
	setConnections : PropTypes.func.isRequired
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ConnectUrls)));
const initialState =
{
	displayName               : '',
	selectedWebcam            : null,
	selectedAudioDevice       : null,
	advancedMode              : false,
	autoGainControl           : false,
	echoCancellation          : true,
	noiseSuppression          : true,
	voiceActivatedUnmute      : false,
	noiseThreshold            : -50,
	audioMuted                : false,
	videoMuted                : false,
	// low, medium, high, veryhigh, ultra
	resolution                : window.config.defaultResolution || 'ultra',
	frameRate                 : window.config.defaultFrameRate || 30,
	screenSharingResolution   : window.config.defaultScreenResolution || 'ultra',
	screenSharingFrameRate    : window.config.defaultScreenSharingFrameRate || 20,
	lastN                     : 4,
	permanentTopBar           : true,
	hiddenControls            : false,
	showNotifications         : true,
	notificationSounds        : true,
	mirrorOwnVideo            : false,
	buttonControlBar          : window.config.buttonControlBar || false,
	drawerOverlayed           : window.config.drawerOverlayed || true,
	aspectRatio               : window.config.viewAspectRatio || 1, // 16 : 9
	mediaPerms                : { audio: true, video: true },
	localPicture              : null,
	changePersonDialogOpen    : false,
	personalCallDialogOpen    : false,
	holding                   : false,
	guestHomeUrl              : { user: '', portal: '' },
	guestRedirectParams       : {},
	serviceGuest              : {},
	logoUrl                   : '',
	meVideoMirrored           : true,
	portalCallOnly            : false,
	userSkillGroups           : [],
	userAverageCallSeconds    : null,
	recorderPreferredMimeType : window.config.defaultRecorderMimeType || 'audio/webm',
	...window.config.defaultAudio
};

const settings = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'CHANGE_WEBCAM':
		{
			return { ...state, selectedWebcam: action.payload.deviceId };
		}

		case 'CHANGE_WEBCAM_AND_FACING_MODE' :
		{
			return {
				...state,
				selectedWebcam   : action.payload.deviceData.deviceId,
				facingModeWebCam : action.payload.deviceData.facingMode
			};
		}

		case 'CHANGE_AUDIO_DEVICE':
		{
			return { ...state, selectedAudioDevice: action.payload.deviceId };
		}

		case 'CHANGE_AUDIO_OUTPUT_DEVICE':
		{
			return { ...state, selectedAudioOutputDevice: action.payload.deviceId };
		}

		case 'SET_DISPLAY_NAME':
		{
			const { displayName } = action.payload;

			return { ...state, displayName };
		}

		case 'TOGGLE_ADVANCED_MODE':
		{
			const advancedMode = !state.advancedMode;

			return { ...state, advancedMode };
		}

		case 'SET_SAMPLE_RATE':
		{
			const { sampleRate } = action.payload;

			return { ...state, sampleRate };
		}

		case 'SET_CHANNEL_COUNT':
		{
			const { channelCount } = action.payload;

			return { ...state, channelCount };
		}

		case 'SET_VOLUME':
		{
			const { volume } = action.payload;

			return { ...state, volume };
		}

		case 'SET_AUTO_GAIN_CONTROL':
		{
			const { autoGainControl } = action.payload;

			return { ...state, autoGainControl };
		}

		case 'SET_ECHO_CANCELLATION':
		{
			const { echoCancellation } = action.payload;

			return { ...state, echoCancellation };
		}

		case 'SET_NOISE_SUPPRESSION':
		{
			const { noiseSuppression } = action.payload;

			return { ...state, noiseSuppression };
		}

		case 'SET_VOICE_ACTIVATED_UNMUTE':
		{
			const { voiceActivatedUnmute } = action.payload;

			return { ...state, voiceActivatedUnmute };
		}

		case 'SET_NOISE_THRESHOLD':
		{
			const { noiseThreshold } = action.payload;

			return { ...state, noiseThreshold };
		}

		case 'SET_DEFAULT_AUDIO':
		{
			const { audio } = action.payload;

			return { ...state, audio };
		}

		case 'SET_SAMPLE_SIZE':
		{
			const { sampleSize } = action.payload;

			return { ...state, sampleSize };
		}

		case 'SET_ASPECT_RATIO':
		{
			const { aspectRatio } = action.payload;

			return { ...state, aspectRatio };
		}

		case 'SET_LAST_N':
		{
			const { lastN } = action.payload;

			return { ...state, lastN };
		}

		case 'TOGGLE_PERMANENT_TOPBAR':
		{
			const permanentTopBar = !state.permanentTopBar;

			return { ...state, permanentTopBar };
		}

		case 'TOGGLE_BUTTON_CONTROL_BAR':
		{
			const buttonControlBar = !state.buttonControlBar;

			return { ...state, buttonControlBar };
		}

		case 'TOGGLE_DRAWER_OVERLAYED':
		{
			const drawerOverlayed = !state.drawerOverlayed;

			return { ...state, drawerOverlayed };
		}

		case 'TOGGLE_HIDDEN_CONTROLS':
		{
			const hiddenControls = !state.hiddenControls;

			return { ...state, hiddenControls };
		}

		case 'TOGGLE_NOTIFICATION_SOUNDS':
		{
			const notificationSounds = !state.notificationSounds;

			return { ...state, notificationSounds };
		}

		case 'TOGGLE_SHOW_NOTIFICATIONS':
		{
			const showNotifications = !state.showNotifications;

			return { ...state, showNotifications };
		}

		case 'SET_VIDEO_RESOLUTION':
		{
			const { resolution } = action.payload;

			return { ...state, resolution };
		}

		case 'SET_VIDEO_FRAME_RATE':
		{
			const { frameRate } = action.payload;

			return { ...state, frameRate };
		}

		case 'SET_SCREEN_SHARING_RESOLUTION':
		{
			const { screenSharingResolution } = action.payload;

			return { ...state, screenSharingResolution };
		}

		case 'SET_SCREEN_SHARING_FRAME_RATE':
		{
			const { screenSharingFrameRate } = action.payload;

			return { ...state, screenSharingFrameRate };
		}

		case 'TOGGLE_MIRROR_OWN_VIDEO':
		{
			const mirrorOwnVideo = !state.mirrorOwnVideo;

			return { ...state, mirrorOwnVideo };
		}

		case 'SET_MEDIA_PERMS':
		{
			const { mediaPerms } = action.payload;

			return { ...state, mediaPerms };
		}

		case 'SET_AUDIO_MUTED':
		{
			const { audioMuted } = action.payload;

			return { ...state, audioMuted };
		}

		case 'SET_VIDEO_MUTED':
		{
			const { videoMuted } = action.payload;

			return { ...state, videoMuted };
		}

		case 'SET_LOCAL_PICTURE':
		{
			const { localPicture } = action.payload;

			return { ...state, localPicture };
		}

		case 'SET_CHANGE_PERSON_DIALOG_OPEN':
		{
			const { changePersonDialogOpen } = action.payload;

			return { ...state, changePersonDialogOpen };
		}

		case 'SET_PERSONAL_CALL_DIALOG_OPEN' :
		{
			const { personalCallDialogOpen } = action.payload;

			return { ...state, personalCallDialogOpen };
		}

		case 'SET_IS_HOLDING':
		{
			const { holding } = action.payload;

			return { ...state, holding };
		}

		case 'SET_GUEST_HOME_URL' :
		{
			const { key, url } = action.payload;

			const newValue = {};

			newValue[key] = url;

			return {
				...state,
				guestHomeUrl : {
					...state.guestHomeUrl,
					...newValue
				}
			};
		}

		case 'SET_LOGO_URL':
		{
			const { logoUrl } = action.payload;

			return { ...state, logoUrl };
		}

		case 'SET_PORTAL_CALL_ONLY' :
		{
			const { flag } = action.payload;

			return { ...state, portalCallOnly: flag };
		}

		case 'SET_ME_VIDEO_MIRRORED' :
		{
			const { flag } = action.payload;

			return { ...state, meVideoMirrored: flag };
		}

		case 'SET_GUEST_REDIRECT_PARAMS' :
		{
			const { guestRedirectParams } = action.payload;

			return { ...state, guestRedirectParams: guestRedirectParams };
		}

		case 'SET_USER_SKILL_GROUPS' :
		{
			const { userSkillGroups } = action.payload;

			return { ...state, userSkillGroups: [ ...userSkillGroups ] };
		}

		case 'SET_USER_AVERAGE_CALL_SECONDS':
		{
			const { userAverageCallSeconds } = action.payload;

			return { ...state, userAverageCallSeconds: userAverageCallSeconds };
		}

		default:
			return state;
	}
};

export default settings;

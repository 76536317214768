import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';

// material ui
import { Button } from '@material-ui/core';

// components
import ConnectPurchaseDialog from './ConnectPurchaseDialog';

const styles = () =>
	({
		button : {
			padding         : '3px 20px',
			fontSize        : '1rem',
			backgroundColor : '#8eba14',
			color           : 'white',
			'&:hover'       : {
				filter : 'saturate(105%) brightness(105%)'
			}
		},
		hiddenInput :
		{
			display : 'none'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const ConnectPurchaseButton = ({
	classes,
	paramKey,
	priceList,
	priceListTerm,
	email,
	defaultExpiryTerm,
	accountId
}) =>
{
	const hiddenFileInput = useRef();

	const [ fileArray, setFileArray ] = useState([]);

	const [ fileResult, setFileResult ] = useState([]);

	const [ priceListPurchase, setPriceListPurchase ] = useState([]);

	const [ showConnectPurchaseDialog, setShowConnectPurchaseDialog ] = useState(false);

	const startPurchaseByCsv = () =>
	{
		hiddenFileInput.current.click();
	};

	const csvFileToArray = (string) =>
	{
		const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
		const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

		const array = csvRows.map((row) =>
		{
			const values = row.split(',');

			const obj = {};

			csvHeader.forEach((header, index) =>
			{
				// remove space
				obj[header.trim()] = values[index].trim();
			});

			return obj;
		});

		setFileArray(array);
	};

	const handleCSV = (event) =>
	{
		event.preventDefault();

		const fileUploaded = event.target.files[0];

		const fileReader = new FileReader();

		fileReader.onload = async function(e)
		{

			const text = e.target.result;

			csvFileToArray(text);

		};

		try
		{
			fileReader.readAsText(fileUploaded);
		}
		catch (e)
		{
			// eslint-disable-next-line no-console
			console.log('error:', e);
		}
	};

	useEffect(() =>
	{
		if (fileArray && fileArray.length > 0)
		{

			const purchaseList = [];

			const resultList = [];

			priceList.forEach((item) =>
			{
				purchaseList.push({ ...item, quantity: 0, expiryTerm: defaultExpiryTerm });
			});

			priceListTerm.forEach((item) =>
			{
				purchaseList.push({ ...item, quantity: 0 });
			});

			fileArray.forEach((fileItem) =>
			{

				const fileItemKey = fileItem['entry_key'];

				const sameKeyIndex = purchaseList.findIndex(
					(row) => row.entryKey === fileItemKey);

				if (sameKeyIndex !== -1)
				{
					if (fileItemKey.slice(0, 4) === paramKey.toUpperCase())
					{
						// quantity欄がない場合は1とみなす
						if (fileItem.quantity === undefined)
						{
							purchaseList[sameKeyIndex].quantity += 1;
							resultList.push({ status: 'success', entryKey: fileItemKey, quantity: 1 });
						}
						else if (fileItem.quantity)
						{
							try
							{
								const quantityValue = Number(fileItem.quantity);

								if (quantityValue)
								{
									purchaseList[sameKeyIndex].quantity += quantityValue;
									resultList.push({ status: 'success', entryKey: fileItemKey, quantity: quantityValue });
								}
								else
								{
									resultList.push({ status: 'noQuantity', entryKey: fileItemKey, quantity: fileItem.quantity });
								}
							}
							catch
							{
								resultList.push({ status: 'noQuantity', entryKey: fileItemKey, quantity: fileItem.quantity });
							}
						}
						else
						{
							resultList.push({ status: 'noQuantity', entryKey: fileItemKey, quantity: fileItem.quantity });
						}
					}
					else
					{
						resultList.push({ status: 'wrongAccount', entryKey: fileItemKey, quantity: fileItem.quantity });
					}
				}
				else
				{
					resultList.push({ status: 'keyNotFound', entryKey: fileItemKey, quantity: fileItem.quantity });
				}
			});

			setFileResult(resultList);

			setPriceListPurchase(purchaseList);

			setShowConnectPurchaseDialog(true);
		}
	}, [ fileArray, priceList, priceListTerm, paramKey, defaultExpiryTerm ]);

	return (
		<MuiThemeProvider theme={theme}>
			<Button
				variant='contained'
				className={classes.button}
				onClick={startPurchaseByCsv}
			>
				csvファイルから購入
			</Button>
			<input
				type='file'
				accept={'.csv'}
				ref={hiddenFileInput}
				onChange={handleCSV}
				className={classes.hiddenInput}
			/>
			< ConnectPurchaseDialog
				show={showConnectPurchaseDialog}
				closeMethod={() => setShowConnectPurchaseDialog(false)}
				accountId={accountId}
				defaultExpiryTerm={defaultExpiryTerm}
				email={email}
				fileResult={fileResult}
				priceListPurchase={priceListPurchase}
			/>
		</MuiThemeProvider>
	);
};

ConnectPurchaseButton.propTypes =
{
	classes           : PropTypes.object.isRequired,
	paramKey          : PropTypes.string.isRequired,
	email             : PropTypes.string.isRequired,
	defaultExpiryTerm : PropTypes.object.isRequired,
	priceList         : PropTypes.array.isRequired,
	priceListTerm     : PropTypes.array.isRequired,
	accountId         : PropTypes.string.isRequired
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ConnectPurchaseButton)));

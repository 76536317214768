import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Box, Button, Modal } from '@material-ui/core';

import Overall from './Parts/overall/Overall';
import Skill from './Parts/skill/Skill';
import Header from './Parts/header/Header';
import Operator from './Parts/operator';

// firestore
import { firestore } from '../../../../lib/firebase';

const styles = (theme) => ({
	ReportContainer : {
		zIndex : 1
	},
	hostContainerReport : {
		width  : '100%',
		height : '100vh'
	},
	hostContainerOperator : {
		display             : 'grid',
		gridTemplateColumns : '35% auto',
		gap                 : '10px'
	},
	hostModal : {
		padding : '30px'
	},
	Report : {
		backgroundColor        : theme.palette.background.default,
		borderRadius           : '7px',
		height                 : 'calc(97vh - 30px)',
		overflow               : 'auto',
		padding                : '30px',
		'&::-webkit-scrollbar' : {
			display : 'none'
		},
		scrollbarWidth       : 'none',
		'-ms-overflow-style' : 'none'
	},
	hostContainerOperatorSkill : {
		display             : 'grid',
		gridTemplateColumns : '35% auto',
		gap                 : '10px',
		marginBottom        : '20px'
	},
	hostTableWrapper : {
		overflowX               : 'auto', // Horizontal scrolling for large tables
		WebkitOverflowScrolling : 'touch' // Smooth scrolling on iOS
	},
	table : {
		borderCollapse : 'collapse',
		width          : '100%',
		minWidth       : '800px', // Ensures tables have a minimum width
		marginBottom   : '20px'
	},
	// Media queries
	'@media (max-width: 1680px)' : {
		hostContainerOperatorSkill : {
			gridTemplateColumns : '1fr',
			gap                 : '20px'
		},
		hostContainerOperator : {
			gridTemplateColumns : '1fr',
			gap                 : '20px',
			marginBottom        : '20px'
		},
		Report : {
			width   : '100%',
			margin  : '0 auto',
			padding : '10px'
		},
		hostHeaderContainer : {
			textAlign : 'center',
			padding   : '0 15px'
		},
		hostTableWrapper : {
			overflowX               : 'auto', // Allows horizontal scrolling
			WebkitOverflowScrolling : 'touch' // Smooth scrolling on iOS
		},
		table : {
			width    : '100%',
			minWidth : '600px' // Ensures tables have a minimum width
		}
	},
	'@media (max-width: 768px)' : {
		ReportContainer : {
			width : '100vw'
		},
		hostModal : {
			padding : '20px 10px',
			width   : '100vw'
		},
		Report : {
			padding : '5px',
			height  : 'calc(97vh - 10px)'
		},
		hostHeaderContainer : {
			button : {
				marginTop : '10px',
				width     : '100%'
			}
		}
	}
});

const Report = ({
	classes,
	show,
	connectionAccountId,
	subdomains,
	close
}) =>
{
	const [ calls, setCalls ] = useState([]);
	const [ roomChangeLogs, setRoomChangeLogs ] = useState([]);
	const [ queueResults, setQueueResults ] = useState([]);

	const fetchCalls = useCallback((accountId) =>
	{
		// eslint-disable-next-line no-console
		console.log(`TESTOUTPUT Report.js accountId ${accountId}`);

		if (accountId)
		{
			firestore.collection('calls').where('connectionAccountId', '==', accountId)
				.orderBy('timestamp')
				.onSnapshot((snapshot) =>
				{
					const callsAr = [];

					snapshot.forEach((doc) =>
					{
						callsAr.push({
							id : doc.id,
							...doc.data()
						});
					});
					setCalls(callsAr);
				});
		}
	}, [ ]);

	const fetchRoomChangeLogs = useCallback((sdomains) =>
	{

		// eslint-disable-next-line no-console
		console.log(`TESTOUTPUT Report.js sdomains ${sdomains}`);

		if (sdomains && sdomains.length > 0)
		{
			firestore.collection('roomChangeLogs').where('subdomain', 'in', sdomains)
				.orderBy('timestamp')
				.onSnapshot((snapshot) =>
				{
					const chLogs = [];

					snapshot.forEach((doc) =>
					{
						chLogs.push({
							id : doc.id,
							...doc.data()
						});
					});
					setRoomChangeLogs(chLogs);
				});
		}
	}, []);

	const fetchQueueResults = useCallback((sdomains) =>
	{
		if (sdomains && sdomains.length > 0)
		{
			firestore.collection('queueResults').where('subdomain', 'in', sdomains)
				.orderBy('start')
				.onSnapshot((snapshot) =>
				{
					const queueLogs = [];

					snapshot.forEach((doc) =>
					{
						queueLogs.push({
							id : doc.id,
							...doc.data()
						});
					});
					setQueueResults(queueLogs);
				});
		}
	}, []);

	useEffect(() =>
	{
		if (show)
		{
			fetchCalls(connectionAccountId);
			fetchRoomChangeLogs(subdomains);
			fetchQueueResults(subdomains);
		}
	}, [
		show,
		connectionAccountId,
		subdomains,
		fetchCalls,
		fetchRoomChangeLogs,
		fetchQueueResults
	]);

	return (
		<div className={classes.ReportContainer}>
			<Modal
				open={show}
				onClose={close}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className={classes.hostModal}
			>
				<Box>
					<div className={classes.Report}>
						<Header close={close} />
						<div>
							<div className={classes.hostContainerOperatorSkill}>
								<Overall
									calls={calls}
									roomChangeLogs={roomChangeLogs}
									queueResults={queueResults}
								/>
								<Skill
									calls={calls}
									roomChangeLogs={roomChangeLogs}
									queueResults={queueResults}
								/>
							</div>
							<div className={classes.hostContainerOperator}>
								<Operator calls={calls} roomChangeLogs={roomChangeLogs} />
							</div>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default withStyles(styles)(Report);

// React
import React, { useState, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../../RoomContext';

// Router
import { useLocation, useHistory, Link } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Message
import { FormattedMessage, useIntl } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';

// image 
import cautionMark from '../../../../images/caution.svg';

// components
import TicketInfoTable from '../Parts/TicketInfoTable';
import ServiceAppBar from '../Parts/ServiceAppBar';

// Firestore
import { functions, firestore } from '../../../../lib/firebase';

// util
import { getDomainWithoutSubdomain, parseUrlParams } from '../../../../utils';

// const
import { DEFAULT_SERVICE_ACCOUNT_KEY } from '../../../../const';

const styles = (theme) =>
	({
		root : {
			zIndex          : 99,
			width           : '100%',
			height          : '100%',
			overflow        : 'auto',
			backgroundColor : '#EBEBEC'
		},
		content : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		contentArea : {
			width           : 'calc( 100% - 20px )',
			maxWidth        : '750px',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			margin          : '30px 15px',
			padding         : '30px 0px',
			minWidth        : '320px',
			backgroundColor : '#FFF',
			borderRadius    : '20px'
		},
		pageContent : {
			width          : 'calc( 100% - 20px )',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'
		},
		titleSection : {
			margin         : '10px 0 20px 0',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		redirectTitle : {
			marginTop : '80px'
		},
		title : {
			fontSize : '1.1rem'
		},
		messageSection : {
			margin         : '10px 0',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		message : {
			fontSize : '0.9rem',
			color    : 'var(--text-color)',
			maxWidth : '250px'
		},
		subTitle : {
			paddingLeft  : '18px',
			borderLeft   : '2px solid #cd2c82',
			fontSize     : '1rem',
			marginBottom : '15px'
		},
		contentWrapper : {
			width     : '98%',
			maxWidth  : '500px',
			minWidth  : '280px',
			marginTop : '25px'
		},
		contentWrapperAmazonRedirect :
		{
			minWidth                     : '300px',
			[theme.breakpoints.up('sm')] :
			{
				minWidth : '480px'
			}
		},
		copyCodeBtn : {
			padding         : '1px 10px',
			backgroundColor : '#cd2c82',
			color           : '#FFF',
			cursor          : 'pointer',
			'&:hover'       : {
				backgroundColor : '#cd2c82',
				color           : '#FFF'
			}
		},
		contentText :
		{
			color    : '#292929',
			fontSize : '1rem'
		},
		contentTextSmall :
		{
			color    : '#292929',
			fontSize : '0.8rem'
		},
		inputLabel : {
			width         : '35%',
			minWidth      : '160px',
			fontSize      : '0.8rem',
			height        : '37px',
			lineHeight    : '37px',
			verticalAlign : 'middle'
		},
		inputLine : {
			display   : 'flex',
			minWidth  : '300px',
			marginTop : '10px'
		},
		inputText : {
			background   : '#FFF',
			padding      : '0.6rem 0.6rem',
			borderRadius : '0.2rem',
			color        : '#292929',
			textAlign    : 'left',
			border       : '1px solid #9E9E9E',
			'&:focus'    : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#E8E8E8',
				color           : 'var(--text-color)'
			},
			'&::-ms-reveal' : {
				display : 'none'
			}
		},
		inputTextTicket : {
			width    : '65%',
			maxWidth : '240px'
		},
		inputAmazonPayInfo : {
			width : '100%'
		},
		inputBox : {
			marginTop : '15px'
		},
		cautionImgArea : {
			width          : '100%',
			margin         : '10px 0',
			display        : 'flex',
			justifyContent : 'center'
		},
		cautionImg : {
			height : '80px',
			width  : 'auto'
		},
		termsArea : {
			margin  : '20px 0 0 0',
			padding : '10px',
			border  : '1px solid #292929'
		},
		termsText : {
			fontSize : '0.8rem',
			color    : '#292929'
		},
		termsTextPaddingLeft : {
			paddingLeft : '10px'
		},
		termsLinkText :
		{
			color          : '#292929',
			fontSize       : '0.8rem',
			textDecoration : 'underline'
		},
		termsLink :
		{
			color : '#292929'
		},
		agreeTermsLine : {
			marginTop      : '15px',
			width          : '100%',
			padding        : '5px 0',
			display        : 'flex',
			justifyContent : 'center'
		},
		agreeTermsCheckBox : {
			width          : '30px',
			display        : 'flex',
			justifyContent : 'end'
		},
		agreeTermsText : {
			width                        : '310px',
			paddingLeft                  : '10px',
			fontSize                     : '0.7rem',
			color                        : '#292929',
			height                       : '26px',
			lineHeight                   : '26px',
			verticalAlign                : 'middle',
			[theme.breakpoints.up('sm')] :
			{
				minWidth : '430px'
			}
		},
		buttonSection : {
			width                        : '100%',
			maxWidth                     : '450px',
			minWidth                     : '300px',
			marginTop                    : '30px',
			display                      : 'flex',
			padding                      : '0 3px',
			justifyContent               : 'space-between',
			[theme.breakpoints.up('sm')] :
			{
				maxWidth : '500px'
			}
		},
		button : {
			padding                      : '4px 8px',
			width                        : '45%',
			maxWidth                     : '300px',
			borderRadius                 : '10px',
			fontSize                     : '0.9rem',
			[theme.breakpoints.up('sm')] :
			{
				width : '40%'
			}
		},
		centerButton : {
			padding      : '6px 10px',
			width        : '70%',
			maxWidth     : '300px',
			borderRadius : '10px',
			fontSize     : '1.1rem'
		},
		backButton : {
			backgroundColor : 'white',
			color           : '#cd2c82',
			border          : '1px solid #cd2c82',
			'&:hover'       : {
				color  : '#D9388E',
				border : '1px solid #D9388E'
			}
		},
		proceedButton : {
			backgroundColor : '#cd2c82',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#D9388E'
			}
		},
		amazonRedirectBtnLine :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			marginTop      : '20px'
		},
		amazonRedirectBtnForm : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		amazonRedirectBtn : {
			padding        : '4px 12px',
			minWidth       : '250px',
			borderRadius   : '10px',
			fontSize       : '1.1rem',
			border         : '0',
			cursor         : 'pointer',
			margin         : '0',
			display        : 'inline-flex',
			outline        : '0',
			position       : 'relative',
			alignItems     : 'center',
			userSelect     : 'none',
			verticalAlign  : 'middle',
			justifyContent : 'center'
		},
		movePageSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '15px 0',
			width          : '100%'
		},
		movePageButton : {
			boxShadow       : '2px 2px 4px #aaa',
			backgroundColor : '#292929',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#4B4B4B'
			}
		},
		link :
		{
			color  : 'var(--submit-button-color)',
			cursor : 'pointer'
		},
		loadingWrapper :
		{
			width           : '100%',
			height          : 'calc( 100% - 40px)',
			display         : 'flex',
			paddingTop      : '250px',
			justifyContent  : 'center',
			alignItems      : 'flex-start',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		},
		loadingIndicator :
		{
			color : 'var(--text-color)'
		},
		errorMsg : {
			width        : '100%',
			color        : 'red',
			fontSize     : '0.8rem',
			textAlign    : 'center',
			marginBottom : '10px'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides :
	{
		MuiOutlinedInput : {
			input : {
				'&::-ms-reveal' : {
					display : 'none'
				}
			}
		},
		PrivateSwitchBase : {
			root : {
				padding : '1px'
			}
		}
	}
});

// home select input confirm complete
const AmazonPayReview = ({
	classes
}) =>
{
	const intl = useIntl();

	const location = useLocation();
	const history = useHistory();

	const [ page, setPage ] = useState('review');

	const [ loading, setLoading ] = useState(true);

	const [ ticketOrder, setTicketOrder ] = useState(null);

	const [ amazonCheckoutSessionId, setAmazonCheckoutSessionId ] = useState('');

	const [ agreeTerms, setAgreeTerms ] = useState(false);

	const [ orderId, setOrderId ] = useState('');

	const [ amazonToken, setAmazonToken ] = useState('');

	const [ amazonStartUrl, setAmazonStartUrl ] = useState('');

	const [ errorMsg, setErrorMsg ] = useState('');

	useEffect(() =>
	{
		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		let paramsObj = {};

		if (paramsValue)
		{
			paramsObj = parseUrlParams({
				paramsValues : paramsValue,
				keys         : [ 'amazonCheckoutSessionId' ]
			});
		}

		setAmazonCheckoutSessionId(paramsObj['amazonCheckoutSessionId']);

		if (paramsObj['amazonCheckoutSessionId'])
		{
			const storedOrderId = localStorage.getItem('amazonPayOrderId');

			setOrderId(storedOrderId);

			firestore.collection('serviceAmazonPayRecords')
				.doc(storedOrderId)
				.get()
				.then((doc) =>
				{
					if (doc.exists)
					{
						const amazonPayOrder = doc.data();

						setTicketOrder({
							...amazonPayOrder
						});
						setLoading(false);
					}
				});
		}
	}, [ location.search ]);

	const moveToPurchasePage = () =>
	{
		const serviceParamKeyValue = ticketOrder.serviceParamKey
			? ticketOrder.serviceParamKey
			: DEFAULT_SERVICE_ACCOUNT_KEY;

		history.push({
			pathname : '/purchase',
			search   : `?id=${serviceParamKeyValue}`
		});
	};

	const executeTransaction = async () =>
	{
		if (agreeTerms)
		{
			setErrorMsg('');

			if (!ticketOrder.serviceParamKey
				|| !ticketOrder.email
			)
			{
				setErrorMsg(intl.formatMessage({
					id             : 'error.returnFirstPage',
					defaultMessage : 'An error has occurred  Please return to the first page and try again'
				}));
				setLoading(false);
			}
			else
			{
				setLoading(true);

				try
				{
					const domain = getDomainWithoutSubdomain();

					const serviceAmazonPayEntryAndExecuteAPI = functions.httpsCallable('serviceAmazonPayEntryAndExecute');

					const response = await serviceAmazonPayEntryAndExecuteAPI({
						orderId                 : orderId,
						amount                  : ticketOrder.amount,
						amazonCheckoutSessionId : amazonCheckoutSessionId,
						domain                  : domain,
						serviceParamKey         : ticketOrder.serviceParamKey
					});

					const data = response.data;

					if (data.success && data.accessId && data.amazonToken && data.amazonUrl)
					{

						setTicketOrder((state) =>
						{
							return {
								...state,
								accessId   : data.accessId,
								accessPass : data.accessPass
							};
						});

						setAmazonToken(data.amazonToken);
						setAmazonStartUrl(data.amazonUrl);
						setPage('redirect');
						setLoading(false);

					}
					else
					{
						setErrorMsg(intl.formatMessage({
							id             : 'error.notIssue',
							defaultMessage : 'Not Issued'
						}));
						setLoading(false);

						return;
					}
				}
				catch
				{
					setErrorMsg(intl.formatMessage({
						id             : 'error.error',
						defaultMessage : 'An error has occurred'
					}));
					setLoading(false);

					return;
				}
			}
		}
		else
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.consent',
				defaultMessage : 'You need to agree with term to purchase'
			}));
			setLoading(false);
		}
	};

	const toggleAgreeTerms = () =>
	{
		setAgreeTerms((state) =>
		{
			return !state;
		});
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>
				<ServiceAppBar
					free={false}
					appBarHeight={40}
				/>
				{ loading ?
					<Box className={classes.loadingWrapper}>
						<CircularProgress className={classes.loadingIndicator}/>
					</Box>
					:
					<Box className={classes.content}>
						<Box className={classes.contentArea}>
							{errorMsg &&
							<p className={classes.errorMsg}>{errorMsg}</p>
							}
							{ page === 'redirect' ?
								<Box className={classes.pageContent}>
									<Box className={`${classes.titleSection} ${classes.redirectTitle}`}>
										<Box className={classes.cautionImgArea}>
											<img src={cautionMark} className={classes.cautionImg} alt='caution'/>
										</Box>
										<Typography className={classes.title}>
											<FormattedMessage
												id='paidservice4.amazonpayCaution'
												defaultMessage='Your purchase has not been completed yet'
											/>
										</Typography>
									</Box>
									<Box className={classes.contentWrapperAmazonRedirect}>
										<Box className={classes.messageSection}>
											<Typography className={classes.message}>
												<FormattedMessage
													id='paidservice4.amazonpayParagraph1'
													defaultMessage='Please complete the purchase procedure.'
												/>
												<FormattedMessage
													id='paidservice4.amazonpayParagraph2'
													defaultMessage='Click the button below to pay with Amazon PAY.'
												/>
											</Typography>
										</Box>
										<Box className={classes.amazonRedirectBtnLine}>
											<form
												name='AmazonpayRedirectCall'
												action={amazonStartUrl}
												method='POST'
												className={classes.amazonRedirectBtnForm}
											>
												<input type='hidden' name='AccessID' value={ticketOrder.accessId} />
												<input type='hidden' name='Token' value={amazonToken} />
												<input type='submit' value={intl.formatMessage({
													id             : 'paidservice3.payAmazon',
													defaultMessage : 'Pay with your Amazon account'
												})}												className={`${classes.proceedButton} ${classes.amazonRedirectBtn}`}
												/>
											</form>
										</Box>
									</Box>
								</Box>
								:<Box className={classes.pageContent}>
									<Box className={classes.titleSection}>
										<Typography className={classes.title}>
											<FormattedMessage
												id='paidservice1.buyTicket'
												defaultMessage='Purchase Ticket'
											/>
										</Typography>
									</Box>

									<Box className={classes.contentWrapper}>
										<Typography className={classes.subTitle}>
											<FormattedMessage
												id='paidservice.purchaseDetails'
												defaultMessage='Purchase details'
											/>
										</Typography>

										<TicketInfoTable
											ticket={ticketOrder}
										/>
									</Box>
									<Box className={classes.contentWrapper}>
										<Typography className={classes.subTitle}>
											<FormattedMessage
												id='paidservice.emailDestination'
												defaultMessage='Ticket code destination'
											/>
										</Typography>
										<Box className={classes.inputLine}>
											<Typography className={classes.inputLabel}>
												<FormattedMessage
													id='service.email'
													defaultMessage='Email Address'
												/>
											</Typography>
											<input
												value={ticketOrder.email}
												className={`${classes.inputText} ${classes.inputTextTicket}`}
												disabled
												type='email'
											/>
										</Box>
									</Box>
									<Box className={classes.contentWrapper}>
										<Typography className={classes.subTitle}>
											<FormattedMessage
												id='paidservice.checkID'
												defaultMessage='customer information'
											/>
										</Typography>
										<Box className={classes.inputLine}>
											<Typography className={classes.inputLabel}>
												<FormattedMessage
													id='paidservice.phoneNumber'
													defaultMessage='Email Address Confirm'
												/>
											</Typography>
											<input
												value={ticketOrder.phoneNumber}
												className={`${classes.inputText} ${classes.inputTextTicket}`}
												disabled
												type='tel'
											/>
										</Box>
									</Box>

									<Box className={classes.contentWrapper}>
										<Typography className={classes.subTitle}>
											<FormattedMessage
												id='paidservice4.paymentInfomation'
												defaultMessage='Payment Infomation'
											/>
										</Typography>
										<Box className={classes.inputBox}>
											<input
												value='Amazon Pay'
												className={`${classes.inputText} ${classes.inputAmazonPayInfo}`}
												disabled
												type='text'
											/>
										</Box>
									</Box>
									<Box className={classes.termsArea}>
										<Typography className={classes.termsText}>
											<FormattedMessage
												id='paidservice4.termsOfUse1'
												defaultMessage='1.Purchased tickets cannot be refunded, regardless of whether they are used or not.'
											/>
										</Typography>
										<Typography className={classes.termsText}>
											<FormattedMessage
												id='paidservice4.termsOfUse2'
												defaultMessage='2. Even if you do not receive the email, you cannot cancel the purchase.'
											/>
										</Typography>
										<Typography className={classes.termsText}>
											<FormattedMessage
												id='paidservice4.termsOfUse3'
												defaultMessage='3. After entering the code, the remaining usage minutes will disappear once the expiration date has passed.'
											/>
										</Typography>
										<Typography className={classes.termsText}>
											<FormattedMessage
												id='paidservice4.termsOfUse4-1'
												defaultMessage='4. The first 10 seconds of a call do not count as minutes.'
											/>
										</Typography>
										<Typography className={`${classes.termsText} ${classes.termsTextPaddingLeft}`}>
											<FormattedMessage
												id='paidservice4.termsOfUse4-2'
												defaultMessage='Minutes are counted in increments of 1 minute.'
											/>
										</Typography>
										<Typography className={`${classes.termsText} ${classes.termsTextPaddingLeft}`}>
											<FormattedMessage
												id='paidservice4.termsOfUse4-3'
												defaultMessage='(Please note that seconds are rounded up)'
											/>
										</Typography>
									</Box>
									<Box className={classes.agreeTermsLine}>
										<Box className={classes.agreeTermsCheckBox}>
											<Checkbox
												checked={agreeTerms}
												onChange={toggleAgreeTerms}
												inputProps={{ 'aria-label': 'terms check box' }}
											/>
										</Box>

										<Typography className={classes.agreeTermsText}>
											<span>
												<FormattedMessage
													id='paidservice4.agreeTermsOfUse1'
													defaultMessage='I agree to the above content and '
												/>
											</span>
											<span className={classes.termsLinkText}>
												<Link
													to={{ pathname : 'https://www.tuuyaku.com/terms'
													}}
													target='_blank'
													className={classes.termsLink}
												>
													<FormattedMessage
														id='paidservice4.agreeTermsOfUse2'
														defaultMessage='terms of use'
													/>
												</Link>
											</span>
											<span>
												<FormattedMessage
													id='paidservice4.agreeTermsOfUse3'
													defaultMessage='of the of LIVE Interpreter.'
												/>
											</span>
										</Typography>
									</Box>

									<Box className={classes.buttonSection}>
										<Button
											className={`${classes.button} ${classes.backButton}`}
											onClick={moveToPurchasePage}
										>
											<FormattedMessage
												id='paidservice.backButton'
												defaultMessage='Back'
											/>
										</Button>
										<Button
											className={`${classes.button} ${classes.proceedButton}`}
											onClick={executeTransaction}
										>
											<FormattedMessage
												id='paidservice.nextButton'
												defaultMessage='FORWARD'
											/>
										</Button>
									</Box>
								</Box>
							}
						</Box>
					</Box>
				}
			</Box>
		</MuiThemeProvider>
	);
};

AmazonPayReview.propTypes =
{
	classes : PropTypes.object.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(AmazonPayReview)));
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../RoomContext';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Components
import WorkTimeSetting from './WorkTimeSetting';

const styles = () =>
	({
		root :
		{
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper :
		{
			padding         : '1% 1%',
			width           : 500,
			minHeight       : 500,
			backgroundColor : '#666666'
		},
		titleSection :
		{
			width    : '100%',
			position : 'relative'
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleText :
		{
			color    : 'white',
			fontSize : '1.1rem'
		},
		promoteBtn :
		{
			width           : '40%',
			border          : '1px solid #8eba14',
			backgroundColor : '#8eba14',
			color           : 'white'
		},
		cancelBtn :
		{
			width           : '40%',
			border          : '1px solid #fff',
			backgroundColor : 'transparent',
			color           : 'white',
			marginRight     : '8%'
		},
		topData : {
			width          : '100%',
			height         : '1.75rem',
			display        : 'flex',
			margin         : '20px 0',
			justifyContent : 'space-between',
			lineHeight     : 1
		},
		workingTimeData : {
			width           : '100%',
			backgroundColor : '#FFF',
			display         : 'flex',
			margin          : '20px 0',
			padding         : '10px 0 15px 0',
			justifyContent  : 'center',
			borderRadius    : '10px'
		},
		inputLabel : {
			width         : '50%',
			fontSize      : '0.8rem',
			paddingTop    : '10px',
			// whiteSpace    : 'nowrap',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'flex-start'
		},
		label : {
			color        : 'white',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '100%',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#F6F6F6'
			}
		},
		inputListSmall : {
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.3rem',
			padding         : '0.2rem 0.3rem',
			color           : 'var(--text-color)',
			minWidth        : '4rem',
			backgroundColor : '#FFF'

		},
		errMsg :
		{
			color        : 'red',
			fontSize     : '0.9rem',
			lineHeight   : '0.9rem',
			marginBottom : '3px'
		},
		errorBox :
		{
			marginTop : '8px'
		},
		dialogButtons :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiInput : {
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				},
				'&.Mui-disabled' : {
					backgroundColor : '#F6F6F6'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		}
	}
});

const EditButtonDialog = ({
	classes,
	show,
	button,
	btnIndex,
	newItem,
	updateButton,
	closeMethod,
	type,
	newBtnKey
}) =>
{
	const [ errors, setErrors ] = useState({
		show   : false,
		errors : []
	});

	const [ subdomain, setSubdomain ] = useState('');
	const [ skillId, setSkillId ] = useState('');
	const [ btnText, setBtnText ] = useState('');
	const [ btnColor, setBtnColor ] = useState('');
	const [ textColor, setTextColor ] = useState('');
	const [ backUrl, setBackUrl ] = useState('');
	const [ businessHours, setBusinessHours ] = useState('');
	const [ btnKey, setBtnKey ] = useState('');

	const [ workTimeSetting, setWorkTimeSetting ] = useState(false);

	// Holiday
	const [ holiday, setHoliday ] = useState([ 0, 0, 0, 0, 0, 0, 0, 0 ]);
	const [ nationalHoliday, setNationalHoliday ] = useState(0);

	// OPEN/CLOSE TIME
	const [ openTime, setOpenTime ] = useState('10:00');
	const [ closeTime, setCloseTime ] = useState('18:00');

	useEffect(() =>
	{
		if (newItem)
		{
			setBtnColor('F15A24');
			setTextColor('FFFFFF');
			setBtnKey(newBtnKey || '');
			setHoliday([ 0, 0, 0, 0, 0, 0, 0, 0 ]);
			setNationalHoliday(0);
			setOpenTime('10:00');
			setCloseTime('18:00');
		}
		else
		{
			setSubdomain(button.subdomain ? button.subdomain : '');
			setSkillId(button.skillId ? button.skillId : '');
			setBtnText(button.btnText ? button.btnText : '');
			setBtnColor(button.btnColor ? button.btnColor : '');
			setTextColor(button.textColor ? button.textColor : '');
			setBackUrl(button.backUrl ? button.backUrl : '');
			setBusinessHours(button.businessHours ? button.businessHours : '');
			setBtnKey(button.btnKey ? button.btnKey : '');
			setHoliday(button.holiday || [ 0, 0, 0, 0, 0, 0, 0, 0 ]);
			setNationalHoliday(button.nationalHoliday || 0);
			setOpenTime(button.openTime || '10:00');
			setCloseTime(button.closeTime || '18:00');
		}

	}, [ newItem, button, newBtnKey ]);

	const handleChangeSubdomain = (e) =>
	{

		setSubdomain(e.target.value);

	};

	const handleChangeSkillId = (e) =>
	{

		setSkillId(e.target.value);

	};

	const handleChangeBtnText = (e) =>
	{
		setBtnText(e.target.value);
	};

	const handleChangeBtnColor = (e) =>
	{
		const value = e.target.value;

		if (value.length <= 6)
		{
			setBtnColor(value);
		}
	};

	const handleChangeTextColor = (e) =>
	{
		const value = e.target.value;

		if (value.length <= 6)
		{
			setTextColor(value);
		}
	};

	const handleChangeBackUrl = (e) =>
	{
		setBackUrl(e.target.value);
	};

	const handleChangeWorkTimeSetting = (e) =>
	{
		setWorkTimeSetting(e.target.value);
	};

	const handleChangeHolidayCallback = (day) =>
	{

		if (holiday[day] === 1)
		{
			holiday[day] = 0;
		}
		else
		{
			holiday[day] = 1;
		}
		setHoliday([ ...holiday ]);
	};

	const handleChangeNationalHolidayCallback = (e) =>
	{
		setNationalHoliday(Number(e.target.value));
	};

	const handleSetButton = () =>
	{
		const errorsAr = [];

		if (!subdomain)
		{
			errorsAr.push('サブドメインを入力してください');
		}
		else
		{
			const subdomainReg = new RegExp(/^[a-z0-9-]+$/);

			if (!subdomainReg.test(subdomain))
			{
				errorsAr.push('サブドメインに使用できるのは半角英数字と-だけです');
			}
		}

		const skillIdReg = new RegExp(/^[a-z0-9-_]+$/);

		if (skillId && !skillIdReg.test(skillId))
		{
			errorsAr.push('ロビーIDに使用できるのは半角英数字と-_だけです');
		}

		// eslint-disable-next-line
		const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

		if (backUrl && !urlPattern.test(backUrl))
		{
			errorsAr.push('通話後URLが正しくありません');
		}

		if (type === 'connect' && !btnKey)
		{
			errorsAr.push('エラーが発生しました。もう一度お試しください。');
		}

		if (errorsAr.length === 0)
		{
			if (type === 'service')
			{
				updateButton({
					subdomain     : subdomain,
					skillId       : skillId,
					btnText       : btnText,
					btnColor      : btnColor,
					textColor     : textColor,
					businessHours : businessHours
				}, btnIndex);
			}
			else if (type === 'connect')
			{
				updateButton({
					subdomain       : subdomain,
					skillId         : skillId,
					btnText         : btnText,
					btnColor        : btnColor,
					textColor       : textColor,
					backUrl         : backUrl,
					businessHours   : businessHours,
					btnKey          : btnKey,
					holiday         : holiday,
					nationalHoliday : nationalHoliday,
					openTime        : openTime,
					closeTime       : closeTime
				}, btnIndex);
			}
			else
			{
				updateButton({
					subdomain : subdomain,
					skillId   : skillId,
					btnText   : btnText,
					btnColor  : btnColor,
					textColor : textColor,
					backUrl   : backUrl
				}, btnIndex);
			}

			closeMethod();
		}
		else
		{
			setErrors({
				show   : true,
				errors : [ ...errorsAr ]
			});
		}

	};

	const handleChangeBussinessHousers = (e) =>
	{
		setBusinessHours(e.target.value);
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={show}
				onClose={() => {}}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle id='form-dialog-title'>
					<div className={classes.titleSection}>
						<div className={classes.title}>
							<div className={classes.titleText}>
								ボタン設定
							</div>
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					{errors.show === true &&
					<Box className={classes.errorBox}>
						{errors.errors.map((err, index) =>
							(<Typography className={classes.errMsg} key={index}>
								・{err}
							</Typography>)
						)}
					</Box>
					}

					<Box className={`${classes.topData}`}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>サブドメイン</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={subdomain}
								className={`${classes.inputField}`}
								onChange={handleChangeSubdomain}
								type='text'
							/>
						</Box>
					</Box>

					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>ロビーID</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={skillId}
								className={`${classes.inputField}`}
								onChange={handleChangeSkillId}
								type='text'
							/>
						</Box>
					</Box>
					<Box className={`${classes.topData}`}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>ボタンカラー</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={btnColor}
								className={`${classes.inputField}`}
								onChange={handleChangeBtnColor}
								type='text'
							/>
						</Box>
					</Box>
					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>ボタンテキスト</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={btnText}
								className={`${classes.inputField}`}
								onChange={handleChangeBtnText}
								type='text'
							/>
						</Box>
					</Box>

					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>テキストカラー</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={textColor}
								className={`${classes.inputField}`}
								onChange={handleChangeTextColor}
								type='text'
							/>
						</Box>
					</Box>
					{ type === 'service' ?
						<Box className={classes.topData}>
							<Box className={classes.inputLabel}>
								<Typography className={classes.label}>対応時間表示</Typography>
							</Box>
							<Box className={classes.inputValue}>
								<input
									value={businessHours}
									className={`${classes.inputField}`}
									onChange={handleChangeBussinessHousers}
									type='text'
								/>
							</Box>
						</Box>
						: type === 'connect' ?
							<>
								<Box className={classes.topData}>
									<Box className={classes.inputLabel}>
										<Typography className={classes.label}>対応時間表示</Typography>
									</Box>
									<Box className={classes.inputValue}>
										<input
											value={businessHours}
											className={`${classes.inputField}`}
											onChange={handleChangeBussinessHousers}
											type='text'
										/>
									</Box>
								</Box>
								<Box className={classes.topData}>
									<Box className={classes.inputLabel}>
										<Typography className={classes.label}>通話後URL</Typography>
									</Box>
									<Box className={classes.inputValue}>
										<input
											value={backUrl}
											className={`${classes.inputField}`}
											onChange={handleChangeBackUrl}
											type='text'
										/>
									</Box>
								</Box>
								<Box className={`${classes.topData}`}>
									<Box className={classes.inputLabel}>
										<Typography className={classes.label}>待受時間設定</Typography>
									</Box>
									<Box className={classes.inputValue}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={workTimeSetting}
											onChange={handleChangeWorkTimeSetting}
										>
											<MenuItem value>ON</MenuItem>
											<MenuItem value={false}>OFF</MenuItem>
										</Select>
									</Box>
								</Box>

								{ workTimeSetting &&
								<Box className={classes.workingTimeData}>
									<WorkTimeSetting
										holiday={holiday}
										handleChangeHolidayCallback={handleChangeHolidayCallback}
										openTime={openTime}
										setOpenTime={setOpenTime}
										closeTime={closeTime}
										setCloseTime={setCloseTime}
										nationalHoliday={nationalHoliday}
										handleChangeNationalHolidayCallback={
											handleChangeNationalHolidayCallback
										}
									/>
								</Box>
								}
							</>
							:
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>通話後URL</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={backUrl}
										className={`${classes.inputField}`}
										onChange={handleChangeBackUrl}
										type='text'
									/>
								</Box>
							</Box>
					}
				</DialogContent>
				<DialogActions>
					<Box className={classes.dialogButtons}>
						<Button
							className={classes.cancelBtn}
							onClick={closeMethod}
							color='primary'
						>
							<FormattedMessage
								id='label.close'
								defaultMessage='Close'
							/>
						</Button>
						<Button
							className={classes.promoteBtn}
							onClick={handleSetButton}
							color='primary'
						>
							設定
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

EditButtonDialog.propTypes =
{
	classes      : PropTypes.object.isRequired,
	show         : PropTypes.bool.isRequired,
	button       : PropTypes.object,
	btnIndex     : PropTypes.number,
	newItem      : PropTypes.bool,
	updateButton : PropTypes.func.isRequired,
	closeMethod  : PropTypes.func.isRequired,
	type         : PropTypes.string,
	newBtnKey    : PropTypes.string
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(EditButtonDialog)));
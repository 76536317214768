// React
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Redux
import { withRoomContext } from '../../../RoomContext';
import { connect } from 'react-redux';
import * as roomActions from '../../../actions/roomActions';
import * as intlActions from '../../../actions/intlActions';

// Components
import Button from '@material-ui/core/Button';
import JoinRoomNoticeDialog from '../Common/JoinRoomNoticeDialog';
import PortalLoginDialog from './PortalLoginDialog';
import TimeIndicator from './TimeIndicator';

// Mui
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

// Firebase
import { auth, firestore, functions } from '../../../lib/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

// Daysjs
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

// locale
import * as locales from '../../../translations/locales';
import { createIntl } from 'react-intl';

// const 
import { PORTAL_LOGIN, PORTAL_SECURITY_KEY, ROOM_ONLINE } from '../../../const';

import { parseUrlParams } from '../../../utils';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

dayjs.extend(weekday);

const breakPointAspectRatio = 1;

const styles = () =>
	({
		root :
		{
			display              : 'flex',
			flexDirection        : 'column',
			justifyContent       : 'center',
			alignItems           : 'flex-start',
			width                : '100%',
			height               : '100%',
			backgroundAttachment : 'fixed',
			backgroundPosition   : 'center',
			backgroundSize       : 'cover',
			backgroundRepeat     : 'no-repeat',
			position             : 'relative'
		},
		buttonAreaPC :
		{
			position       : 'absolute',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			margin         : 'auto',
			width          : '50vw',
			height         : '25vh',
			visibility     : 'hidden' // hide first because ugly shape show up
		},
		buttonAreaSP :
		{
			position       : 'absolute',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			margin         : 'auto',
			width          : '90vw',
			height         : '25vh',
			visibility     : 'hidden' // hide first because ugly shape show up
		},
		buttonRow :
		{
			display        : 'flex',
			justifyContent : 'center',
			width          : '100%',
			padding        : '0 1%',
			margin         : '1% 0'
		},
		joinButton :
		{
			display      : 'block',
			borderRadius : '5px',
			boxShadow    : '#CCC 5px 5px 5px',
			fontSize     : '1.2rem',
			'&:hover'    :
			{
				filter : 'saturate(105%) brightness(105%)'
			},
			'&:only-child' :
			{
				marginRight : 'auto !important',
				marginLeft  : 'auto !important'
			}
		},
		imageContentPC :
		{
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100vw',
			maxHeight      : '100%'
		},
		imageContentSP :
		{
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100%',
			maxHeight      : '100vh'
		},
		container : {
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'center',
			width          : '100%',
			height         : '100%'
		},
		imageArea : {
			position : 'relative'
		},
		disabledScreen : {
			position        : 'fixed',
			top             : '0',
			left            : '0',
			height          : '100vh',
			width           : '100vw',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'white'
		},
		disabledIndicator : {
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'white',
			padding         : '20px 25px',
			border          : '1px solid var(--text-color)',
			borderRadius    : '10px',
			maxWidth        : '90vw',
			marginBottom    : '15%'
		},
		disabledText : {
			fontSize  : '1.5rem',
			color     : 'var(--text-color)',
			textAlign : 'center'
		},
		circular : {
			width           : '100%',
			height          : '100%',
			zIndex          : 1000,
			position        : 'fixed',
			top             : 0,
			left            : 0,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		}
	});

let roomStatusListener = null;

let portalName = '';

let portalKey = '';

const PortalHome = ({
	classes,
	setJoinRoomNoticeDialogOpen,
	updateIntl,
	locale
}) =>
{
	const location = useLocation();

	const [ portalLogin ] = useAuthState(auth);

	const [	portalLoginDialogOpen, setPortalLoginDialogOpen ] = useState(false);

	const [ portalConnectionData, setPortalConnectionData ] = useState({});

	const [ portalId, setPortalId ] = useState(null);

	const [ invalidKey, setInvalidKey ] = useState(false);

	const [ loading, setLoading ] = useState(false);

	const [ ipRestriction, setIpRestriction ] = useState(false);

	const [ hideTalkTime, setHideTalkTime ] = useState(true);

	// Image URls
	const [ bgPCUrl, setBgPCUrl ] = useState('');
	const [ bgSPUrl, setBgSPUrl ] = useState('');

	const [ buttons, setButtons ] = useState([]);

	// Button Area
	const [ btnAreaContent, setBtnAreaContent ] = useState({
		PC : {
			areaWidth         : '50vw',
			areaHight         : '25vh',
			columns           : 2,
			btnMargin         : '2vh 2vw',
			btnPadding        : '2vh 4vw',
			btnFontSize       : '1.5vw',
			btnFontSizeSecond : '1vw'
		},
		SP : {
			areaWidth         : '90vw',
			areaHight         : '25vh',
			columns           : 1,
			btnMargin         : '2vh 2vw',
			btnPadding        : '2vh 4vw',
			btnFontSize       : '2vh',
			btnFontSizeSecond : '1.5vh'
		}
	});

	const [ btnAreaPositionObj, setBtnAreaPositionObj ] = useState({
		PC : { top: '25', bottom: '', left: '', right: '', verticCenter: false, horizCenter: true },
		SP : { top: '25', bottom: '', verticCenter: false }
	});

	const [ btnAreaRows, setBtnAreaRows ] = useState({
		PC : 1,
		SP : 1
	});

	// background
	const [ bgPCGradient, setBgPCGradient ] = useState('#EEEEEE, #EEEEEE');
	const [ bgSPGradient, setBgSPGradient ] = useState('#EEEEEE, #EEEEEE');

	// window size
	const [ aspectRatio, setAspectRatio ] = useState(window.innerWidth/window.innerHeight);

	// available room
	const [ roomAvailable, setRoomAvailable ] = useState([]);

	const buttonAreaRef = useRef();

	const bgImgRef = useRef();
	const bgImgAreaRef = useRef();

	const [ talkingMinutesLimit, setTalkingMinutesLimit ] = useState(null);

	const [ disabled, setDisabled ] = useState(false);

	const [ termsPage, setTermsPage ] = useState('default');

	const [ backButton, setBackButton ] = useState({
		active          : false,
		text            : '戻る',
		url             : '',
		color           : '#FFF',
		backgroundColor : '#B7B7B7',
		textMarginPC    : '0',
		textMarginSP    : '1vh 0'
	});

	const updateDimensions = useCallback(() =>
	{

		if (buttonAreaRef.current)
		{
			buttonAreaRef.current.style.visibility = 'visible';

			let deviceType = 'PC';

			// SP

			if (window.innerWidth/window.innerHeight < breakPointAspectRatio)
			{
				deviceType = 'SP';
			}

			if (btnAreaContent[deviceType].areaWidth)
			{
				buttonAreaRef.current.style.width = btnAreaContent[deviceType].areaWidth;
			}

			if (btnAreaContent[deviceType].areaHeight)
			{
				buttonAreaRef.current.style.height = btnAreaContent[deviceType].areaHeight;
			}

			// button Area location
			if (btnAreaPositionObj[deviceType].verticCenter)
			{
				buttonAreaRef.current.style.top = '0';
				buttonAreaRef.current.style.bottom = '0';
			}
			else
			{
				buttonAreaRef.current.style.top = btnAreaPositionObj[deviceType].top ? `${btnAreaPositionObj[deviceType].top}%` : '';
				buttonAreaRef.current.style.bottom = btnAreaPositionObj[deviceType].bottom ? `${btnAreaPositionObj[deviceType].bottom}%` : '';
			}

			if (deviceType === 'SP' || btnAreaPositionObj[deviceType].horizCenter)
			{
				buttonAreaRef.current.style.left = '0';
				buttonAreaRef.current.style.right = '0';
			}
			else
			{
				buttonAreaRef.current.style.left = btnAreaPositionObj.PC.left ? `${btnAreaPositionObj.PC.left}%` : '';
				buttonAreaRef.current.style.right = btnAreaPositionObj.PC.right ?`${btnAreaPositionObj.PC.right}%` : '';
			}
		}

		if (bgImgRef.current && bgImgAreaRef.current)
		{

			// PC case. window height is smaller than background image
			if (window.innerWidth/window.innerHeight >= breakPointAspectRatio)
			{
				// img size is limited by Height (horizontally long device)
				if (window.innerHeight <= bgImgRef.current.clientHeight)
				{
					bgImgAreaRef.current.style.height = '100%';
					bgImgAreaRef.current.style.width = '';
				}
				else
				{
					bgImgAreaRef.current.style.height = '';
					bgImgAreaRef.current.style.width = '100%';
				}
			}

			// Smart phone case. window width is smaller than background image
			else
			// img size is limited by Width (vertically long device)
			if (window.innerWidth <= bgImgRef.current.clientWidth)
			{
				bgImgAreaRef.current.style.width = '100%';
				bgImgAreaRef.current.style.height = '';
			}
			else
			{
				bgImgAreaRef.current.style.width = '';
				bgImgAreaRef.current.style.height = '100%';
			}

		}

		setAspectRatio(window.innerWidth/window.innerHeight);

	}, [ btnAreaPositionObj, btnAreaContent ]);

	// /portalは日本語のみ使用(アプリで意図せずに英語が指定されてしまう場合がある)
	useEffect(() =>
	{
		const one = locales.loadOne('ja');

		updateIntl({
			locale   : one.locale[0],
			messages : one.messages,
			list   	 : locales.getList()
		});

		createIntl({
			locale   : one.locale[0],
			messages : one.messages
		});

		document.documentElement.lang = one.locale[0].toUpperCase();
	}, [ updateIntl ]);

	useEffect(() =>
	{
		window.addEventListener('resize', updateDimensions);

		return () => window.removeEventListener('resize', updateDimensions);

	}, [ updateDimensions ]);

	const fetchInfos = useCallback(() =>
	{

		firestore.collection('portals').doc(portalId)
			.get()
			.then((doc) =>
			{

				if (doc.exists)
				{
					const portalData = doc.data();

					// ip address check
					if (portalData.ipAddressRestriction)
					{
						setLoading(true);

						const checkIoAddressAPI = functions.httpsCallable('checkIpAddress');

						checkIoAddressAPI().then((res) =>
						{

							const ipAddress = res.data?.ipAddress;

							if (ipAddress
								&& portalData.ipAddresses
								&& portalData.ipAddresses.includes(ipAddress))
							{
								setIpRestriction(false);
								setLoading(false);
							}
							else
							{
								setIpRestriction(true);
								setLoading(false);
							}
						});
					}
					// login
					if (portalData.authenticate === PORTAL_LOGIN)
					{
						// check login. check portalId by email address.
						if (!portalLogin || !portalLogin.email.endsWith(`${portalId}.portallogin.2nddoor.net`))
						{
							setPortalLoginDialogOpen(true);
						}
						else
						{
							const expireDays = portalData.authenticateExpireDays ?
								Number(portalData.authenticateExpireDays) : 0;

							if (expireDays === 0)
							{
								setPortalLoginDialogOpen(false);
							}
							else
							{
								const prevLoginTimestamp = localStorage.getItem('portalLoginTimestamp');

								const currentTimestamp = Date.now();

								const oneDay = 86400000;

								// check if authentication expires
								if (
									!prevLoginTimestamp
									|| (currentTimestamp > Number(prevLoginTimestamp) + expireDays * oneDay)
								)
								{
									setPortalLoginDialogOpen(true);
								}
								else
								{
									setPortalLoginDialogOpen(false);
								}
							}
						}
					}
					else if (portalData.authenticate === PORTAL_SECURITY_KEY)
					{
						// authenticate by url security key
						if (portalData.securityKey === portalKey)
						{
							setInvalidKey(false);
						}
						else
						{
							setInvalidKey(true);
						}
					}

					portalName = portalData.portalName ? portalData.portalName : '';

					setTermsPage(portalData.termsPage ? portalData.termsPage : 'default');

					if (portalData.browserTitle)
					{
						document.title = portalData.browserTitle;
					}

					if (portalData.talkingTimeLimit)
					{

						setTalkingMinutesLimit(portalData.talkingTimeLimit);
					}

					if (portalData.backButtonActive)
					{
						setBackButton({
							active          : true,
							text            : portalData.backButtonText ? portalData.backButtonText : '戻る',
							url             : portalData.backButtonUrl,
							color           : portalData.backButtonTextColor ? `#${portalData.backButtonTextColor}` : '#FFF',
							backgroundColor : portalData.backButtonBGColor ? `#${portalData.backButtonBGColor}` : '#B7B7B7',
							textMarginPC    : portalData.backButtonTextMarginPC ? portalData.backButtonTextMarginPC : '0',
							textMarginSP    : portalData.backButtonTextMarginSP ? portalData.backButtonTextMarginSP : '1vh 0'
						});
					}

					setBgPCUrl(portalData.bgPCUrl ? portalData.bgPCUrl : '');
					setBgSPUrl(portalData.bgSPUrl ? portalData.bgSPUrl : '');
					setBgPCGradient(portalData.bgPCGradient ? portalData.bgPCGradient : '#EEEEEE, #EEEEEE');
					setBgSPGradient(portalData.bgSPGradient ? portalData.bgSPGradient : '#EEEEEE, #EEEEEE');

					setDisabled(portalData.disabled ? true: false);

					setBtnAreaContent(portalData.btnAreaContent
						?	portalData.btnAreaContent
						: {
							PC : {
								areaWidth         : '50vw',
								areaHeight        : '25vh',
								columns           : 2,
								btnMargin         : '2vh 2vw',
								btnPadding        : '2vh 4vw',
								btnFontSize       : '1.5vw',
								btnFontSizeSecond : '1vw'
							},
							SP : {
								areaWidth         : '90vw',
								areaHeight        : '25vh',
								columns           : 1,
								btnMargin         : '2vh 2vw',
								btnPadding        : '2vh 4vw',
								btnFontSize       : '2vw',
								btnFontSizeSecond : '1.5vw'
							}
						}
					);
					setBtnAreaPositionObj(portalData.btnAreaPosition
						? portalData.btnAreaPosition
						: {
							PC : { top: '25', bottom: '', left: '', right: '', verticCenter: false, horizCenter: true },
							SP : { top: '25', bottom: '', verticCenter: false }
						}
					);

					if (portalData.buttons)
					{
						// how many rows in btnArea
						const pcRows = portalData.btnAreaContent?.PC?.columns
							? Math.ceil(
								portalData.buttons.length
											/portalData.btnAreaContent.PC.columns
							) : 1;

						const spRows = portalData.btnAreaContent?.SP?.columns
							? Math.ceil(
								portalData.buttons.length
											/portalData.btnAreaContent.SP.columns
							) : 1;

						setBtnAreaRows(
							{
								PC : pcRows,
								SP : spRows
							});
					}

					setButtons(portalData.buttons ? portalData.buttons : []);

					const buttonSubdomains = [];

					portalData.buttons.forEach((b) =>
					{
						if (!buttonSubdomains.includes(b.subdomain))
						{
							buttonSubdomains.push(b.subdomain);
						}
					});

					setHideTalkTime(portalData.hideTalkTime ? true : false);

					roomStatusListener = firestore.collection('rooms').where('subdomain', 'in', buttonSubdomains)
						.onSnapshot((snapshot) =>
						{
							// サブドメインごとにavailable/not availableをチェック
							// [{subdomain:'a',available:true},{subdomain:'b',available:false}]
							const subdomainAvailable = [];

							snapshot.forEach((r) =>
							{
								if (r.exists)
								{
									const roomData = r.data();

									if (roomData.status === ROOM_ONLINE)
									{
										subdomainAvailable.push(roomData.subdomain);
									}
								}
							});

							const roomAvailableAr = [];

							// buttonsに移し替える
							if (portalData?.buttons)
							{
								portalData.buttons.forEach((b, btnIndex) =>
								{

									const subdomainIndex = subdomainAvailable
										.findIndex((item) => item === b.subdomain);

									if (subdomainIndex !== -1)
									{
										roomAvailableAr[btnIndex] = true;
									}
									else
									{
										roomAvailableAr[btnIndex] = false;
									}
								});

								setRoomAvailable([ ...roomAvailableAr ]);
							}

						});

				}
			});
	}, [ portalId, portalLogin ]);

	const handleJoin = (itemIndex) =>
	{
		if (buttons[itemIndex] && !portalLoginDialogOpen)
		{
			const targetBtn = buttons[itemIndex];

			setPortalConnectionData({
				portalId   : portalId,
				portalName : portalName,
				portalKey  : portalKey,
				termsPage  : termsPage,
				subdomain  : targetBtn.subdomain ? targetBtn.subdomain : '',
				skillId    : targetBtn.skillId ? targetBtn.skillId : '',
				backUrl    : targetBtn.backUrl ? targetBtn.backUrl : '',
				locale     : locale ? locale : ''
			});

			setJoinRoomNoticeDialogOpen(true);
		}
	};

	const locationReplace = (url) =>
	{
		window.location.replace(url);
	};

	useEffect(() =>
	{
		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		portalKey = '';

		if (paramsValue)
		{
			const paramsObj = parseUrlParams({
				paramsValues : paramsValue,
				keys         : [ 'id', 'portalKey' ]
			});

			if (paramsObj['id'])
			{
				setPortalId(paramsObj['id']);
			}

			if (paramsObj['portalKey'])
			{
				portalKey = paramsObj['portalKey'];
			}
		}

	}, [ location.search ]);

	useEffect(() =>
	{
		if (portalId)
		{
			fetchInfos();
		}

		return () =>
		{
			if (roomStatusListener)
			{
				// stop listening
				try
				{
					roomStatusListener();
				}
				catch
				{
					// do nothing
				}
			}

			roomStatusListener = null;

		};
	}, [ fetchInfos, portalId ]);

	return (
		<>
			{ loading ?
				<div className={classes.circular}>
					<CircularProgress />
				</div>
				: disabled ?
					<div className={classes.disabledScreen}>
						<div className={classes.disabledIndicator}>
							<p className={classes.disabledText}>
								現在使用できません。サービス運営会社にお問い合わせください。
							</p>
						</div>
					</div>
					: invalidKey ?
						<div className={classes.disabledScreen}>
							<div className={classes.disabledIndicator}>
								<p className={classes.disabledText}>
									URLが正しくありません
								</p>
							</div>
						</div>
						: ipRestriction ?
							<div className={classes.disabledScreen}>
								<div className={classes.disabledIndicator}>
									<p className={classes.disabledText}>
										このネットワークからは使用できません
									</p>
								</div>
							</div>
							:
							<>
								<PortalLoginDialog
									portalLoginDialogOpen={portalLoginDialogOpen}
									setPortalLoginDialogOpen={setPortalLoginDialogOpen}
									portalId={portalId}
								/>
								<div
									className={classes.root}
									style={aspectRatio < breakPointAspectRatio ? { background: `linear-gradient(${bgSPGradient})` } :{ background: `linear-gradient(${bgPCGradient})` }}
								>
									{ aspectRatio < breakPointAspectRatio ?
										<>
											<div className={classes.container}>
												<div className={classes.imageArea} ref={bgImgAreaRef}>
													{ bgSPUrl &&
													<img
														className={classes.imageContentSP}
														src={bgSPUrl}
														alt='PageImage'
														ref={bgImgRef}
														onLoad={updateDimensions}
													/>
													}
													<div className={classes.buttonAreaSP} ref={buttonAreaRef}>
														<>
															{ [ ...Array(btnAreaRows.SP) ].map((_r, rowIndex) =>
															{
																return (
																	<div className={classes.buttonRow} key={rowIndex}>
																		<>
																			{[ ...Array(btnAreaContent.SP.columns) ]
																				.map((_c, columnIndex) =>
																				{
																					const itemIndex =
														rowIndex*btnAreaContent.SP.columns + columnIndex;

																					if (buttons[itemIndex])
																					{
																						return (
																							<Button
																								key={columnIndex}
																								style={{
																									backgroundColor :
																							`#${buttons[itemIndex].btnColor}`,
																									padding :
																							btnAreaContent.SP.btnPadding,
																									margin :
																							btnAreaContent.SP.btnMargin
																								}}
																								className={classes.joinButton}
																								onClick={() => handleJoin(itemIndex)}
																							>
																								<Typography
																									className={classes.joinButtonText}
																									style={{
																										color :
																								`#${buttons[itemIndex].textColor}`,
																										fontSize :
																								btnAreaContent.SP.btnFontSize
																									}}
																								>

																									{buttons[itemIndex].btnText}
																								</Typography>
																								<Typography
																									className={classes.joinButtonTextSmall}
																									style={{
																										color :
																								`#${buttons[itemIndex].textColor}`,
																										fontSize :
																								btnAreaContent.SP.btnFontSizeSecond
																									}}
																								>
																									{
																										roomAvailable[itemIndex] ?
																											'　ただいま利用可能です　'
																											:
																											'ただいま混み合っています'
																									}
																								</Typography>
																							</Button>
																						);
																					}
																					else
																					{
																						return null;
																					}
																				})
																			}
																		</>
																	</div>
																);
															}
															)}
														</>
														{ backButton.active &&
														<div className={classes.buttonRow}>
															<Button
																style={{
																	backgroundColor : backButton.backgroundColor,
																	padding         : btnAreaContent.SP.btnPadding,
																	margin          : btnAreaContent.SP.btnMargin
																}}
																className={classes.joinButton}
																onClick={() => locationReplace(backButton.url)}
															>
																<Typography
																	className={classes.joinButtonText}
																	style={{
																		color    : backButton.color,
																		minWidth : '20vw',
																		margin   : backButton.textMarginSP,
																		fontSize :
																btnAreaContent.SP.btnFontSize
																	}}
																>
																	{backButton.text}
																</Typography>
															</Button>
														</div>
														}
													</div>
												</div>
											</div>
										</>
										:
										<>
											<div className={classes.container}>
												<div className={classes.imageArea} ref={bgImgAreaRef}>
													{ bgPCUrl &&
													<img
														className={classes.imageContentPC}
														src={bgPCUrl}
														alt='PageImage'
														ref={bgImgRef}
														onLoad={updateDimensions}
													/>
													}

													<div className={classes.buttonAreaPC} ref={buttonAreaRef}>
														<>
															{ [ ...Array(btnAreaRows.PC) ].map((_r, rowIndex) =>
															{
																return (
																	<div className={classes.buttonRow} key={rowIndex}>
																		<>
																			{[ ...Array(btnAreaContent.PC.columns) ]
																				.map((_c, columnIndex) =>
																				{
																					const itemIndex =
														rowIndex*btnAreaContent.PC.columns + columnIndex;

																					if (buttons[itemIndex])
																					{
																						return (
																							<Button
																								key={columnIndex}
																								style={{
																									backgroundColor :
																							`#${buttons[itemIndex].btnColor}`,
																									padding :
																							btnAreaContent.PC.btnPadding,
																									margin :
																							btnAreaContent.PC.btnMargin
																								}}
																								className={classes.joinButton}
																								onClick={() => handleJoin(itemIndex)}
																							>
																								<Typography
																									style={{
																										color :
																								`#${buttons[itemIndex].textColor}`,
																										fontSize :
																								btnAreaContent.PC.btnFontSize
																									}}
																								>

																									{buttons[itemIndex].btnText}
																								</Typography>
																								<Typography
																									style={{
																										color :
																								`#${buttons[itemIndex].textColor}`,
																										fontSize :
																								btnAreaContent.PC.btnFontSizeSecond
																									}}
																								>
																									{
																										roomAvailable[itemIndex] ?
																											'　ただいま利用可能です　'
																											:
																											'ただいま混み合っています'
																									}
																								</Typography>
																							</Button>

																						);
																					}
																					else
																					{
																						return null;
																					}
																				})
																			}
																		</>
																	</div>
																);
															}
															)}
														</>
														{ backButton.active &&
														<div className={classes.buttonRow}>
															<Button
																style={{
																	backgroundColor : backButton.backgroundColor,
																	padding         : btnAreaContent.PC.btnPadding,
																	margin          : btnAreaContent.PC.btnMargin
																}}
																className={classes.joinButton}
																onClick={() => locationReplace(backButton.url)}
															>
																<Typography
																	className={classes.joinButtonText}
																	style={{
																		color    : backButton.color,
																		minWidth : '8vw',
																		margin   : backButton.textMarginPC,
																		fontSize :
																btnAreaContent.PC.btnFontSize
																	}}
																>
																	{backButton.text}
																</Typography>
															</Button>
														</div>
														}
													</div>

												</div>
											</div>

										</>

									}
									{
										(talkingMinutesLimit && !hideTalkTime) &&
										<TimeIndicator
											portalId={portalId}
											talkingMinutesLimit={talkingMinutesLimit}
											isSP={aspectRatio < breakPointAspectRatio}
										/>
									}
									<JoinRoomNoticeDialog
										type={'portal'}
										portalConnectionData={portalConnectionData}
									/>
								</div>
							</>
			}
		</>
	);
};

PortalHome.propTypes =
{
	classes                     : PropTypes.object.isRequired,
	updateIntl                  : PropTypes.func.isRequired,
	locale                      : PropTypes.string.isRequired,
	setJoinRoomNoticeDialogOpen : PropTypes.func.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		locale : state.intl.locale
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setJoinRoomNoticeDialogOpen : (joinRoomNoticeDialogOpen) =>
		{
			dispatch(roomActions.setJoinRoomNoticeDialogOpen(joinRoomNoticeDialogOpen));
		},
		updateIntl : (args) =>
		{
			dispatch(intlActions.updateIntl({ ...args }));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.intl.locale === next.intl.locale
			);
		}
	}
)(withStyles(styles)(PortalHome)));

// React
import React, { useState, useEffect, useRef } from 'react';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../../RoomContext';

// Router
import { useLocation, useHistory, Link } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Message
import { FormattedMessage, useIntl } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';

// Firestore
import { functions, firestore } from '../../../../lib/firebase';

// util
import { getDomainWithoutSubdomain, parseUrlParams, showTaxIncludedAmount, getGmoShopId, getGmoTokenUrl, showNumberWithComma, filterPriceListItem } from '../../../../utils';

// const
import { DEFAULT_SERVICE_ACCOUNT_KEY, PAYMENT_AMAZON_PAY, ISSUED_AT_GUEST_PAGE, PAYMENT_CREDIT_CARD, PURCHASE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE } from '../../../../const';

import VisaMark from '../../../../images/payments/visa.svg';
import MasterMark from '../../../../images/payments/master.svg';
import AmexMark from '../../../../images/payments/amex.svg';
import DinersMark from '../../../../images/payments/diners.svg';
import JcbMark from '../../../../images/payments/jcb.svg';
import AmazonPayMark from '../../../../images/payments/amazonpay.svg';

// components
import PurchaseComplete from '../Parts/PurchaseComplete';
import TicketInfoTable from '../Parts/TicketInfoTable';
import ServiceAppBar from '../Parts/ServiceAppBar';

const styles = (theme) =>
	({
		root : {
			zIndex          : 99,
			width           : '100%',
			height          : '100%',
			overflow        : 'auto',
			backgroundColor : '#EBEBEC'
		},
		content : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		contentArea : {
			width           : 'calc( 100% - 20px )',
			maxWidth        : '750px',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			margin          : '30px 15px',
			padding         : '30px 0px',
			minWidth        : '320px',
			backgroundColor : '#FFF',
			borderRadius    : '20px'
		},
		pageContent : {
			width          : 'calc( 100% - 20px )',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'
		},
		BtnArea : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			margin         : '15px 0 30px 0'
		},
		titleSection : {
			margin         : '10px 0 10px 0',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		amazonPayTitle : {
			marginTop : '80px'
		},
		title : {
			fontSize : '1.1rem'
		},
		priceMessageSection : {
			marginTop      : '5px',
			marginBottom   : '10px',
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		priceMessage : {
			fontSize  : '0.9rem',
			textAlign : 'center',
			width     : '98%',
			maxWidth  : '500px',
			minWidth  : '280px'
		},
		subTitle : {
			paddingLeft  : '18px',
			borderLeft   : '2px solid #cd2c82',
			fontSize     : '1rem',
			marginBottom : '15px'
		},
		messageAreaMarginBtm : {
			margin : '20px 0 10px 0'
		},
		messageAreaMarginBoth : {
			margin : '10px 0'
		},
		messegeText : {
			fontSize : '0.8rem',
			color    : 'var(--text-color)'
		},
		messegeTextPaddingLeft : {
			paddingLeft : '7px'
		},
		cautionText : {
			width     : '100%',
			textAlign : 'center'
		},
		termsArea : {
			margin  : '20px 0 0 0',
			padding : '10px',
			border  : '1px solid #292929'
		},
		termsText : {
			fontSize : '0.8rem',
			color    : '#292929'
		},
		termsTextPaddingLeft : {
			paddingLeft : '10px'
		},
		contentWrapper : {
			width     : '98%',
			maxWidth  : '520px',
			minWidth  : '280px',
			marginTop : '20px'
		},
		paymentItemBox : {
			border       : '1px solid #9E9E9E',
			borderRadius : '5px'
		},
		paymentItemLine : {
			display        : 'flex',
			width          : '100%',
			justifyContent : 'space-between'
		},
		paymentItemLineAbove : {
			borderBottom : '1px solid #9E9E9E'
		},
		paymentItemSelect : {
			padding : '5px 5px 5px 0'
		},
		paymentItemLabel : {
			fontSize      : '1rem',
			lineHeight    : '36px',
			verticalAlign : 'middle',
			fontFamily    : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
		},
		paymentLabelArea : {
			display : 'flex'
		},
		paymentLogoArea : {
			display        : 'flex',
			justifyContent : 'end',
			alignItems     : 'center'
		},
		paymentLogo : {
			height      : '16px',
			width       : 'auto',
			marginRight : '3px'
		},
		paymentLogoVisa : {
			height      : '12px',
			width       : 'auto',
			marginRight : '3px'
		},
		paymentLogoAmazon : {
			height      : '14px',
			width       : 'auto',
			marginRight : '3px'
		},
		amazonPayButtonArea : {
			marginTop : '30px'
		},
		purchaseTicketSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '15px 0',
			width          : '100%'
		},
		copyCodeBtn : {
			padding         : '1px 10px',
			backgroundColor : '#cd2c82',
			color           : '#FFF',
			cursor          : 'pointer',
			'&:hover'       : {
				backgroundColor : '#cd2c82',
				color           : '#FFF'
			}
		},
		freeTicketText : {
			cursor         : 'pointer',
			fontSize       : '0.8rem',
			color          : '#292929',
			textDecoration : 'underline'
		},
		purchaseTicketText : {
			fontSize  : '0.8rem',
			color     : '#292929',
			width     : '100%',
			textAlign : 'center'
		},
		btnGroup : {
			width      : '90%',
			marginLeft : '10%',
			marginTop  : '8px'
		},
		explanationBox :
		{
			marginBottom : '25px'
		},
		explanationText :
		{
			color    : '#999898',
			fontSize : '0.8rem'
		},
		contentText :
		{
			color    : '#292929',
			fontSize : '1rem'
		},
		contentTextSmall :
		{
			color    : '#292929',
			fontSize : '0.8rem'
		},
		inputLabel : {
			width                        : '100%',
			fontSize                     : '0.8rem',
			paddingBottom                : '0px',
			[theme.breakpoints.up('sm')] :
			{
				width         : '40%',
				fontSize      : '0.8rem',
				height        : '37px',
				lineHeight    : '37px',
				verticalAlign : 'middle'
			}
		},
		inputLine : {
			width                        : '100%',
			display                      : 'block',
			marginBottom                 : '3px',
			[theme.breakpoints.up('sm')] :
			{
				display        : 'flex',
				justifyContent : 'space-between',
				marginTop      : '10px'
			}
		},
		inputText : {
			background   : '#FFF',
			padding      : '0.6rem 0.6rem',
			borderRadius : '0.2rem',
			color        : '#292929',
			textAlign    : 'left',
			border       : '1px solid #9E9E9E',
			fontSize     : '16px',
			'&:focus'    : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#E8E8E8',
				color           : 'var(--text-color)'
			},
			'&::-ms-reveal' : {
				display : 'none'
			}
		},
		inputTextTicket : {
			width                        : '100%',
			[theme.breakpoints.up('sm')] :
			{
				width    : '55%',
				maxWidth : '250px'
			}
		},
		inputCreditCardInfo : {
			width : '100%'
		},
		inputCreditCardYear : {
			width : '65px'
		},
		inputCreditCardMonth : {
			width : '65px'
		},
		inputCreditCardSecurityCode : {
			width : '150px'
		},
		visibilityButton : {
			position  : 'absolute',
			top       : '0',
			bottom    : '16px',
			right     : '-6px',
			color     : '#FFF',
			'&:hover' : {
				backgroundColor : 'transparent'
			}
		},
		creditCardInput : {
			marginTop : '15px'
		},
		creditCardLabel : {
			fontSize : '0.8rem',
			color    : 'var(--text-color)'
		},
		creditCardPeriod : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'start'
		},
		creditCardPeriodItemFirst : {
			marginRight : '20px'
		},
		creditCardPeriodText : {
			height        : '37px',
			lineHeight    : '37px',
			verticalAlign : 'middle',
			fontSize      : '0.9rem',
			margin        : '0 3px'
		},
		agreeTermsLine : {
			marginTop      : '15px',
			width          : '100%',
			padding        : '5px 0',
			display        : 'flex',
			justifyContent : 'center'
		},
		agreeTermsCheckBox : {
			width          : '30px',
			display        : 'flex',
			justifyContent : 'end'
		},
		agreeTermsText : {
			paddingLeft   : '10px',
			fontSize      : '0.7rem',
			color         : '#292929',
			lineHeight    : '26px',
			verticalAlign : 'middle'
		},
		amazonPayButton : {
			width  : '280px',
			height : 'auto',
			cursor : 'pointer'
		},
		circle : {
			width           : '4rem',
			height          : '4rem',
			borderRadius    : '4rem',
			backgroundColor : '#999898',
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			margin          : '0.5rem 0'
		},
		priceListColumn : {
			width          : '100%',
			margin         : '0 0 20px 0',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'start',
			alignItems     : 'center'
		},
		priceListBoxTitle : {
			color       : '#cd2c82',
			fontSize    : '0.9rem',
			width       : '100%',
			paddingLeft : '15px'
		},
		priceListBoxTitleCenter : {
			textAlign   : 'center',
			paddingLeft : '0 !important'
		},
		priceBox : {
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'start',
			alignItems     : 'flex-start',
			border         : '1px solid #292929',
			borderRadius   : '10px',
			width          : 'calc( 100% - 20px )'
		},
		priceListLine : {
			width                : '100%',
			display              : 'flex',
			justifyContent       : 'space-between',
			'&:last-child > div' : {
				borderBottom : 'none !important'
			},
			'&:first-child' : {
				backgroundColor      : '#cd2c82',
				borderTopLeftRadius  : '9px',
				borderTopRightRadius : '9px'
			},
			'&:first-child > div' : {
				color : '#FFF'
			}
		},
		priceListItem : {
			display        : 'flex',
			justifyContent : 'center',
			padding        : '8px 5px',
			color          : '#292929',
			borderBottom   : '1px solid #292929',
			fontSize       : '0.9rem'
		},
		priceItem : {
			textAlign  : 'center',
			height     : '38px',
			lineHeight : '38px',
			color      : '#292929',
			fontSize   : '1rem'
		},
		priceItemLeft : {
			width       : '15%',
			borderRight : '1px solid #292929',
			fontFamily  : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
		},
		priceItemRight : {
			width          : '85%',
			display        : 'flex',
			justifyContent : 'space-around',
			fontFamily     : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
		},
		priceItemRightChild : {
			fontSize       : '1rem',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		priceItemRightFirst :
		{
			width   : '53%',
			padding : '0 5px'
		},
		priceItemRightSecond : {
			width : '5%'
		},
		priceItemRightThird : {
			width   : '47%',
			padding : '0 5px'
		},
		priceItemRightTitle : {
			backgroundColor              : 'white',
			color                        : '#cd2c82',
			padding                      : '0px 3px',
			[theme.breakpoints.up('sm')] :
			{
				padding : '0px 10px'
			}
		},
		priceText : {
			minWidth  : '80px',
			textAlign : 'end'
		},
		timeText : {
			fontSize   : '1rem',
			lineHeight : '20px',
			textAlign  : 'center'
		},
		nolimitTimeText : {
			fontSize   : '1rem',
			lineHeight : '20px',
			fontWeight : 'bold',
			textAlign  : 'center'
		},
		buttonSection : {
			width                        : '100%',
			maxWidth                     : '450px',
			minWidth                     : '300px',
			marginTop                    : '30px',
			display                      : 'flex',
			padding                      : '0 3px',
			justifyContent               : 'space-between',
			[theme.breakpoints.up('sm')] :
			{
				maxWidth : '500px'
			}
		},
		singleButtonSection : {
			width          : '100%',
			marginTop      : '30px',
			display        : 'flex',
			padding        : '0 3px',
			justifyContent : 'center'
		},
		button : {
			padding                      : '4px 8px',
			width                        : '45%',
			maxWidth                     : '300px',
			borderRadius                 : '10px',
			fontSize                     : '0.9rem',
			[theme.breakpoints.up('sm')] :
			{
				width : '40%'
			}
		},
		centerButton : {
			padding      : '6px 10px',
			width        : '70%',
			maxWidth     : '300px',
			borderRadius : '10px',
			fontSize     : '1.1rem'
		},
		backButton : {
			backgroundColor : 'white',
			color           : '#cd2c82',
			border          : '1px solid #cd2c82',
			'&:hover'       : {
				color  : '#D9388E',
				border : '1px solid #D9388E'
			}
		},
		amazonPayBackBtn : {
			marginTop : '50px'
		},
		proceedButton : {
			backgroundColor : '#cd2c82',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#D9388E'
			}
		},
		movePageSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '15px 0',
			width          : '100%'
		},
		movePageButton : {
			boxShadow       : '2px 2px 4px #aaa',
			backgroundColor : '#292929',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#4B4B4B'
			}
		},
		purchaseButton : {
			backgroundColor : '#292929',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#4B4B4B'
			}
		},
		forgotPasswordLabel :
		{
			fontSize     : '0.6rem',
			color        : '#999898',
			textAlign    : 'center',
			marginBottom : '16px'
		},
		link :
		{
			color  : 'var(--submit-button-color)',
			cursor : 'pointer'
		},
		loadingWrapper :
		{
			width           : '100%',
			height          : 'calc( 100% - 40px )',
			display         : 'flex',
			paddingTop      : '250px',
			justifyContent  : 'center',
			alignItems      : 'flex-start',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		},
		loadingIndicator :
		{
			color : 'var(--text-color)'
		},
		errorMsg : {
			width        : '100%',
			color        : 'red',
			fontSize     : '0.8rem',
			textAlign    : 'center',
			marginBottom : '10px'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides :
	{
		MuiOutlinedInput : {
			input : {
				'&::-ms-reveal' : {
					display : 'none'
				}
			}
		},
		PrivateSwitchBase : {
			root : {
				padding : '1px'
			}
		}
	}
});

const today = new Date();

const thisYear = today.getFullYear().toString();

const convertNumber = (text) =>
{
	if (text)
	{
		const numbersToReplace = '０１２３４５６７８９';
		const reFullnums = new RegExp(`[${numbersToReplace}]`, 'g');

		return text.replace(reFullnums, (m) => numbersToReplace.indexOf(m));
	}
	else
	{
		return '';
	}
};

const amazonPayButtonScriptFunc = (config) =>
{
	return `
		amazon.Pay.renderButton('#AmazonPayButton', {
			merchantId: 'A27IBUVFX2BY9F',
			ledgerCurrency: 'JPY',
			sandbox: ${config.domain === 'online-call.com' ? 'false' : 'true'},
			checkoutLanguage: 'ja_JP',
			productType: 'PayOnly',
			placement: 'Cart',
			buttonColor: 'Gold',
			createCheckoutSessionConfig: {
				payloadJSON: ${config.payloadJson},
				signature: '${config.signature}',
				publicKeyId : '${config.publicKeyId}'
			}
		});
`;
};

const ServiceGuestPurchase = ({
	classes
}) =>
{
	const intl = useIntl();
	const location = useLocation();
	const history = useHistory();

	const [ page, setPage ] = useState('home');

	const [ loading, setLoading ] = useState(true);

	const [ serviceParamKey, setServiceParamKey ] = useState('');

	const [ priceList, setPriceList ] = useState('');

	const [ priceListTerm, setPriceListTerm ] = useState('');

	const [ priceListExpireAfterUse, setPriceListExpireAfterUse ] = useState('');

	const [ defaultExpiryTerm, setDefaultExpiryTerm ] = useState({
		time      : { value: null, type: null },
		afterUse  : { value: null, type: null },
		identical : false
	});

	const [ selectedTicket, setSelectedTicket ] = useState({});

	const [ ticketEmail, setTicketEmail ] = useState('');

	const [ ticketEmailConfirm, setTicketEmailConfirm ] = useState('');

	const [ phoneNumber, setPhoneNumber ] = useState('');

	const [ paymentMethod, setPaymentMethod ] = useState(0);

	const [ creditCardNumber, setCrecitCardNumber ] = useState('');

	const [ securityCode, setSecurityCode ] = useState('');

	const [ creditCardYear, setCreditCardYear ] = useState('');

	const [ creditCardMonth, setCreditCardMonth ] = useState('');

	const [ createdCode, setCreatedCode ] = useState('');

	const [ createdExpiryTimestamp, setCreatedExpiryTimestamp ] = useState(null);

	const [ createdExpiryTerm, setCreatedExpiryTerm ] = useState(
		{ afterPurchase: {}, afterUse: {} }
	);

	const [ createdMinutes, setCreatedMinutes ] = useState(null);

	const [ agreeTerms, setAgreeTerms ] = useState(false);

	const [ gmoAccessId, setGmoAccessId ] = useState('');

	const [ gmoAccessPass, setGmoAccessPass ] = useState('');

	const [ amazonPayConfig, setAmazonPayConfig ] = useState(null);

	const [ orderId, setOrderId ] = useState('');

	const [ gmoToken, setGmoToken ] = useState('');

	const [ errorMsg, setErrorMsg ] = useState('');

	const amazonPayBtnRef = useRef();

	const showExpiry = (cond, minutes) =>
	{
		let returnText = '';

		if (cond)
		{
			const condValue = Number(cond.value);

			if (condValue === 0)
			{
				return '-';
			}
			else if (cond.type === 'month')
			{
				if (condValue === 1)
				{
					returnText = intl.formatMessage({
						id             : 'paidservice1.oneMonth',
						defaultMessage : '1MONTH'
					});
				}
				else
				{
					returnText = intl.formatMessage({
						id             : 'paidservice1.multiMonths',
						defaultMessage : '{term}MONTHS'
					}, {
						term : cond.value
					});
				}
			}
			else if (cond.type === 'day')
			{

				if (condValue === 7)
				{
					returnText = intl.formatMessage({
						id             : 'paidservice1.oneWeek',
						defaultMessage : '1WEEK'
					});
				}
				else if (condValue%7===0)
				{
					returnText = intl.formatMessage({
						id             : 'paidservice1.multiWeeks',
						defaultMessage : '{term}WEEKS'
					}, {
						term : cond.value/7
					});
				}
				else // day
				if (condValue === 1)
				{
					returnText = intl.formatMessage({
						id             : 'paidservice1.oneDay',
						defaultMessage : '1DAY'
					});
				}
				else
				{
					returnText = intl.formatMessage({
						id             : 'paidservice1.multiDays',
						defaultMessage : '{term}DAYS'
					}, {
						term : cond.value
					});
				}
			}

			if (!minutes)
			{
				return (<span>{returnText}</span>);
			}
			else
			{
				let returnText2 = '';

				returnText2 += '[';
				if (Number(minutes === 1))
				{
					returnText2 += intl.formatMessage({
						id             : 'paidservice1.listSingleMinute',
						defaultMessage : '1 minute'
					});
				}
				else if (Number(minutes === -1))
				{
					returnText2 += intl.formatMessage({
						id             : 'paidservice1.listTimeFree',
						defaultMessage : 'UNLIMITED'
					});
				}
				else
				{
					returnText2 += intl.formatMessage({
						id             : 'paidservice1.listTime',
						defaultMessage : '{minutes} minutes'
					}, {
						minutes : minutes
					});
				}
				returnText2 += ']';

				return (<><span>{returnText}</span><br/><span>{returnText2}</span></>);
			}
		}
	};

	useEffect(() =>
	{
		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		let paramsObj = {};

		if (paramsValue)
		{
			paramsObj = parseUrlParams({
				paramsValues : paramsValue,
				keys         : [ 'id' ]
			});
		}

		if (!paramsObj['id'] || paramsObj['id'] === 'null')
		{
			paramsObj['id'] = DEFAULT_SERVICE_ACCOUNT_KEY;
		}

		const parts = window.location.hostname.split('.');

		// subdomainがある場合、subdomainなしに移動
		if (parts.length > 2 || (parts[parts.length - 1] === 'localhost' && parts.length > 1))
		{
			const domain = getDomainWithoutSubdomain();

			window.location.replace(`https://${domain}/purchase?id=${paramsObj['id']}`);
		}

		setServiceParamKey(paramsObj['id']);

		firestore.collection('serviceAccounts')
			.where('paramKey', '==', paramsObj['id'])
			.get()
			.then((res) =>
			{
				if (res.docs && res.docs.length > 0)
				{
					const accountData = res.docs[0].data();

					setPriceList(
						filterPriceListItem(
							accountData.priceList,
							[ PURCHASE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE ],
							'itemId'
						)
					);

					setPriceListTerm(
						filterPriceListItem(
							accountData.priceListTerm,
							[ PURCHASE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE ],
							'itemId'
						)
					);

					setPriceListExpireAfterUse(
						filterPriceListItem(
							accountData.priceListExpireAfterUse,
							[ PURCHASE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE ],
							'itemId'
						)
					);

					let valueIdentical = false;

					if (accountData.defaultExpiryTerm?.value
						=== accountData.defaultExpiryTermAfterUse?.value
								&& accountData.defaultExpiryTerm.type
									=== accountData.defaultExpiryTermAfterUse.type)
					{
						valueIdentical = true;
					}

					setDefaultExpiryTerm({
						time      : { ...accountData.defaultExpiryTerm || {} },
						afterUse  : { ...accountData.defaultExpiryTermAfterUse || {} },
						identical : valueIdentical
					});

					setLoading(false);
				}
			});

	}, [ location.search ]);

	// add gmo token script
	useEffect(() =>
	{

		// get Multipayment Object
		const script = document.createElement('script');

		// add gmo token script
		const domain = getDomainWithoutSubdomain();

		const url = getGmoTokenUrl(domain);

		script.src = url;
		script.async = true;

		document.body.appendChild(script);

		return () =>
		{
			document.body.removeChild(script);
		};
	}, []);

	// add amazon script
	useEffect(() =>
	{
		const script = document.createElement('script');

		script.src = 'https://static-fe.payments-amazon.com/checkout.js';
		script.async = true;

		document.body.appendChild(script);

		return () =>
		{
			document.body.removeChild(script);
		};

	}, []);

	// amazon button
	useEffect(() =>
	{
		if (page==='amazonPay')
		{
			if (amazonPayConfig)
			{
				try
				{
					const script = document.createElement('script');

					// script.innerHTML = amazonPayButtonStript;

					script.innerHTML = amazonPayButtonScriptFunc(amazonPayConfig);

					let intervalId = null;

					let intervalCounter = 0;

					let breakInterval = false;
					const addBtnFunction = () =>
					{
						if (intervalId && (breakInterval || intervalCounter > 500))
						{
							clearInterval(intervalId);
						}
						else if (amazonPayBtnRef.current)
						{
							document.body.appendChild(script);
							breakInterval = true;
						}
						else
						{
							intervalCounter += 1;
						}
					};

					// 0.01秒ごとにdiv#amazonPayBtnのレンダリングをチェックしてレンダリングされていればボタンをロード
					intervalId = setInterval(addBtnFunction, 10);

				}
				catch
				{
					setErrorMsg(intl.formatMessage({
						id             : 'error.error',
						defaultMessage : 'An error has occurred'
					}));
				}
			}
			else
			{
				setErrorMsg(intl.formatMessage({
					id             : 'error.error',
					defaultMessage : 'An error has occurred'
				}));
			}
		}
	}, [ page, amazonPayConfig, intl ]);

	const showDefaultExpiryTerm = (term) =>
	{
		if (!term)
		{
			return '';
		}
		else if (Number(term.value) === 0)
		{
			return intl.formatMessage({
				id             : 'service.noLimit',
				defaultMessage : 'No Limit'
			});
		}
		else
		{
			return `${term.value}${term.type === 'month' ?
				intl.formatMessage({
					id             : 'service.months',
					defaultMessage : 'months'
				}) : intl.formatMessage({
					id             : 'service.days',
					defaultMessage : 'days'
				})}`;
		}
	};

	const moveToTicketLoginPage = () =>
	{
		const serviceParamKeyValue = serviceParamKey
			? serviceParamKey
			: DEFAULT_SERVICE_ACCOUNT_KEY;

		history.push({
			pathname : '/service',
			search   : `?id=${serviceParamKeyValue}`,
			state    : {
				prevPage : 'purchase'
			}
		});
	};

	const moveToSelectPayment = () =>
	{
		setErrorMsg('');
		setPage('selectPayment');
	};

	const moveToCreditCardInput = () =>
	{
		setErrorMsg('');
		setAgreeTerms(false);
		setPage('creditCardInput');
	};

	const createPaymentEntry = async () =>
	{

		setErrorMsg('');

		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

		if (!ticketEmail)
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.notEnteredEmail',
				defaultMessage : 'No email address entered'
			}));

			return;
		}
		else if (!emailPattern.test(ticketEmail))
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.incorrectEmail',
				defaultMessage : 'Incorrect email address'
			}));

			return;
		}

		if (ticketEmail !== ticketEmailConfirm)
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.notMatchEmail',
				defaultMessage : 'Email addresses do not match'
			}));

			return;
		}

		const phoneNumberReg = /^[0-9]+$/;

		const phoneNumberValue = convertNumber(phoneNumber).replace(/−/g, '')
			.replace(/―/g, '')
			.replace(/-/g, '')
			.replace(/ー/g, '');

		if (!phoneNumber)
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.notEnteredPhone',
				defaultMessage : 'No phone number entered'
			}));

			return;
		}
		else if (!phoneNumberReg.test(phoneNumberValue))
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.incorrectPhone',
				defaultMessage : 'Incorrect phone number'
			}));

			return;
		}

		if (paymentMethod === PAYMENT_CREDIT_CARD)
		{
			setErrorMsg('');
			setPage('creditCardInput');
			setLoading(false);
		}
		else if (paymentMethod === PAYMENT_AMAZON_PAY)
		{
			setLoading(true);

			const serviceAmazonPayInitAPI = functions.httpsCallable('serviceAmazonPayInit');

			try
			{
				const domain = getDomainWithoutSubdomain();

				const response = await serviceAmazonPayInitAPI({
					amount          : selectedTicket.salesAmount,
					selectedTicket  : selectedTicket,
					serviceParamKey : serviceParamKey,
					email           : ticketEmail,
					phoneNumber     : phoneNumberValue,
					domain          : domain
				});

				const data = response.data;

				if (data.success)
				{
					setOrderId(data.orderId);
					setAmazonPayConfig({ ...data.amazonPayConfig, domain });
					localStorage.setItem('amazonPayOrderId', data.orderId);
					setErrorMsg('');
					setPage('amazonPay');
					setLoading(false);
				}
				else
				{
					setErrorMsg(intl.formatMessage({
						id             : 'error.error',
						defaultMessage : 'An error has occurred'
					}));
					setLoading(false);

					return;
				}
			}
			catch
			{
				setErrorMsg(intl.formatMessage({
					id             : 'error.error',
					defaultMessage : 'An error has occurred'
				}));
				setLoading(false);

				return;
			}
		}
	};

	const creditCardCallback = async (response) =>
	{
		if (response.resultCode !== '000' || !response.tokenObject || !response.tokenObject.token)
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.notVerifiedCard',
				defaultMessage : 'Credit card could not be verified'
			}));
			setLoading(false);
		}
		else
		{

			setGmoToken(response.tokenObject.token);

			// enter gmo transaction
			const serviceCreditCardEntryAPI = functions.httpsCallable('serviceCreditCardEntry');

			try
			{
				const res = await serviceCreditCardEntryAPI({
					amount : selectedTicket.salesAmount
				});

				const data = res.data;

				if (data.success && data.accessId && data.accessPass && data.orderId)
				{
					setOrderId(data.orderId);
					setGmoAccessId(data.accessId);
					setGmoAccessPass(data.accessPass);
					setErrorMsg('');
					setPage('creditCardReview');
					setLoading(false);
				}
				else
				{
					setErrorMsg(intl.formatMessage({
						id             : 'error.error',
						defaultMessage : 'An error has occurred'
					}));
					setLoading(false);

					return;
				}
			}
			catch
			{
				setErrorMsg(intl.formatMessage({
					id             : 'error.error',
					defaultMessage : 'An error has occurred'
				}));
				setLoading(false);

				return;
			}
		}
	};

	const checkCreditCardInput = () =>
	{
		setErrorMsg('');

		const numberReg = new RegExp(/^[0-9]+$/);

		const securityCodeReg = new RegExp(/^[0-9]+$/);

		// 1文字か2文字
		const creditCardMonthReg = new RegExp(/^[0-9]{1,2}$/);

		const creditCardNumberString = convertNumber(creditCardNumber);

		const securityCodeString = convertNumber(securityCode);

		const creditCardYearString = convertNumber(creditCardYear);

		const creditCardMonthString = convertNumber(creditCardMonth);

		if (!creditCardNumberString || !numberReg.test(creditCardNumberString))
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.incorrectCard',
				defaultMessage : 'Incorrect credit card number'
			}));
		}
		else if (!securityCodeString || !securityCodeReg.test(securityCodeString))
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.incorrectCCV',
				defaultMessage : 'Incorrect security code'
			}));
		}
		else if (!creditCardYearString
			|| !numberReg.test(creditCardYearString)
			|| (creditCardYearString.length !== 2 && creditCardYearString.length !== 4)
		)
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.incorrectYear',
				defaultMessage : 'Incorrect expiration date (year)'
			}));
		}
		else if (!creditCardMonthString || !creditCardMonthReg.test(creditCardMonthString))
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.incorrectMonth',
				defaultMessage : 'Invalid expiration date (month)'
			}));
		}
		else
		{
			try
			{
				setLoading(true);

				const domain = getDomainWithoutSubdomain();

				const gmoShopId = getGmoShopId(domain);

				// eslint-disable-next-line
				Multipayment.init(gmoShopId);

				let creditCardYearFormatted = creditCardYearString;

				if (creditCardYearFormatted.length === 2)
				{
					let hundredsOfYear = thisYear.slice(0, 2);

					const thisYearUnderHundred = thisYear.slice(2, 4);

					// 例えば2090年に01が入力される => hundredsOfYearに1を足して2101年と認識させる
					if (Number(thisYearUnderHundred) > Number(creditCardYearFormatted))
					{
						hundredsOfYear = (Number(hundredsOfYear)+1).toString();
					}

					creditCardYearFormatted = `${hundredsOfYear}${creditCardYearString}`;
				}

				const creditCardMonthFormatted = (`00${creditCardMonthString}`).slice(-2);

				// eslint-disable-next-line
				Multipayment.getToken(
					{
						cardno       : creditCardNumberString,
						expire       : `${creditCardYearFormatted}${creditCardMonthFormatted}`,
						securitycode : securityCodeString
					}, (response) => creditCardCallback(response));
			}
			catch
			{
				setErrorMsg(intl.formatMessage({
					id             : 'error.notVerifiedCard',
					defaultMessage : 'Credit card could not be verified'
				}));
				setLoading(false);
			}
		}
	};

	const completeTransaction = async () =>
	{
		if (agreeTerms)
		{
			setErrorMsg('');

			if (!gmoToken)
			{
				setErrorMsg(intl.formatMessage({
					id             : 'error.reEnterCard',
					defaultMessage : 'An error has occurred  Please try entering your credit card again'
				}));
				setLoading(false);
			}
			else if (!gmoAccessId || !gmoAccessPass || !ticketEmail)
			{
				setErrorMsg(intl.formatMessage({
					id             : 'error.returnFirstPage',
					defaultMessage : 'An error has occurred  Please return to the first page and try again'
				}));
				setLoading(false);
			}
			else
			{

				setLoading(true);

				const serviceCreditCardExecuteAPI = functions.httpsCallable('serviceCreditCardExecute');

				try
				{
					const domain = getDomainWithoutSubdomain();

					const phoneNumberValue = convertNumber(phoneNumber).replace(/−/g, '')
						.replace(/―/g, '')
						.replace(/-/g, '')
						.replace(/ー/g, '');

					const response = await serviceCreditCardExecuteAPI({
						gmoToken               : gmoToken,
						accessId               : gmoAccessId,
						accessPass             : gmoAccessPass,
						orderId                : orderId,
						serviceAccountParamKey : serviceParamKey,
						tickets                : [
							{ ...selectedTicket,
								orderId        : orderId,
								quantity       : 1,
								holesaleAmount : 0
							}
						],
						email            : ticketEmail,
						domain           : domain,
						phoneNumber      : phoneNumberValue,
						additionalEmail1 : '',
						additionalEmail2 : '',
						buyer            : 'guest',
						salesChannel     : ISSUED_AT_GUEST_PAGE,
						paymentMethod    : PAYMENT_CREDIT_CARD
					});
					const data = response.data;

					if (data.success && data.tickets?.length > 0)
					{
						const createdTicket = data.tickets[0];

						setCreatedCode(`${serviceParamKey.toUpperCase()}${createdTicket.ticketNumber}`);
						setCreatedMinutes(createdTicket.duration === -1 ? -1
							: createdTicket.duration ? createdTicket.duration/60 : 0);
						setCreatedExpiryTimestamp(createdTicket.expiryTimestamp);
						setCreatedExpiryTerm({
							afterPurchase : createdTicket.expiryTerm || {},
							afterUse      : createdTicket.expiryTermAfterUse || {}
						});
						setErrorMsg('');
						setPage('complete');
						setErrorMsg('');
						setLoading(false);
					}
					else
					{
						setErrorMsg(intl.formatMessage({
							id             : 'error.notIssue',
							defaultMessage : 'Cannot be Issued'
						}));
						setLoading(false);
					}

				}
				catch
				{

					setErrorMsg(intl.formatMessage({
						id             : 'error.error',
						defaultMessage : 'An error has occurred'
					}));
					setLoading(false);
				}
			}
		}
		else
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.consent',
				defaultMessage : 'You need to agree with term to purchase'
			}));
			setLoading(false);
		}
	};

	const backToHome = () =>
	{
		setErrorMsg('');
		setPage('home');
	};

	const handleChangeSelectedTicket = (e, list) =>
	{
		const indexValue = e.target.value;

		if (indexValue === 0 || indexValue)
		{
			const targetTicket = list[indexValue];

			if (targetTicket)
			{
				setSelectedTicket({
					...targetTicket,
					index : Number(indexValue)
				});
			}
		}
	};

	const updateTicketEmail = (e) =>
	{
		setTicketEmail(e.target.value);
	};

	const updateTicketEmailConfirm = (e) =>
	{
		setTicketEmailConfirm(e.target.value);
	};

	const updatePhoneNumber = (e) =>
	{
		setPhoneNumber(e.target.value);
	};

	const updatePaymentMethod = (e) =>
	{
		setPaymentMethod(Number(e.target.value));
	};

	const updateCreditCardNumber = (e) =>
	{
		setCrecitCardNumber(e.target.value);
	};

	const updateSecurityCode = (e) =>
	{
		setSecurityCode(e.target.value);
	};

	const updateCreditCardYear = (e) =>
	{
		setCreditCardYear(e.target.value);
	};

	const updateCreditCardMonth = (e) =>
	{
		setCreditCardMonth(e.target.value);
	};

	const toggleAgreeTerms = () =>
	{
		setAgreeTerms((state) =>
		{
			return !state;
		});
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>
				<ServiceAppBar
					free={false}
					appBarHeight={40}
					disabled={page === 'complete'}
				/>
				{
					loading ?
						<Box className={classes.loadingWrapper}>
							<CircularProgress className={classes.loadingIndicator}/>
						</Box>
						:
						<Box className={classes.content}>
							<Box className={classes.contentArea}>
								{errorMsg &&
								<Typography className={classes.errorMsg}>{errorMsg}</Typography>
								}
								{ page === 'selectPayment' ?
									<Box className={classes.pageContent}>
										<Box className={classes.titleSection}>
											<Typography className={classes.title}>
												<FormattedMessage
													id='paidservice2.purchaseConfirmation'
													defaultMessage='Confirmation'
												/>
											</Typography>
										</Box>
										<Box className={classes.contentWrapper}>
											<Typography className={classes.subTitle}>
												<FormattedMessage
													id='paidservice.purchaseDetails'
													defaultMessage='Purchase details'
												/>
											</Typography>

											<TicketInfoTable
												ticket={selectedTicket}
											/>
										</Box>

										<Box className={classes.contentWrapper}>
											<Typography className={classes.subTitle}>
												<FormattedMessage
													id='paidservice.emailDestination'
													defaultMessage='Ticket code destination'
												/>
											</Typography>

											<Box className={classes.messageAreaMarginBtm}>
												<Typography className={classes.messegeText}>
													<FormattedMessage
														id='service.emailParagraph'
														defaultMessage='A ticket code will be sent to the email address you entered.'
													/>
												</Typography>
											</Box>

											<Box className={classes.inputLine}>
												<Typography className={classes.inputLabel}>
													<FormattedMessage
														id='service.email'
														defaultMessage='Email Address'
													/>
												</Typography>
												<input
													value={ticketEmail}
													className={`${classes.inputText} ${classes.inputTextTicket}`}
													onChange={(e) => updateTicketEmail(e)}
													type='email'
												/>
											</Box>

											<Box className={classes.inputLine}>
												<Typography className={classes.inputLabel}>
													<FormattedMessage
														id='paidservice2.checkEmail'
														defaultMessage='Confirm'
													/>
												</Typography>
												<input
													value={ticketEmailConfirm}
													className={`${classes.inputText} ${classes.inputTextTicket}`}
													onChange={(e) => updateTicketEmailConfirm(e)}
													type='email'
												/>
											</Box>
										</Box>

										<Box className={classes.contentWrapper}>
											<Typography className={classes.subTitle}>
												<FormattedMessage
													id='paidservice.checkID'
													defaultMessage='customer information'
												/>
											</Typography>
											<Box className={classes.inputLine}>
												<Typography className={classes.inputLabel}>
													<FormattedMessage
														id='paidservice.phoneNumber'
														defaultMessage='Email Address Confirm'
													/>
												</Typography>
												<input
													value={phoneNumber}
													className={`${classes.inputText} ${classes.inputTextTicket}`}
													onChange={(e) => updatePhoneNumber(e)}
													type='tel'
												/>
											</Box>

											<Box className={classes.messageAreaMarginBoth}>
												<Typography className={classes.messegeText}>
													<FormattedMessage
														id='paidservice.checkIDparagraph1'
														defaultMessage='*If you do not receive the email containing the ticket code after payment,'
													/>
												</Typography>
												<Typography className={`${classes.messegeText} ${classes.messegeTextPaddingLeft}`}>
													<FormattedMessage
														id='paidservice.checkIDparagraph2'
														defaultMessage='please contact us and let us know your phone number'
													/>
												</Typography>
												<Typography className={`${classes.messegeText} ${classes.messegeTextPaddingLeft}`}>
													<FormattedMessage
														id='paidservice.checkIDparagraph3'
														defaultMessage='so that we can resend the email.'
													/>
												</Typography>
											</Box>
										</Box>

										<Box className={classes.contentWrapper}>
											<Typography className={classes.subTitle}>
												<FormattedMessage
													id='paidservice2.paymentSelect'
													defaultMessage='Select payment'
												/>
											</Typography>
											<Box className={classes.paymentItemBox}>
												<Box className={`${classes.paymentItemLine} ${classes.paymentItemLineAbove}`}>
													<Box className={classes.paymentLabelArea}>
														<Box className={classes.paymentItemSelect}>
															<Radio
																checked={paymentMethod === PAYMENT_CREDIT_CARD}
																onChange={(e) => updatePaymentMethod(e)}
																value={PAYMENT_CREDIT_CARD}
																name='radio-buttons'
																sx={{
																	'& .MuiSvgIcon-root' : {
																		fontSize : 24
																	}
																}}
															/>
														</Box>
														<Box
															className={`${classes.paymentItemLabel}`}
														>
															<FormattedMessage
																id='paidservice2.creditCard'
																defaultMessage='credit card'
															/>
														</Box>
													</Box>

													<Box className={classes.paymentLogoArea}>
														<img
															alt='visa'
															src={VisaMark}
															className={classes.paymentLogoVisa}
														/>

														<img
															alt='master'
															src={MasterMark}
															className={classes.paymentLogo}
														/>

														<img
															alt='diners'
															src={DinersMark}
															className={classes.paymentLogo}
														/>

														<img
															alt='jcb'
															src={JcbMark}
															className={classes.paymentLogo}
														/>

														<img
															alt='amex'
															src={AmexMark}
															className={classes.paymentLogo}
														/>
													</Box>
												</Box>
												<Box className={classes.paymentItemLine}>
													<Box className={classes.paymentLabelArea}>
														<Box className={classes.paymentItemSelect}>
															<Radio
																checked={paymentMethod === PAYMENT_AMAZON_PAY}
																onChange={(e) => updatePaymentMethod(e)}
																value={PAYMENT_AMAZON_PAY}
																name='radio-buttons'
																sx={{
																	'& .MuiSvgIcon-root' : {
																		fontSize : 24
																	}
																}}
															/>
														</Box>
														<Box
															className={`${classes.paymentItemLabel}`}
														>
															<FormattedMessage
																id='paidservice2.amazonPay'
																defaultMessage='Amazon Pay'
															/>
														</Box>
													</Box>

													<Box className={classes.paymentLogoArea}>
														<img
															alt='amazonPay'
															src={AmazonPayMark}
															className={classes.paymentLogoAmazon}
														/>
													</Box>

												</Box>
											</Box>
										</Box>

										<Box className={classes.buttonSection}>
											<Button
												className={`${classes.button} ${classes.backButton}`}
												onClick={backToHome}
											>
												<FormattedMessage
													id='paidservice.backButton'
													defaultMessage='Back'
												/>
											</Button>
											<Button
												className={`${classes.button} ${classes.proceedButton}`}
												onClick={createPaymentEntry}
												disabled={paymentMethod===0}
											>
												<FormattedMessage
													id='paidservice1.paymentMethodButton'
													defaultMessage='ENTER PAYMENT PROCEDURE'
												/>
											</Button>
										</Box>
									</Box>
									: page === 'creditCardInput' ?
										<Box className={classes.pageContent}>

											<Box className={classes.titleSection}>
												<Typography className={classes.title}>
													<FormattedMessage
														id='paidservice3.enterPaymentMethod'
														defaultMessage='Enter ticket payment information'
													/>
												</Typography>
											</Box>

											<Box className={classes.contentWrapper}>
												<Typography className={classes.subTitle}>
													<FormattedMessage
														id='paidservice.purchaseDetails'
														defaultMessage='Purchase details'
													/>
												</Typography>

												<TicketInfoTable
													ticket={selectedTicket}
												/>
											</Box>

											<Box className={classes.contentWrapper}>
												<Typography className={classes.subTitle}>
													<FormattedMessage
														id='paidservice.cardInfomation'
														defaultMessage='Credit Card Information'
													/>
												</Typography>

												<Box className={classes.creditCardInput}>
													<Typography className={classes.creditCardLabel}>
														<FormattedMessage
															id='paidservice.cardNumber'
															defaultMessage='Credit Card Number'
														/>
													</Typography>

													<input
														value={creditCardNumber}
														className={`${classes.inputText} ${classes.inputCreditCardInfo}`}
														onChange={(e) => updateCreditCardNumber(e)}
														type='text'
													/>
												</Box>

												{/* <Box className={classes.creditCardInput}>
												<Typography className={classes.creditCardLabel}>
													契約者名
												</Typography>

												<input
													value={creditCardHolder}
													className={`${classes.inputText} ${classes.inputCreditCardInfo}`}
													onChange={(e) => updateCreditCardHolder(e)}
													type='text'
												/>
											</Box> */}

												<Box className={classes.creditCardInput}>
													<Typography className={classes.creditCardLabel}>
														<FormattedMessage
															id='paidservice3.cardValid'
															defaultMessage='expiration date'
														/>
													</Typography>

													<Box className={classes.creditCardPeriod}>
														<Box className={classes.creditCardPeriodItemFirst}>
															<Typography className={classes.creditCardLabel}>
																<FormattedMessage
																	id='paidservice3.month'
																	defaultMessage='Month'
																/>
															</Typography>
															<input
																value={creditCardMonth}
																className={`${classes.inputText} ${classes.inputCreditCardMonth}`}
																onChange={(e) => updateCreditCardMonth(e)}
																maxLength='2'
																type='text'
															/>
														</Box>
														<Box>
															<Typography className={classes.creditCardLabel}>
																<FormattedMessage
																	id='paidservice3.year'
																	defaultMessage='Year'
																/>
															</Typography>
															<input
																value={creditCardYear}
																className={`${classes.inputText} ${classes.inputCreditCardYear}`}
																onChange={(e) => updateCreditCardYear(e)}
																maxLength='4'
																type='text'
															/>
														</Box>
													</Box>
												</Box>

												<Box className={classes.creditCardInput}>
													<Typography className={classes.creditCardLabel}>
														<FormattedMessage
															id='paidservice3.cvv'
															defaultMessage='CVV'
														/>
													</Typography>

													<input
														value={securityCode}
														className={`${classes.inputText} ${classes.inputCreditCardSecurityCode}`}
														onChange={(e) => updateSecurityCode(e)}
														type='text'
													/>
												</Box>

											</Box>

											<Box className={classes.buttonSection}>
												<Button
													className={`${classes.button} ${classes.backButton}`}
													onClick={moveToSelectPayment}
												>
													<FormattedMessage
														id='paidservice.backButton'
														defaultMessage='Back'
													/>
												</Button>
												<Button
													className={`${classes.button} ${classes.proceedButton}`}
													onClick={checkCreditCardInput}
													disabled={
														!creditCardNumber
														|| !creditCardYear
														|| !creditCardMonth
														|| !securityCode
													}
												>
													<FormattedMessage
														id='paidservice.nextButton'
														defaultMessage='FORWARD'
													/>
												</Button>
											</Box>
										</Box>
										: page === 'creditCardReview' ?
											<Box className={classes.pageContent}>
												<Box className={classes.titleSection}>
													<Typography className={classes.title}>
														<FormattedMessage
															id='paidservice1.buyTicket'
															defaultMessage='Purchase Ticket'
														/>
													</Typography>
												</Box>

												<Box className={classes.contentWrapper}>
													<Typography className={classes.subTitle}>
														<FormattedMessage
															id='paidservice.purchaseDetails'
															defaultMessage='Purchase details'
														/>
													</Typography>

													<TicketInfoTable
														ticket={selectedTicket}
													/>
												</Box>
												<Box className={classes.contentWrapper}>
													<Typography className={classes.subTitle}>
														<FormattedMessage
															id='paidservice.emailDestination'
															defaultMessage='Ticket code destination'
														/>
													</Typography>

													<Box className={classes.messageAreaMarginBtm}>
														<Typography className={classes.messegeText}>
															<FormattedMessage
																id='service.emailParagraph'
																defaultMessage='A ticket code will be sent to the email address you entered.'
															/>
														</Typography>
													</Box>
													<Box className={classes.inputLine}>
														<Typography className={classes.inputLabel}>
															<FormattedMessage
																id='service.email'
																defaultMessage='Email Address'
															/>
														</Typography>
														<input
															value={ticketEmail}
															className={`${classes.inputText} ${classes.inputTextTicket}`}
															disabled
															type='email'
														/>
													</Box>
												</Box>
												<Box className={classes.contentWrapper}>
													<Typography className={classes.subTitle}>
														<FormattedMessage
															id='paidservice.checkID'
															defaultMessage='customer information'
														/>
													</Typography>
													<Box className={classes.inputLine}>
														<Typography className={classes.inputLabel}>
															<FormattedMessage
																id='paidservice.phoneNumber'
																defaultMessage='Email Address Confirm'
															/>
														</Typography>
														<input
															value={phoneNumber}
															className={`${classes.inputText} ${classes.inputTextTicket}`}
															disabled
															type='tel'
														/>
													</Box>

													<Box className={classes.messageAreaMarginBoth}>
														<Typography className={classes.messegeText}>
															<FormattedMessage
																id='paidservice.checkIDparagraph1'
																defaultMessage='*If you do not receive the email containing the ticket code after payment,'
															/>
														</Typography>
														<Typography className={`${classes.messegeText} ${classes.messegeTextPaddingLeft}`}>
															<FormattedMessage
																id='paidservice.checkIDparagraph2'
																defaultMessage='please contact us and let us know your phone number'
															/>
														</Typography>
														<Typography className={`${classes.messegeText} ${classes.messegeTextPaddingLeft}`}>
															<FormattedMessage
																id='paidservice.checkIDparagraph3'
																defaultMessage='so that we can resend the email.'
															/>
														</Typography>
													</Box>
												</Box>

												<Box className={classes.contentWrapper}>
													<Typography className={classes.subTitle}>
														<FormattedMessage
															id='paidservice.cardInfomation'
															defaultMessage='Credit Card Information'
														/>
													</Typography>
													<Box className={classes.creditCardInput}>
														<Typography className={classes.creditCardLabel}>
															<FormattedMessage
																id='paidservice.cardNumber'
																defaultMessage='Credit Card Number'
															/>
														</Typography>

														<input
															value={'*'.repeat(creditCardNumber.length)}
															className={`${classes.inputText} ${classes.inputCreditCardInfo}`}
															disabled
															type='text'
														/>
													</Box>
												</Box>

												<Box className={classes.termsArea}>
													<Typography className={classes.termsText}>
														<FormattedMessage
															id='paidservice4.termsOfUse1'
															defaultMessage='1.Purchased tickets cannot be refunded, regardless of whether they are used or not.'
														/>
													</Typography>
													<Typography className={classes.termsText}>
														<FormattedMessage
															id='paidservice4.termsOfUse2'
															defaultMessage='2. Even if you do not receive the email, you cannot cancel the purchase.'
														/>
													</Typography>
													<Typography className={classes.termsText}>
														<FormattedMessage
															id='paidservice4.termsOfUse3'
															defaultMessage='3. After entering the code, the remaining usage minutes will disappear once the expiration date has passed.'
														/>
													</Typography>
													<Typography className={classes.termsText}>
														<FormattedMessage
															id='paidservice4.termsOfUse4-1'
															defaultMessage='4. The first 10 seconds of a call do not count as minutes."'
														/>
													</Typography>
													<Typography className={`${classes.termsText} ${classes.termsTextPaddingLeft}`}>
														<FormattedMessage
															id='paidservice4.termsOfUse4-2'
															defaultMessage='Minutes are counted in increments of 1 minute.'
														/>
													</Typography>
													<Typography className={`${classes.termsText} ${classes.termsTextPaddingLeft}`}>
														<FormattedMessage
															id='paidservice4.termsOfUse4-3'
															defaultMessage='(Please note that seconds are rounded up)'
														/>
													</Typography>
												</Box>

												<Box className={classes.agreeTermsLine}>
													<Box className={classes.agreeTermsCheckBox}>
														<Checkbox
															checked={agreeTerms}
															onChange={toggleAgreeTerms}
															inputProps={{ 'aria-label': 'terms check box' }}
														/>
													</Box>

													<Typography className={classes.agreeTermsText}>
														<span>
															<FormattedMessage
																id='paidservice4.agreeTermsOfUse1'
																defaultMessage='I agree to the above content and '
															/>
														</span>
														<span className={classes.termsLinkText}>
															<Link
																to={{ pathname : 'https://www.tuuyaku.com/terms'
																}}
																target='_blank'
																className={classes.termsLink}
															>
																<FormattedMessage
																	id='paidservice4.agreeTermsOfUse2'
																	defaultMessage='terms of use'
																/>
															</Link>
														</span>
														<span>
															<FormattedMessage
																id='paidservice4.agreeTermsOfUse3'
																defaultMessage='of the of LIVE Interpreter.'
															/>
														</span>
													</Typography>
												</Box>

												<Box className={classes.messageAreaMarginBoth}>
													<Typography className={`${classes.messegeText} ${classes.cautionText}`}>
														<FormattedMessage
															id='paidservice4.termsOfUseCaution1'
															defaultMessage='By pressing the ”Purchase”button, the ticket purchase will be completed.'
														/>
													</Typography>
													<Typography className={`${classes.messegeText} ${classes.cautionText}`}>
														<FormattedMessage
															id='paidservice4.termsOfUseCaution2'
															defaultMessage='Please do not return to the screen after completing the purchase.'
														/>
													</Typography>
												</Box>

												<Box className={classes.buttonSection}>
													<Button
														className={`${classes.button} ${classes.backButton}`}
														onClick={moveToCreditCardInput}
													>
														<FormattedMessage
															id='paidservice.backButton'
															defaultMessage='Back'
														/>
													</Button>
													<Button
														className={`${classes.button} ${classes.proceedButton}`}
														onClick={completeTransaction}
													>
														<FormattedMessage
															id='paidservice4.payButton'
															defaultMessage='PURCHASE'
														/>
													</Button>
												</Box>
											</Box>
											: page === 'amazonPay' ?
												<Box className={classes.pageContent}>
													<Box className={`${classes.titleSection} ${classes.amazonPayTitle}`}>
														<Typography className={classes.title}>
															<FormattedMessage
																id='paidservice3.loginAmazon'
																defaultMessage='Log in with your Amazon account'
															/>
														</Typography>
													</Box>
													{/* <Box>
													<img
														alt='logo'
														src='images/amazon_pay_logo.svg'
														className={classes.amazonPayButton}
														onClick={startAmazonPay}
													/>
												</Box> */}
													<div
														className={classes.amazonPayButtonArea}
														id='AmazonPayButton'
														ref={amazonPayBtnRef}
													/>

													<Box className={classes.singleButtonSection}>
														<Button
															className={`${classes.button} ${classes.backButton} ${classes.amazonPayBackBtn}`}
															onClick={() => setPage('selectPayment')}
														>
															<FormattedMessage
																id='paidservice.backButton'
																defaultMessage='Back'
															/>
														</Button>
													</Box>
												</Box>
												: page === 'complete' ?
													<PurchaseComplete
														serviceParamKey={serviceParamKey}
														ticketCode={createdCode}
														ticketExpiryTimestamp={createdExpiryTimestamp}
														ticketExpiryTerm={createdExpiryTerm}
														ticketMinutes={createdMinutes}
														ticketEmail={ticketEmail}
														free={false}
													/>
													:
													<Box className={classes.pageContent}>
														<Box className={classes.titleSection}>
															<Typography className={classes.title}>
																<FormattedMessage
																	id='paidservice1.buyTicket'
																	defaultMessage='Purchase Ticket'
																/>
															</Typography>
														</Box>

														<Box className={classes.priceMessageSection}>
															<Typography className={classes.priceMessage}>
																<FormattedMessage
																	id='paidservice1.buyTicketParagraph'
																	defaultMessage='Select from the following minutes for interpreting services and you will be redirected to the payment screen.'
																/>
															</Typography>
														</Box>

														{ (priceList && priceList.length > 0) &&
														<Box className={classes.contentWrapper}>
															<Box className={classes.priceListColumn}>
																<Box className={classes.priceBox}>
																	<Box className={classes.priceListLine}>
																		<Box className={`${classes.priceListItem} ${classes.priceItemLeft}`}>
																			<FormattedMessage
																				id='paidservice1.headerSelect'
																				defaultMessage='select'
																			/>
																		</Box>
																		<Box className={`${classes.priceListItem} ${classes.priceItemRight}`}>
																			<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightFirst}`}>
																				<Typography
																					className={classes.priceItemRightTitle}
																				>
																					<FormattedMessage
																						id='paidservice1.headerTime'
																						defaultMessage='Time plan'
																					/>
																				</Typography>
																			</Box>
																			<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightSecond}`}>
																				/
																			</Box>
																			<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightThird}`}>
																				<Typography className={classes.priceText}>
																					<FormattedMessage
																						id='paidservice1.headerFee'
																						defaultMessage='Fee (including tax)'
																					/>
																				</Typography>
																			</Box>
																		</Box>
																	</Box>
																	{priceList.map((item, index) => (
																		<Box className={classes.priceListLine} key={index}>
																			<Box className={`${classes.priceListItem} ${classes.priceItemLeft}`}>
																				<Radio
																					checked={selectedTicket.type === 'time' && selectedTicket.index === index}
																					onChange={(e) =>
																						handleChangeSelectedTicket(e, priceList)}
																					value={index}
																					name='radio-buttons'
																					sx={{
																						'& .MuiSvgIcon-root' : {
																							fontSize : 24
																						}
																					}}
																				/>
																			</Box>
																			<Box className={`${classes.priceListItem} ${classes.priceItemRight}`}>
																				<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightFirst}`}>
																					<Typography>
																						{ item.minutes === -1 ?
																							<FormattedMessage
																								id='paidservice.unlimitedTicket'
																								defaultMessage='UNLIMITED'
																							/>
																							:
																							<FormattedMessage
																								id='paidservice1.listTime'
																								defaultMessage='{minutes} minutes'
																								values={{
																									minutes :
																								showNumberWithComma(item.minutes)
																								}}
																							/>
																						}
																					</Typography>
																				</Box>
																				<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightSecond}`}>
																					/
																				</Box>
																				<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightThird}`}>
																					<Typography className={classes.priceText}>
																						<FormattedMessage
																							id='paidservice1.listFee'
																							defaultMessage='{includetax} yen'
																							values={{
																								includetax :
																							showTaxIncludedAmount(item.salesAmount)
																							}}
																						/>
																					</Typography>
																				</Box>
																			</Box>
																		</Box>
																	))}
																</Box>
																{ !defaultExpiryTerm.identical &&
																<Typography className={classes.priceListBoxTitle}>
																	<FormattedMessage
																		id='paidservice1.periodMonth'
																		defaultMessage={'The expiration after purchase is {period}'}
																		values={{ period :
																		showDefaultExpiryTerm(defaultExpiryTerm.time)
																		}}
																	/>
																</Typography>
																}
															</Box>
														</Box>
														}

														{ (priceListExpireAfterUse
															&& priceListExpireAfterUse.length > 0) &&
															<Box className={classes.contentWrapper}>
																<Box className={classes.priceListColumn}>
																	<Box className={classes.priceBox}>
																		<Box className={classes.priceListLine}>
																			<Box className={`${classes.priceListItem} ${classes.priceItemLeft}`}>
																				<FormattedMessage
																					id='paidservice1.headerSelect'
																					defaultMessage='select'
																				/>
																			</Box>
																			<Box className={`${classes.priceListItem} ${classes.priceItemRight}`}>
																				<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightFirst}`}>
																					<Typography
																						className={classes.priceItemRightTitle}
																					>
																						<FormattedMessage
																							id='paidservice1.headerDay'
																							defaultMessage='Day plan'
																						/>
																					</Typography>
																				</Box>
																				<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightSecond}`}>
																					/
																				</Box>
																				<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightThird}`}>
																					<Typography className={classes.priceText}>
																						<FormattedMessage
																							id='paidservice1.headerFee'
																							defaultMessage='Fee (including tax)'
																						/>
																					</Typography>
																				</Box>
																			</Box>
																		</Box>
																		{priceListExpireAfterUse.map((item, index) => (
																			<Box className={classes.priceListLine} key={index}>
																				<Box className={`${classes.priceListItem} ${classes.priceItemLeft}`}>
																					<Radio
																						checked={selectedTicket.type === 'afterUse' && selectedTicket.index === index}
																						onChange={(e) =>
																							handleChangeSelectedTicket(
																								e, priceListExpireAfterUse
																							)}
																						value={index}
																						name='radio-buttons'
																						sx={{
																							'& .MuiSvgIcon-root' : {
																								fontSize : 24
																							}
																						}}
																					/>
																				</Box>
																				<Box className={`${classes.priceListItem} ${classes.priceItemRight}`}>
																					<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightFirst}`}>
																						<Typography className={
																							item.minutes === -1
																								? classes.nolimitTimeText
																								: classes.timeText
																						}
																						>
																							{showExpiry(
																								item.expiryTermAfterUse,
																								item.minutes
																							)}
																						</Typography>
																					</Box>
																					<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightSecond}`}>
																						/
																					</Box>
																					<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightThird}`}>
																						<Typography className={classes.priceText}>
																							<FormattedMessage
																								id='paidservice1.listFee'
																								defaultMessage='{includetax} yen'
																								values={{
																									includetax :
																							showTaxIncludedAmount(item.salesAmount)
																								}}
																							/>
																						</Typography>
																					</Box>
																				</Box>
																			</Box>
																		))}
																	</Box>
																	{ !defaultExpiryTerm.identical &&
																	<Typography className={classes.priceListBoxTitle}>
																		<FormattedMessage
																			id='paidservice1.periodMonth'
																			defaultMessage={'The expiration after purchase is {period}'}
																			values={{ period :
																		showDefaultExpiryTerm(defaultExpiryTerm.afterUse)
																			}}
																		/>
																	</Typography>
																	}
																</Box>
															</Box>
														}

														{ defaultExpiryTerm.identical &&
														<Typography className={`${classes.priceListBoxTitle} ${classes.priceListBoxTitleCenter}`}>
															<FormattedMessage
																id='paidservice1.periodMonth'
																defaultMessage={'The expiration after purchase is {period}'}
																values={{ period :
																		showDefaultExpiryTerm(defaultExpiryTerm.time)
																}}
															/>
														</Typography>
														}
														{ (priceListTerm && priceListTerm.length > 0) &&
														<Box className={classes.contentWrapper}>
															<Box className={classes.priceListColumn}>
																<Box className={classes.priceBox}>
																	<Box className={classes.priceListLine}>
																		<Box className={`${classes.priceListItem} ${classes.priceItemLeft}`}>
																			<FormattedMessage
																				id='paidservice1.headerSelect'
																				defaultMessage='select'
																			/>
																		</Box>
																		<Box className={`${classes.priceListItem} ${classes.priceItemRight}`}>
																			<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightFirst}`}>
																				<Typography
																					className={classes.priceItemRightTitle}
																				>
																					<FormattedMessage
																						id='paidservice1.headerDay'
																						defaultMessage='Day plan'
																					/>
																				</Typography>
																			</Box>
																			<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightSecond}`}>
																				/
																			</Box>
																			<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightThird}`}>
																				<Typography className={classes.priceText}>
																					<FormattedMessage
																						id='paidservice1.headerFee'
																						defaultMessage='Fee (including tax)'
																					/>
																				</Typography>
																			</Box>
																		</Box>
																	</Box>
																	{priceListTerm.map((item, index) => (
																		<Box className={classes.priceListLine} key={index}>
																			<Box className={`${classes.priceListItem} ${classes.priceItemLeft}`}>
																				<Radio
																					checked={selectedTicket.type === 'term' && selectedTicket.index === index}
																					onChange={(e) =>
																						handleChangeSelectedTicket(
																							e, priceListTerm
																						)}
																					value={index}
																					name='radio-buttons'
																					sx={{
																						'& .MuiSvgIcon-root' : {
																							fontSize : 24
																						}
																					}}
																				/>
																			</Box>
																			<Box className={`${classes.priceListItem} ${classes.priceItemRight}`}>
																				<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightFirst}`}>
																					<Typography className={
																						item.minutes === -1
																							? classes.nolimitTimeText
																							: classes.timeText
																					}
																					>
																						{showExpiry(
																							item.expiryTerm,
																							item.minutes
																						)}
																					</Typography>
																				</Box>
																				<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightSecond}`}>
																					/
																				</Box>
																				<Box className={`${classes.priceItemRightChild} ${classes.priceItemRightThird}`}>
																					<Typography className={classes.priceText}>
																						<FormattedMessage
																							id='paidservice1.listFee'
																							defaultMessage='{includetax} yen'
																							values={{
																								includetax :
																							showTaxIncludedAmount(item.salesAmount)
																							}}
																						/>
																					</Typography>
																				</Box>
																			</Box>
																		</Box>
																	))}
																</Box>
															</Box>
														</Box>
														}

														<Box className={classes.buttonSection}>
															<Button
																className={`${classes.button} ${classes.backButton}`}
																onClick={moveToTicketLoginPage}
															>
																<FormattedMessage
																	id='paidservice.backButton'
																	defaultMessage='Back'
																/>
															</Button>
															<Button
																className={`${classes.button} ${classes.proceedButton}`}
																onClick={moveToSelectPayment}
																disabled={Object.keys(selectedTicket).length === 0}
															>
																<FormattedMessage
																	id='paidservice1.paymentMethodButton'
																	defaultMessage='ENTER PAYMENT PROCEDURE'
																/>
															</Button>
														</Box>
													</Box>
								}
							</Box>
						</Box>
				}
			</Box>
		</MuiThemeProvider>
	);
};

ServiceGuestPurchase.propTypes =
{
	classes : PropTypes.object.isRequired
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ServiceGuestPurchase)));
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// util
import { showTaxIncludedAmount, getDomainWithoutSubdomain } from '../../../../utils';

// const
import { PAYMENT_MONTHLY, ISSUED_AT_SYSTEM_ADMIN_PAGE } from '../../../../const';

// firestore
import { functions } from '../../../../lib/firebase';

// dayjs
import dayjs from 'dayjs';

const styles = () =>
	({
		root :
		{
			zIndex : 99
		},
		dialogPaper :
		{
			width           : '100%',
			padding         : '1% 1%',
			backgroundColor : '#FFF'
		},
		dialogPaperConfirm :
		{
			width           : '95vw',
			maxWidth        : '1250px !important',
			padding         : '1% 1%',
			backgroundColor : '#FFF'
		},
		titleSection :
		{
			width    : '100%',
			position : 'relative'
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleText :
		{
			color    : 'var(--text-color)',
			fontSize : '1.1rem'
		},
		label : {
			color        : 'var(--text-color)',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		messageSection : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			marginBottom   : '10px'
		},
		message : {
			fontSize : '0.8rem',
			color    : 'var(--text-color)'
		},
		table : {
			margin : '15px 0'
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '20%',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#FFF'
			}
		},
		priceListInputKey : {
			minWidth : '180px'
		},
		priceListInput :
		{
			minWidth : '95px'
		},
		noItemRow : {
			color : ' var(--text-color)'
		},
		errorText : {
			color : 'red'
		},
		dialogButtons :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-around'
		},
		purchaseBtn : {
			width           : '28%',
			fontSize        : '0.9rem',
			border          : '1px solid red',
			backgroundColor : 'red',
			color           : 'white',
			'&:hover'       :
			{
				filter : 'saturate(90%) brightness(90%)'
			}
		},
		purchaseBtnDisabled : {
			backgroundColor : '#AAA !important',
			color           : '#FFF !important',
			border          : 'none !important',
			'&:hover'       :
			{
				backgroundColor : '#AAA !important',
				filter          : 'saturate(90%) brightness(90%)'
			}
		},
		cancelBtn :
		{
			width           : '28%',
			fontSize        : '0.9rem',
			border          : '1px solid var(--text-color)',
			backgroundColor : 'transparent',
			color           : 'var(--text-color)'
		},
		loading :
		{
			width           : '100%',
			height          : '100%',
			minWidth        : '25vw',
			minHeight       : '40vh',
			zIndex          : 9999999,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0)'
		},
		statusChanging : {
			width           : '100%',
			height          : '63px',
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiInput : {
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				},
				'&.Mui-disabled' : {
					backgroundColor : '#F6F6F6'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		}
	}
});

const showExpiryDate = (timestamp) =>
{
	if (timestamp === 0 || timestamp === '0')
	{
		return '';
	}
	else if (timestamp)
	{
		const dayjsTime = dayjs(timestamp);

		return dayjsTime.format('YYYY.MM.DD H:mm');
	}
	else
	{
		return '';
	}
};

const showExpiryTerm = (term) =>
{
	if (term.value === 0)
	{
		return '無制限';
	}
	else
	{
		return `${term.value}${term.type === 'month' ? 'ヶ月' : '日'}`;
	}
};

const ConnectPurchaseDialog = ({
	classes,
	show,
	closeMethod,
	accountId,
	email,
	fileResult,
	priceListPurchase
}) =>
{

	const [ page, setPage ] = useState('file');

	const [ countPurchaseItems, setCountPurchaseItems ] = useState(0);

	const [ loading, setLoading ] = useState(false);

	const [ createdConnections, setCreatedConnections ] = useState([]);

	const [ message, setMessage ] = useState('');

	const closeDialog = () =>
	{
		setPage('file');
		setMessage('');
		setLoading(false);
		closeMethod();
	};

	const showDurationMinutes = (duration) =>
	{
		if (duration === -1)
		{
			return '無制限';
		}
		else if (duration > 0)
		{
			return `${Math.ceil(duration/60)}分`;
		}
		else
		{
			return '0分';
		}
	};

	useEffect(() =>
	{

		if (priceListPurchase)
		{
			let count = 0;

			priceListPurchase.forEach((item) =>
			{
				count += item.quantity;
			});

			setCountPurchaseItems(count);
		}

	}, [ priceListPurchase ]);

	const purchaseConnections = () =>
	{
		if (countPurchaseItems > 0)
		{
			const connectConnectionCreateAPI = functions.httpsCallable('connectionCreate');

			const domain = getDomainWithoutSubdomain();

			setLoading(true);

			connectConnectionCreateAPI({
				connectAccountId : accountId,
				connections      : [
					...priceListPurchase
				],
				email         : email,
				domain        : domain,
				buyer         : 'system-admin',
				salesChannel  : ISSUED_AT_SYSTEM_ADMIN_PAGE,
				paymentMedhod : PAYMENT_MONTHLY
			}).then((response) =>
			{
				if (response.data.tickets && response.data.tickets.length > 0)
				{
					setCreatedConnections([ ...response.data.tickets ]);
					setPage('complete');
					setLoading(false);
				}
				else
				{
					setMessage('エラーが発生しました');
					setLoading(false);
				}
			})
				.catch(() =>
				{
					setMessage('エラーが発生しました');
					setLoading(false);
				});
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={show}
				scroll={'paper'}
				onClose={loading ? () => {} : closeDialog}
				classes={{
					paper : page === 'confirm' || page === 'complete'? classes.dialogPaperConfirm : classes.dialogPaper
				}}
			>
				{ page === 'file' ?
					<>
						<DialogTitle id='form-dialog-title'>
							<div className={classes.titleSection}>
								<div className={classes.title}>
									<div className={classes.titleText}>
										ファイル読み込み情報
									</div>
								</div>
							</div>
						</DialogTitle>
						<DialogContent>
							{ loading ?
								<Box className={classes.loading}>
									<CircularProgress />
								</Box>
								:
								<>
									{ message &&
										<Box className={classes.messageSection}>
											<Typography className={classes.message}>
												{message}
											</Typography>
										</Box>
									}

									<Box>
										<Typography>
											entry_key欄にエントリーキー、quantity欄に数量を入力
										</Typography>
									</Box>
									<TableContainer component={Paper}>
										<Table className={classes.table} aria-label='call target table'>
											<TableHead>
												<TableRow>
													<TableCell align='left'>行</TableCell>
													<TableCell align='left'>キー</TableCell>
													<TableCell align='left'>数量</TableCell>
													<TableCell align='left'>ステータス</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{	fileResult.map((item, index) => (
													<TableRow key={index}>
														<TableCell align='left'>
															<Typography>
																{index+1}
															</Typography>
														</TableCell>
														<TableCell align='left'>
															<Typography>
																{item.entryKey}
															</Typography>
														</TableCell>
														<TableCell align='left'>
															<Typography>
																{item.quantity}
															</Typography>
														</TableCell>
														<TableCell align='left'>
															{item.status === 'success' ? <Typography>成功</Typography>
																: item.status === 'keyNotFound' ? <Typography className={classes.errorText}>キーエラー</Typography>
																	: item.status === 'noQuantity' ? <Typography className={classes.errorText}>数量エラー</Typography>
																		: item.status === 'wrongAccount' ? <Typography className={classes.errorText}>アカウントエラー</Typography> : null}
														</TableCell>
													</TableRow>
												))
												}
											</TableBody>
										</Table>
									</TableContainer>
								</>
							}
						</DialogContent>
						<DialogActions>
							<Box className={classes.dialogButtons}>

								<Button
									className={classes.cancelBtn}
									onClick={closeDialog}
									color='primary'
								>
									<FormattedMessage
										id='label.close'
										defaultMessage='Close'
									/>
								</Button>

								{ countPurchaseItems > 0 ?
									<Button
										className={classes.purchaseBtn}
										onClick={() => setPage('confirm')}
										color='primary'
									>
										購入確認
									</Button>
									:
									<Button
										className={`${classes.purchaseBtn} ${classes.purchaseBtnDisabled}`}
										onClick={() => {}}
										disabled
										color='primary'
									>
										購入確認
									</Button>
								}
							</Box>
						</DialogActions>
					</>
					: page === 'confirm' ?
						<>
							<DialogTitle id='form-dialog-title'>
								<div className={classes.titleSection}>
									<div className={classes.title}>
										<div className={classes.titleText}>
											購入確認
										</div>
									</div>
								</div>
							</DialogTitle>
							<DialogContent>
								{ loading ?
									<Box className={classes.loading}>
										<CircularProgress />
									</Box>
									:
									<>
										{ message &&
										<Box className={classes.messageSection}>
											<Typography className={classes.message}>
												{message}
											</Typography>
										</Box>
										}
										<TableContainer component={Paper}>
											<Table className={classes.table} aria-label='call target table'>
												<TableHead>
													<TableRow>
														<TableCell align='left'>キー</TableCell>
														<TableCell align='left'>通話時間(分)</TableCell>
														<TableCell align='left'>期限</TableCell>
														<TableCell align='left'>定価</TableCell>
														<TableCell align='left'>卸売価格</TableCell>
														<TableCell align='left'>数量</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{ priceListPurchase && priceListPurchase.length > 0 ?
														<>
															{	priceListPurchase.map((item, index) => (
																<TableRow key={index}>
																	<TableCell align='left'>
																		<input
																			value={item.entryKey}
																			className={`${classes.inputField} ${classes.priceListInputKey}`}
																			disabled
																			type='text'
																		/>
																	</TableCell>
																	<TableCell align='left'>
																		{ item.minutes === -1 ?
																			<input
																				value={'無制限'}
																				className={`${classes.inputField} ${classes.priceListInput}`}
																				disabled
																				type='text'
																			/>
																			:
																			<input
																				value={item.minutes}
																				min={0}
																				className={`${classes.inputField} ${classes.priceListInput}`}
																				disabled
																				type='number'
																			/>
																		}
																	</TableCell>
																	<TableCell align='left'>
																		<input
																			value={showExpiryTerm(item.expiryTerm)}
																			className={`${classes.inputField} ${classes.priceListInput}`}
																			disabled
																			type='text'
																		/>
																	</TableCell>
																	<TableCell align='left'>
																		<input
																			value={item.salesAmount}
																			min={0}
																			className={`${classes.inputField} ${classes.priceListInput}`}
																			disabled
																			type='number'
																		/>
																	</TableCell>
																	<TableCell align='left'>
																		<input
																			value={item.holesaleAmount}
																			min={0}
																			className={`${classes.inputField} ${classes.priceListInput}`}
																			disabled
																			type='number'
																		/>

																	</TableCell>
																	<TableCell align='left'>
																		<input
																			value={item.quantity}
																			className={`${classes.inputField} ${classes.priceListInput}`}
																			disabled
																			type='number'
																		/>
																	</TableCell>
																</TableRow>
															))}
														</>
														:
														<TableRow>
															<TableCell
																align='center'
																className={classes.noItemRow}
																colSpan={4}
															>
																アイテムがありません
															</TableCell>
														</TableRow>

													}
												</TableBody>
											</Table>
										</TableContainer>
									</>
								}
							</DialogContent>
							{ !loading &&
								<DialogActions>
									<Box className={classes.dialogButtons}>

										<Button
											className={classes.cancelBtn}
											onClick={closeDialog}
											color='primary'
										>
											<FormattedMessage
												id='label.close'
												defaultMessage='Close'
											/>
										</Button>
										<Button
											className={classes.purchaseBtn}
											onClick={purchaseConnections}
											color='primary'
										>
											購入
										</Button>
									</Box>
								</DialogActions>
							}
						</>
						: page === 'complete' ?
							<>
								<DialogTitle id='form-dialog-title'>
									<div className={classes.titleSection}>
										<div className={classes.title}>
											<div className={classes.titleText}>
												購入完了
											</div>
										</div>
									</div>
								</DialogTitle>
								<DialogContent>
									{ loading ?
										<Box className={classes.loading}>
											<CircularProgress />
										</Box>
										:
										<>
											<Box className={classes.table}>
												<TableContainer component={Paper}>
													<Table aria-label='simple table'>
														<TableHead>
															<TableRow>
																<TableCell align='center'>接続コード</TableCell>
																<TableCell align='center'>有効期限(購入後)</TableCell>
																<TableCell align='center'>通話時間</TableCell>
																<TableCell align='center'>卸売価格</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{createdConnections.map((row, index) => (
																<TableRow key={index}>
																	<TableCell align='center'>{row.connectionKey}</TableCell>
																	<TableCell align='center'>{showExpiryDate(row.expiryTimestamp)}</TableCell>
																	<TableCell align='center'>{showDurationMinutes(row.duration)}</TableCell>
																	<TableCell align='center'>{showTaxIncludedAmount(row.holesaleAmount)}円(税込)</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</TableContainer>
											</Box>
										</>
									}
								</DialogContent>
								<DialogActions>
									<Box className={classes.dialogButtons}>

										<Button
											className={classes.cancelBtn}
											onClick={closeDialog}
											color='primary'
										>
											<FormattedMessage
												id='label.close'
												defaultMessage='Close'
											/>
										</Button>
									</Box>
								</DialogActions>
							</>
							: null
				}
			</Dialog>
		</MuiThemeProvider>
	);
};

ConnectPurchaseDialog.propTypes =
{
	classes           : PropTypes.object.isRequired,
	show              : PropTypes.bool.isRequired,
	closeMethod       : PropTypes.func.isRequired,
	accountId         : PropTypes.string.isRequired,
	email             : PropTypes.string.isRequired,
	fileResult        : PropTypes.array.isRequired,
	priceListPurchase : PropTypes.array.isRequired

};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(ConnectPurchaseDialog)));
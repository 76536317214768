// React
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Redux
import { withRoomContext } from '../../../RoomContext';
import { connect } from 'react-redux';
import * as roomActions from '../../../actions/roomActions';
import * as settingsActions from '../../../actions/settingsActions';

// Components
import Button from '@material-ui/core/Button';
import JoinRoomNoticeDialog from '../Common/JoinRoomNoticeDialog';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

// Mui
import { withStyles } from '@material-ui/core/styles';

// Firebase
import { firestore } from '../../../lib/firebase';

// Daysjs
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

// const 
import { ROOM_ONLINE, ROOM_APPOINTMENT, ROOM_OFFLINE } from '../../../const';
import { Typography } from '@material-ui/core';

// util
import { parseUrlParams } from '../../../utils';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

dayjs.extend(weekday);

const breakPointAspectRatio = 1;

const styles = (theme) =>
	({
		root :
		{
			display              : 'flex',
			flexDirection        : 'column',
			justifyContent       : 'center',
			alignItems           : 'flex-start',
			width                : '100%',
			height               : '100%',
			backgroundAttachment : 'fixed',
			backgroundPosition   : 'center',
			backgroundSize       : 'cover',
			backgroundRepeat     : 'no-repeat',
			position             : 'relative'
		},
		logo :
		{
			position                     : 'fixed',
			left                         : '5%',
			top                          : '5%',
			marginLeft                   : 0,
			[theme.breakpoints.up('sm')] :
			{
				display : 'none' // block
			},
			width       : '30vw',
			aspectRatio : 2.5,
			objectFit   : 'conatin',
			display     : 'none'
		},
		startButtonArea :
		{
			position       : 'absolute',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center',
			margin         : 'auto'
		},
		startButtonAreaSP :
		{
			width  : '80%',
			left   : '0',
			right  : '0',
			margin : 'auto'
		},
		startButton :
		{
			display      : 'block',
			maxWidth     : '500px',
			borderRadius : '5px',
			boxShadow    : '10px 5px 10px #aaa',
			color        : 'white',
			visibility   : 'hidden', // hide first because ugly shape show up
			'&:hover'    :
			{
				filter : 'saturate(105%) brightness(105%)'
			}
		},
		startButtonPC :
		{
			maxHeight : '10vh'
		},
		startButtonSP :
		{
			maxHeight : '10vh',
			width     : '100%'
		},
		btnTextWrapper :
		{
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		startButtonText :
		{
			fontSize : '1.2rem',
			color    : 'white'
		},
		startButtonTextSmall :
		{
			fontSize : '0.72rem',
			color    : 'white'
		},
		buttonContentDevided : {
			position : 'relative',
			display  : 'flex',
			width    : '100%',
			height   : '100%'
		},
		buttonContentLeft : {
			width : '92%'
		},
		buttonContentLeftItem : {
			padding   : '0',
			textAlign : 'center'
		},
		buttonContentRight : {
			width          : '8%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		information :
		{
			fontSize        : '1rem',
			backgroundColor : 'rgba(255,255,255,0.8)',
			width           : '100%',
			display         : 'none', // 'flex',
			flexDirection   : 'column',
			alignItems      : 'center',
			justifyContent  : 'center',
			padding         : '5%'
		},
		imageContentPC :
		{
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100vw',
			maxHeight      : '100%'
		},
		imageContentSP :
		{
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100%',
			maxHeight      : '100vh'
		},
		container : {
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'center',
			width          : '100%',
			height         : '100%'
		},
		imageArea : {
			position : 'relative'
		},
		linkToDefault : {
			marginTop  : '1vh',
			color      : '#e26b04',
			textAlign  : 'center',
			fontSize   : '0.8rem',
			cursor     : 'pointer',
			fontWeight : 'bold',
			visibility : 'hidden'
		},
		linkToDefaultIcon : {
			fontFamily : '"M PLUS Rounded 1c", Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans", "ヒラギノ角ゴ", Meiryo, sans-serif'
		},
		disabledScreen : {
			position        : 'fixed',
			top             : '0',
			left            : '0',
			height          : '100vh',
			width           : '100vw',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'white'
		},
		disabledIndicator : {
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'white',
			padding         : '20px 25px',
			border          : '1px solid var(--text-color)',
			borderRadius    : '10px',
			maxWidth        : '90vw',
			marginBottom    : '15%'
		},
		disabledText : {
			fontSize  : '1.5rem',
			color     : 'var(--text-color)',
			textAlign : 'center'
		}
	});

let availableRoomFound = false;

let initialSetup = true;

let roomStatusListener = null;

let kokyakuId = '';

const operatorKeyNotChecked = 0;

const operatorNotGiven = 1;

const operatorAvailable = 2;

const operatorBusy = 3;

const operatorNotInRoom = 4;

// hostのキーは与えられたが、キーが存在していない
const operatorNotExists = 5;

const GuestLobby = ({
	mediaPerms,
	classes,
	setJoinRoomNoticeDialogOpen,
	setRoomState,
	logoUrl,
	setLogoUrl
}) =>
{

	const location = useLocation();

	const [ userExists, setUserExists ] = useState(false);

	const [ disabled, setDisabled ] = useState(false);

	const [ notFound, setNotFound ] = useState(false);

	const [ parameter, setParameter ] = useState({
		id     : null,
		loaded : false
	});

	const [ personalCallEnabled, setPersonalCallEnabled ] = useState(false);

	// Image URls
	const [ userBgPCUrl, setUserBgPCUrl ] = useState('');
	const [ userBgSPUrl, setUserBgSPUrl ] = useState('');

	// OPEN/CLOSE TIME
	const [ userOpenTime, setUserOpenTime ] = useState('10:00');
	const [ userCloseTime, setUserCloseTime ] = useState('18:00');

	// Button Position
	const [ userBtnPositionObj, setUserBtnPositionObj ] = useState({
		PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
		SP : { top: '49', bottom: '', verticCenter: false }
	});

	// Holiday
	const [ userHoliday, setUserHoliday ] = useState([ 0, 0, 0, 0, 0, 0, 0, 0 ]);

	// nationalHolidays 
	const [ nationalHolidays, setNationalHolidays ] = useState([]);

	// button
	const [ buttonColor, setButtonColor ] = useState('F15A24');
	const [ buttonText, setButtonText ] = useState('オンライン相談受付');
	const [ buttonPadding, setBtnPadding ] = useState('0.8vh 2vw');

	// background
	const [ bgPCGradient, setBgPCGradient ] = useState('#EEEEEE, #EEEEEE');
	const [ bgSPGradient, setBgSPGradient ] = useState('#EEEEEE, #EEEEEE');

	// directMode
	const [ directMode, setDirectMode ] = useState(false);

	// window size
	const [ aspectRatio, setAspectRatio ] = useState(window.innerWidth/window.innerHeight);

	// available room
	const [ roomAvailable, setRoomAvailable ] = useState(false);

	// call target
	const [ targetOperator, setTargetOperator ] = useState('');
	const [ targetOperatorName, setTargetOperatorName ] = useState('');

	// target host status notGiven, inRoom, notInRoom, givenButNotExistInHosts
	const [ targetOperatorStatus, setTargetOperatorStatus ]
		= useState(operatorKeyNotChecked);

	const startButtonAreaRef = useRef();
	const startButtonRef = useRef();
	const btnTextWrapperRef = useRef();
	const startButtonTextRef = useRef();
	const startButtonTextSecondRef = useRef();
	const startButtonTextSmallRef = useRef();
	const startButtonTextArrowRef = useRef();

	const bgImgRef = useRef();
	const bgImgAreaRef = useRef();

	useEffect(() =>
	{
		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		const paramsObj = parseUrlParams({
			paramsValues : paramsValue,
			keys         : [ 'id', 'kokyaku_id', 'operator' ]
		});

		if (paramsObj['operator'])
		{
			setTargetOperator(paramsObj['operator']);
		}
		else
		{
			setTargetOperator('');
		}

		if (paramsObj['kokyaku_id'])
		{
			kokyakuId = paramsObj['kokyaku_id'];
		}
		else
		{
			kokyakuId = '';
		}

		if (paramsObj['id'])
		{

			setParameter({
				id     : paramsObj['id'],
				loaded : true
			});
		}
		else
		{
			setParameter({
				id     : null,
				loaded : true
			});
		}

	// eslint-disable-next-line
	},[]);

	// Is OpenTime ?
	const isWorkTime = useMemo(() =>
	{

		const now = dayjs();

		// dayjs => sunday 0 , userHolidy => monday 0
		let wkDay = now.weekday() - 1;

		if (wkDay === -1)
		{
			wkDay = 6;
		}

		// 曜日
		if (userHoliday[wkDay] === 1)
		{
			return false;
		}

		const year = now.year();
		const month = now.month()+1;
		const date = now.date();

		let isHoliday = false;

		nationalHolidays.forEach((d) =>
		{
			if (year === d.year && month === d.month && date === d.day)
			{
				isHoliday = true;
			}
		});

		if (isHoliday)
		{
			return false;
		}

		const openTimeString = `${year}-${month}-${date} ${userOpenTime}:00`;
		const closeTimeString = `${year}-${month}-${date} ${userCloseTime}:00`;

		const openTimeObj = dayjs(openTimeString);
		const closeTimeObj = dayjs(closeTimeString);

		if (now > openTimeObj && now < closeTimeObj)
		{
			return true;
		}

		return false;
	}, [ userOpenTime, userCloseTime, userHoliday, nationalHolidays ]);

	const updateDimensions = useCallback(() =>
	{
		if (userExists)
		{
			if (startButtonRef.current)
			{
				startButtonRef.current.style.visibility = 'visible';

				if (!isWorkTime
				|| (
					!roomAvailable && personalCallEnabled &&
					(targetOperatorStatus === operatorBusy
					|| targetOperatorStatus === operatorNotInRoom
					|| targetOperatorStatus === operatorNotExists)
				)
				)
				{
					startButtonRef.current.style.backgroundColor = '#aaaaaa';
				}
				else if (buttonColor)
				{
					startButtonRef.current.style.backgroundColor = `#${buttonColor}`;
				}

				// PC
				if (window.innerWidth/window.innerHeight >= breakPointAspectRatio)
				{
					if (startButtonTextRef.current)
					{
					// button text size
						if (window.innerHeight < 400 || window.innerWidth < 500)
						{
							startButtonTextRef.current.style.fontSize = '0.3rem';
							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.2rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '0.3rem';
							}
							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '15px';
							}
						}
						else if (window.innerHeight < 500 || window.innerWidth < 700)
						{
							startButtonTextRef.current.style.fontSize = '0.50rem';
							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.35rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '0.5rem';
							}
							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '25px';
							}
						}
						else if (window.innerHeight < 650 || window.innerWidth < 1000)
						{
							startButtonTextRef.current.style.fontSize = '0.75rem';
							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.63rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '0.75rem';
							}

							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '30px';
							}
						}
						else if (window.innerHeight < 800 || window.innerWidth < 1200)
						{
							startButtonTextRef.current.style.fontSize = '1.1rem';

							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.72rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '1.1rem';
							}
							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '40px';
							}
						}
						else if (window.innerWidth < 1536)
						{
							startButtonTextRef.current.style.fontSize = '1.2rem';

							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.81rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '1.2rem';
							}

							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '45px';
							}
						}
						else
						{
							startButtonTextRef.current.style.fontSize = '1.4rem';
							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.9rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '1.4rem';
							}

							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '50px';
							}
						}
					}

					if (startButtonAreaRef.current)
					{
					// button location
						if (userBtnPositionObj.PC.verticCenter)
						{
							startButtonAreaRef.current.style.top = '0';
							startButtonAreaRef.current.style.bottom = '0';
						}
						else
						{
							startButtonAreaRef.current.style.top = userBtnPositionObj.PC.top ? `${userBtnPositionObj.PC.top}%` : '';
							startButtonAreaRef.current.style.bottom = userBtnPositionObj.PC.bottom ? `${userBtnPositionObj.PC.bottom}%` : '';
						}

						if (userBtnPositionObj.PC.horizCenter)
						{
							startButtonAreaRef.current.style.left = '0';
							startButtonAreaRef.current.style.right = '0';
						}
						else
						{
							startButtonAreaRef.current.style.left = userBtnPositionObj.PC.left ? `${userBtnPositionObj.PC.left}%` : '';
							startButtonAreaRef.current.style.right = userBtnPositionObj.PC.right ?`${userBtnPositionObj.PC.right}%` : '';
						}
					}

				}
				else // SP
				{
					if (startButtonTextRef.current)
					{
						// button text size
						if (window.innerHeight < 500 || window.innerWidth < 250)
						{
							startButtonTextRef.current.style.fontSize = '0.45rem';
							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.4rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '0.45rem';
							}
							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '20px';
							}
						}
						else if (window.innerHeight < 550 || window.innerWidth < 300)
						{
							startButtonTextRef.current.style.fontSize = '0.8rem';
							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.54rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '0.8rem';
							}

							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '25px';
							}
						}
						else if (window.innerHeight < 600 || window.innerWidth < 400)
						{
							startButtonTextRef.current.style.fontSize = '1rem';
							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.63rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '1rem';
							}

							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '30px';
							}
						}
						else if (window.innerHeight < 800)
						{
							startButtonTextRef.current.style.fontSize = '1.1rem';
							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.72rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '1.1rem';
							}
							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '40px';
							}
						}
						else if (window.innerHeight < 1280)
						{
							startButtonTextRef.current.style.fontSize = '1.2rem';
							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.81rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '1.2rem';
							}

							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '45px';
							}
						}
						else
						{
							startButtonTextRef.current.style.fontSize = '1.4rem';
							if (startButtonTextSmallRef.current)
							{
								startButtonTextSmallRef.current.style.fontSize = '0.9rem';
							}
							if (startButtonTextSecondRef.current)
							{
								startButtonTextSecondRef.current.style.fontSize = '1.4rem';
							}

							if (startButtonTextArrowRef.current)
							{
								startButtonTextArrowRef.current.style.fontSize = '50px';
							}
						}
					}

					if (startButtonAreaRef.current)
					{
						// button location
						if (userBtnPositionObj.SP.verticCenter)
						{
							startButtonAreaRef.current.style.top = '0';
							startButtonAreaRef.current.style.bottom = '0';
							startButtonAreaRef.current.style.left = '0';
							startButtonAreaRef.current.style.right = '0';
						}
						else
						{
							startButtonAreaRef.current.style.top = userBtnPositionObj.SP.top ? `${userBtnPositionObj.SP.top}%` : '';
							startButtonAreaRef.current.style.bottom = userBtnPositionObj.SP.bottom ? `${userBtnPositionObj.SP.bottom}%` : '';
							startButtonAreaRef.current.style.left = '0';
							startButtonAreaRef.current.style.right = '0';
						}
					}
				}
			}

			if (bgImgRef.current && bgImgAreaRef.current && startButtonRef.current)
			{

				// PC case. window height is smaller than background image
				if (window.innerWidth/window.innerHeight >= breakPointAspectRatio)
				{
				// img size is limited by Height (horizontally long device)
					if (window.innerHeight <= bgImgRef.current.clientHeight)
					{
						bgImgAreaRef.current.style.height = '100%';
						bgImgAreaRef.current.style.width = '';
					}
					else
					{
						bgImgAreaRef.current.style.height = '';
						bgImgAreaRef.current.style.width = '100%';
					}
				}
				// Smart phone case. window width is smaller than background image
				else
				// img size is limited by Width (vertically long device)
				if (window.innerWidth <= bgImgRef.current.clientWidth)
				{
					bgImgAreaRef.current.style.width = '100%';
					bgImgAreaRef.current.style.height = '';
				}
				else
				{
					bgImgAreaRef.current.style.width = '';
					bgImgAreaRef.current.style.height = '100%';
				}

			}

			setAspectRatio(window.innerWidth/window.innerHeight);
		}

	}, [
		userExists,
		buttonColor,
		userBtnPositionObj,
		isWorkTime,
		targetOperatorStatus,
		roomAvailable,
		personalCallEnabled
	]);

	useEffect(() =>
	{
		window.addEventListener('resize', updateDimensions);

		return () => window.removeEventListener('resize', updateDimensions);

	}, [ updateDimensions ]);

	useEffect(() =>
	{
		if (targetOperatorStatus !== operatorKeyNotChecked)
		{
			updateDimensions();
		}
	}, [ updateDimensions, targetOperatorStatus ]);

	const fetchInfos = useCallback(() =>
	{
		const parts = window.location.hostname.split('.');
		const subdomain = parts.shift();

		firestore.collection('users').where('subdomain', '==', subdomain)
			.get()
			.then((querySnapshot) =>
			{
				if (querySnapshot.size === 0)
				{
					setNotFound(true);
				}
				else
				{
					querySnapshot.forEach((doc) =>
					{
						if (doc.exists)
						{
							const userData = doc.data();

							let lobbyBrowserTitle = userData.browserTitle;

							let lobbyButtonColor = userData.buttonColor;

							let lobbyButtonText = userData.buttonText;

							let lobbyBtnPadding = userData.buttonPadding;

							let lobbyBgPCUrl = userData.bgPCUrl;

							let lobbyBgSPUrl = userData.bgSPUrl;

							let lobbyBgPCGradient = userData.bgPCGradient;

							let lobbyBgSPGradient = userData.bgSPGradient;

							let lobbyBtnPosition = userData.btnPosition;

							let holidayValue =
								userData.holiday ? userData.holiday : [ 0, 0, 0, 0, 0, 0, 0 ];

							let nationalHolidayValue = userData.nationalHoliday;

							let openTimeValue = userData.openTime ? userData.openTime : '10:00';

							let closeTimeValue = userData.closeTime ? userData.closeTime : '18:00';

							// when lobby id is specified
							if (parameter.id && userData.skillGroups)
							{
								const skillGroupIndex
								= userData.skillGroups.findIndex((lp) => lp.skillId === parameter.id);

								if (skillGroupIndex !== -1)
								{
									const skillGroupData = userData.skillGroups[skillGroupIndex];

									if (skillGroupData.showOwnLobby)
									{
										lobbyBrowserTitle = skillGroupData.browserTitle ?
											skillGroupData.browserTitle
											: lobbyBrowserTitle;
										lobbyButtonColor = skillGroupData.buttonColor ?
											skillGroupData.buttonColor : lobbyButtonColor;
										lobbyButtonText = skillGroupData.buttonText ?
											skillGroupData.buttonText : lobbyButtonText;
										lobbyBtnPadding = skillGroupData.buttonPadding ?
											skillGroupData.buttonPadding : lobbyBtnPadding;
										lobbyBgPCUrl = skillGroupData.bgPCUrl ?
											skillGroupData.bgPCUrl : lobbyBgPCUrl;
										lobbyBgSPUrl = skillGroupData.bgSPUrl ?
											skillGroupData.bgSPUrl : lobbyBgSPUrl;
										lobbyBgPCGradient = skillGroupData.bgPCGradient ?
											skillGroupData.bgPCGradient
											: lobbyBgPCGradient;
										lobbyBgSPGradient = skillGroupData.bgSPGradient ?
											skillGroupData.bgSPGradient
											: lobbyBgSPGradient;
										lobbyBtnPosition = skillGroupData.btnPosition ?
											skillGroupData.btnPosition : lobbyBtnPosition;
									}

									if (skillGroupData.customizedWorkTime)
									{
										holidayValue = skillGroupData.holiday || holidayValue;
										nationalHolidayValue =
											skillGroupData.nationalHoliday || nationalHolidayValue;
										openTimeValue = skillGroupData.openTime || openTimeValue;
										closeTimeValue = skillGroupData.closeTime || closeTimeValue;
									}
								}
							}

							if (lobbyBrowserTitle)
							{
								document.title = lobbyBrowserTitle;
							}

							setUserExists(true);

							setDisabled(userData.portalCallOnly ? true : false);

							setPersonalCallEnabled(userData.personalCallEnabled ? true : false);
							setLogoUrl(userData.logoUrl ? userData.logoUrl : '');
							setUserBgPCUrl(lobbyBgPCUrl ? lobbyBgPCUrl : '');
							setUserBgSPUrl(lobbyBgSPUrl ? lobbyBgSPUrl : '');
							setUserOpenTime(openTimeValue);
							setUserCloseTime(closeTimeValue);
							setUserHoliday(
								holidayValue
							);
							setButtonColor(lobbyButtonColor ? lobbyButtonColor : 'F15A24');
							setButtonText(lobbyButtonText ? lobbyButtonText : 'オンライン相談受付');
							setBtnPadding(lobbyBtnPadding ? lobbyBtnPadding : '0.8vh 2vw');
							setBgPCGradient(lobbyBgPCGradient ? lobbyBgPCGradient : '#EEEEEE, #EEEEEE');
							setBgSPGradient(lobbyBgSPGradient ? lobbyBgSPGradient : '#EEEEEE, #EEEEEE');
							setDirectMode(userData.directMode ? true: false);
							if (lobbyBtnPosition
						&& lobbyBtnPosition.PC
						&& lobbyBtnPosition.SP)
							{
								setUserBtnPositionObj(lobbyBtnPosition);
							}
							else
							{
								setUserBtnPositionObj({
									PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
									SP : { top: '49', bottom: '', verticCenter: false }
								});
							}

							if (nationalHolidayValue)
							{
								firestore.collection('nationalHolidaysJP').orderBy('createdAt', 'desc')
									.get()
									.then((document) =>
									{
										const nationalHolidaysAr = document.docs[0]?.data()?.holidays;

										if (nationalHolidaysAr)
										{
											setNationalHolidays(
												nationalHolidaysAr.filter(
													(h) => Number(h.type) <= Number(userData.nationalHoliday)
												)
											);
										}
									});
							}
						}
					});
				}
			});

		// 通話可能な部屋があるかチェック(リスニング)
		roomStatusListener = firestore.collection('rooms').where('subdomain', '==', subdomain)
			.onSnapshot((snapshot) =>
			{
				//
				// check private call to host
				//

				// target host given
				if (!targetOperator)
				{
					setTargetOperatorStatus(operatorNotGiven);
					setTargetOperatorName('');
				}
				else if (targetOperatorStatus === operatorKeyNotChecked
					|| targetOperatorStatus === operatorAvailable
					|| targetOperatorStatus === operatorNotInRoom
					|| targetOperatorStatus === operatorBusy)
				{
					let targetOperatorInRoom = false;

					let targetOperatorExists = false;

					let targetOperatorUserName = '';

					let targetOperatorRoomStatus = 0;

					snapshot.forEach((d) =>
					{
						const roomData = d.data();

						if (roomData.personalCallKey === targetOperator)
						{
							targetOperatorInRoom = true;

							targetOperatorRoomStatus = roomData.status;

							targetOperatorUserName = roomData.user;

							return;
						}
					});

					// check if in room
					if (targetOperatorInRoom)
					{
						setTargetOperatorName(targetOperatorUserName);

						if (targetOperatorRoomStatus === ROOM_ONLINE
							|| targetOperatorRoomStatus === ROOM_APPOINTMENT)
						{
							setTargetOperatorStatus(operatorAvailable);
						}
						else if (targetOperatorRoomStatus === ROOM_OFFLINE)
						{
							setTargetOperatorStatus(operatorNotInRoom);
						}
						else
						{
							setTargetOperatorStatus(operatorBusy);
						}
					}
					else
					{
						firestore.collection('hosts').where('subdomain', '==', subdomain)
							.get()
							.then((hostsDocs) =>
							{
								hostsDocs.forEach((doc) =>
								{

									const hostData = doc.data();

									if (hostData.personalCallKey === targetOperator)
									{
										targetOperatorExists = true;

										targetOperatorUserName = hostData.hostName;

										return;
									}
								});

								setTargetOperatorName(targetOperatorUserName);

								// check if exists
								if (targetOperatorExists)
								{
									setTargetOperatorStatus(operatorNotInRoom);
								}
								else
								{
									setTargetOperatorStatus(operatorNotExists);
								}
							});
					}
				}

				// 初回チェック
				if (initialSetup === true)
				{
					snapshot.forEach((d) =>
					{
						if (d.exists)
						{
							const roomData = d.data();

							if (roomData.status === ROOM_ONLINE)
							{
								availableRoomFound = true;
								setRoomAvailable(true);

								return;
							}
						}
					});

					initialSetup = false;
				}
				// ページロード後の更新
				else
				{
					// 今回の更新で通話可能な部屋があるか？
					let availableRoomFoundThisTime = false;

					snapshot.forEach((d) =>
					{
						if (d.exists)
						{
							const roomData = d.data();

							if (roomData.status === ROOM_ONLINE)
							{
								availableRoomFoundThisTime = true;

								return;
							}
						}
					});

					availableRoomFound = availableRoomFoundThisTime;

					// 1人在席でcallがかかった時など、頻繁に切り替わらないように一定時間後にステータスをチェック
					// それでも変更されていればステータス変更し画面に画面
					if (roomAvailable !== availableRoomFound)
					{
						setTimeout(() =>
						{
							if (roomAvailable !== availableRoomFound)
							{
								setRoomAvailable(availableRoomFound);
							}
						}, 1000);
					}
				}
			});
	}, [
		roomAvailable,
		setLogoUrl,
		parameter.id,
		targetOperatorStatus,
		targetOperator
	]);

	const askForPerms = () =>
	{
		if (mediaPerms.video || mediaPerms.audio)
		{
			navigator.mediaDevices.getUserMedia(mediaPerms);
		}
	};

	const handleJoin = () =>
	{
		askForPerms();
		// direct call needs room.state === 'new'. make sure to change state
		setRoomState('new');
		setJoinRoomNoticeDialogOpen(true);
	};

	useEffect(() =>
	{
		// get lobby data from db when loaded
		if (parameter.loaded)
		{
			fetchInfos();
		}

		return () =>
		{
			if (roomStatusListener)
			{
				// stop listening
				try
				{
					roomStatusListener();
				}
				catch
				{
					// do nothing
				}
			}

			roomStatusListener = null;
		};
	}, [ fetchInfos, parameter.loaded ]);

	let mainBtnText = buttonText;

	let buttonContentType = 0;

	if (!isWorkTime)
	{
		mainBtnText = '受付時間外です';
	}
	else if (targetOperatorStatus === operatorNotExists && personalCallEnabled)
	{
		mainBtnText = '※指定URLは無効です';

		if (roomAvailable)
		{
			buttonContentType = 4;
			// 2列目 他のオペレータが対応します
		}
		else
		{
			buttonContentType = 2;
			// 2列目 しばらくお待ち下さい
		}
	}
	// 指名着信の担当者が待機中/呼び出し待機中
	else if (targetOperatorStatus === operatorAvailable && personalCallEnabled)
	{
		mainBtnText = '担当者にオンライン相談';
	}
	// 待機中がいない
	else if (!roomAvailable)
	{
		if (targetOperatorStatus !== operatorNotGiven && personalCallEnabled)
		{
			mainBtnText = '担当者は不在のため';
			buttonContentType = 2;
			// 2列目 しばらくお待ち下さい
		}
		else
		{
			mainBtnText = 'ただいま混み合っています';
			buttonContentType = 3;
			// 2列目 crowded now
		}
	}
	// 待機中はいるが、氏名着信先の担当者がいない
	else if ((targetOperatorStatus === operatorNotInRoom && personalCallEnabled)
		|| (targetOperatorStatus === operatorBusy && personalCallEnabled))
	{
		mainBtnText = '担当者は不在のため';
		buttonContentType = 1;
		// 2列目 他のオペレータが対応します
	}

	return (
		<>
			{ disabled || notFound ?
				<div className={classes.disabledScreen}>
					<div className={classes.disabledIndicator}>
						<p className={classes.disabledText}>
							このページは使用できません
						</p>
					</div>
				</div>
				:
				<div
					className={classes.root}
					style={aspectRatio < breakPointAspectRatio ? { background: `linear-gradient(${bgSPGradient})` } :{ background: `linear-gradient(${bgPCGradient})` }}
				>
					{ aspectRatio < breakPointAspectRatio ?
						<>

							<div className={classes.container}>
								<div className={classes.imageArea} ref={bgImgAreaRef}>
									{ userBgSPUrl &&
									<img
										className={classes.imageContentSP}
										src={userBgSPUrl}
										alt='PageImage'
										ref={bgImgRef}
										onLoad={updateDimensions}
									/>
									}

									{ logoUrl &&
									<img
										className={classes.logo}
										src={logoUrl}
										alt='Logo'
									/>
									}
									<div
										ref={startButtonAreaRef}
										className={`${classes.startButtonArea} ${classes.startButtonAreaSP}`}
									>
										<Button
											onClick={isWorkTime
													&& buttonContentType !== 2
												? handleJoin
												: () => {}
											}
											className={`${classes.startButton} ${classes.startButtonSP}`}
											style={{
												padding : buttonPadding
											}}
											ref={startButtonRef}
											id='startButton'
										>
											<div className={classes.btnTextWrapper} ref={btnTextWrapperRef}>

												{ buttonContentType === 1 ?
													<div className={classes.buttonContentDevided}>
														<div className={classes.buttonContentLeft}>
															<Typography
																ref={startButtonTextRef}
																className={classes.buttonContentLeftItem}
															>
																{mainBtnText}
															</Typography>
															<Typography
																ref={startButtonTextSecondRef}
																className={classes.buttonContentLeftItem}
															>
																他のオペレータが対応します
															</Typography>
														</div>
														<div className={classes.buttonContentRight}>
															<PlayArrowIcon ref={startButtonTextArrowRef}/>
														</div>
													</div>
													:
													<>
														<Typography
															ref={startButtonTextRef}
															className={classes.startButtonText}
														>
															{ mainBtnText}
														</Typography>

														{ buttonContentType === 4 ?
															<Typography
																ref={startButtonTextSecondRef}
																className={classes.startButtonText}
															>
																他のオペレータが対応します
															</Typography>

															: buttonContentType === 2 ?
																<Typography
																	ref={startButtonTextSecondRef}
																	className={classes.startButtonText}
																>
																	しばらくお待ち下さい
																</Typography>

																: buttonContentType === 3 ?
																	<Typography
																		ref={startButtonTextSmallRef}
																		className={classes.startButtonTextSmall}
																	>
																		crowded now
																	</Typography>

																	: null
														}
													</>
												}
											</div>

										</Button>
									</div>
								</div>
							</div>

						</>
						:
						<>

							<div className={classes.container}>
								<div className={classes.imageArea} ref={bgImgAreaRef}>
									{ userBgPCUrl &&
									<img
										className={classes.imageContentPC}
										src={userBgPCUrl}
										alt='PageImage'
										ref={bgImgRef}
										onLoad={updateDimensions}
									/>
									}

									{ logoUrl &&
									<img
										className={classes.logo}
										src={logoUrl}
										alt='Logo'
									/>
									}
									<div
										ref={startButtonAreaRef}
										className={classes.startButtonArea}
									>
										<Button
											onClick={isWorkTime
													&& buttonContentType !== 2
												? handleJoin
												: () => {}
											}
											className={`${classes.startButton} ${classes.startButtonPC}`}
											style={{
												padding : buttonPadding
											}}
											ref={startButtonRef}
											id='startButton'
										>
											<div className={classes.btnTextWrapper} ref={btnTextWrapperRef}>
												{ buttonContentType === 1 ?
													<div className={classes.buttonContentDevided}>
														<div className={classes.buttonContentLeft}>
															<Typography
																ref={startButtonTextRef}
																className={classes.buttonContentLeftItem}
															>
																{mainBtnText}
															</Typography>
															<Typography
																ref={startButtonTextSecondRef}
																className={classes.buttonContentLeftItem}
															>
																他のオペレータが対応します
															</Typography>
														</div>
														<div className={classes.buttonContentRight} >
															<PlayArrowIcon ref={startButtonTextArrowRef}/>
														</div>
													</div>
													:
													<>
														<Typography
															ref={startButtonTextRef}
															className={classes.startButtonText}
														>
															{ mainBtnText }
														</Typography>
														{ buttonContentType === 4 ?
															<Typography
																ref={startButtonTextSecondRef}
																className={classes.startButtonText}
															>
																他のオペレータが対応します
															</Typography>

															: buttonContentType === 2 ?
																<Typography
																	ref={startButtonTextSecondRef}
																	className={classes.startButtonText}
																>
																	しばらくお待ち下さい
																</Typography>
																: buttonContentType === 3 ?
																	<Typography
																		ref={startButtonTextSmallRef}
																		className={classes.startButtonTextSmall}
																	>
																		crowded now
																	</Typography>
																	:null
														}
													</>
												}
											</div>
										</Button>
									</div>

								</div>
							</div>
						</>
					}
					<JoinRoomNoticeDialog
						type={'lobby'}
						directMode={directMode}
						skillId={parameter?.id}
						targetOperator={personalCallEnabled ? targetOperator : ''}
						targetOperatorName={personalCallEnabled ? targetOperatorName : ''}
						invalidOperatorUrl={targetOperatorStatus === operatorNotExists}
						kokyakuId={kokyakuId}
					/>
				</div>
			}
		</>
	);
};

GuestLobby.propTypes =
{
	room                        : PropTypes.object.isRequired,
	setMediaPerms  	            : PropTypes.func.isRequired,
	classes                     : PropTypes.object.isRequired,
	mediaPerms                  : PropTypes.object.isRequired,
	locale                      : PropTypes.string.isRequired,
	localesList                 : PropTypes.array.isRequired,
	setJoinRoomNoticeDialogOpen : PropTypes.func.isRequired,
	setRoomState                : PropTypes.func.isRequired,
	logoUrl                     : PropTypes.string,
	setLogoUrl                  : PropTypes.func.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		room        : state.room,
		mediaPerms  : state.settings.mediaPerms,
		locale      : state.intl.locale,
		localesList : state.intl.list,
		logoUrl     : state.settings.logoUrl
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setMediaPerms : (mediaPerms) =>
		{
			dispatch(settingsActions.setMediaPerms(mediaPerms));
		},
		setJoinRoomNoticeDialogOpen : (joinRoomNoticeDialogOpen) =>
		{
			dispatch(roomActions.setJoinRoomNoticeDialogOpen(joinRoomNoticeDialogOpen));
		},
		setRoomState : (state) =>
		{
			dispatch(roomActions.setRoomState(state));
		},
		setLogoUrl : (logoUrl) =>
		{
			dispatch(settingsActions.setLogoUrl(logoUrl));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room === next.room &&
				prev.intl.locale === next.intl.locale &&
				prev.intl.localesList === next.intl.localesList &&
				prev.settings.logoUrl === next.settings.logoUrl
			);
		}
	}
)(withStyles(styles)(GuestLobby)));

// React
import React, { useState, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../../RoomContext';

// Router
import { useLocation, useHistory } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Message
import { FormattedMessage, useIntl } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// recaptcha
import ReCAPTCHA from 'react-google-recaptcha';

// Firestore
import { functions } from '../../../../lib/firebase';

// util
import { getDomainWithoutSubdomain, parseUrlParams } from '../../../../utils';

// const
import { PAYMENT_FREE, ISSUED_AT_GUEST_PAGE, DEFAULT_SERVICE_ACCOUNT_KEY } from '../../../../const';

// Components
import ServiceAppBar from '../Parts/ServiceAppBar';
import PurchaseComplete from '../Parts/PurchaseComplete';

const styles = (theme) =>
	({
		root : {
			zIndex          : 99,
			width           : '100%',
			height          : '100%',
			overflow        : 'auto',
			backgroundColor : '#EBEBEC'
		},
		content : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		contentArea : {
			width           : 'calc( 100% - 20px )',
			maxWidth        : '750px',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			margin          : '30px 15px',
			padding         : '30px 0px',
			minWidth        : '320px',
			backgroundColor : '#FFF',
			borderRadius    : '20px'
		},
		contentAreaComplete : {
			padding : '30px 10px !important'
		},
		BtnArea : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			margin         : '15px 0 30px 0'
		},
		serviceLogo : {
			width          : '100%',
			maxWidth       : '500px',
			display        : 'flex',
			justifyContent : 'center'
		},
		serviceLogoImg : {
			width    : '55%',
			maxWidth : '250px',
			minWidth : '180px',
			height   : 'auto'
		},
		contentTitle : {
			marginBottom : '30px'
		},
		issueTicketTitleText : {
			color                        : '#cd2c82',
			fontSize                     : '1.4rem',
			fontWeight                   : 'bold',
			textAlign                    : 'center',
			[theme.breakpoints.up('sm')] :
			{
				fontSize : '1.5rem'
			}
		},
		ticketCodeSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '10px 0',
			width          : '100%'
		},
		freeTicketSection : {
			margin         : '15px 0',
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		movePageSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '15px 0',
			width          : '100%'
		},
		movePageText : {
			fontSize  : '0.8rem',
			color     : '#292929',
			width     : '100%',
			textAlign : 'center'
		},
		btnGroup : {
			width      : '90%',
			marginLeft : '10%',
			marginTop  : '8px'
		},
		explanationBox :
		{
			marginBottom : '25px'
		},
		explanationText :
		{
			color    : '#999898',
			fontSize : '0.8rem'
		},
		contentText :
		{
			color     : '#292929',
			fontSize  : '1rem',
			textAlign : 'center'
		},
		contentTextSmall :
		{
			width        : '95%',
			textAlign    : 'center',
			color        : '#292929',
			fontSize     : '0.8rem',
			marginBottom : '10px'
		},
		freeCondLine : {
			padding         : '0',
			height          : '34px',
			backgroundColor : '#F4DA23',
			borderRadius    : '3px',
			display         : 'flex',
			justifyContent  : 'space-between',
			alignItems      : 'center'
		},
		freeCondText : {
			color                        : '#292929',
			fontSize                     : '0.9rem',
			margin                       : '0 16px',
			[theme.breakpoints.up('sm')] :
			{
				fontSize : '1rem'
			}
		},
		startRectangle : {
			width        : '0',
			height       : '0',
			borderTop    : '20px solid transparent',
			borderBottom : '20px solid transparent',
			borderLeft   : '12px solid #FFF'
		},
		endRectangle : {
			width        : '0',
			height       : '0',
			borderTop    : '20px solid transparent',
			borderBottom : '20px solid transparent',
			borderRight  : '12px solid #FFF'
		},
		ticketCodeTitle : {
			color      : '#292929',
			fontSize   : '1.1rem',
			fontWeight : 'bold'
		},
		recaptchaBox : {
			maxWidth : '450px',
			margin   : '20px 0 20px 0'
		},
		freeTicketInputBox : {
			width                        : 'calc( 100% - 15px )',
			maxWidth                     : '450px',
			padding                      : '14px',
			margin                       : '25px 0',
			border                       : '1px solid #292929',
			borderRadius                 : '15px',
			display                      : 'flex',
			flexDirection                : 'column',
			justifyContent               : 'start',
			alignItems                   : 'center',
			[theme.breakpoints.up('md')] :
			{
				width : '60%'
			}
		},
		freeTicketBox : {
			padding      : '4px 10px',
			border       : '1px solid #292929',
			borderRadius : '10px',
			marginBottom : '30px'
		},
		ticketCodeText : {
			color      : '#cd2c82',
			fontSize   : '2rem',
			fontWeight : 'bold'
		},
		inputText : {
			background   : '#FFF',
			padding      : '0.6rem 0.6rem',
			borderRadius : '0.2rem',
			color        : '#292929',
			textAlign    : 'left',
			border       : '1px solid #292929',
			fontSize     : '16px',
			'&:focus'    : {
				outline : 'none'
			},
			'&:disabled' : {
				color : '#F1F1F1'
			},
			'&::-ms-reveal' : {
				display : 'none'
			}
		},
		inputTextTicket : {
			width    : '75%',
			maxWidth : '400px'
		},
		inputTextEmail : {
			width    : '95%',
			maxWidth : '400px'
		},
		visibilityButton : {
			position  : 'absolute',
			top       : '0',
			bottom    : '16px',
			right     : '-6px',
			color     : '#FFF',
			'&:hover' : {
				backgroundColor : 'transparent'
			}
		},
		circle : {
			width           : '4rem',
			height          : '4rem',
			borderRadius    : '4rem',
			backgroundColor : '#999898',
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			margin          : '0.5rem 0'
		},
		button : {
			padding      : '3px 35px',
			maxWidth     : '300px',
			borderRadius : '10px',
			fontSize     : '1.1rem',
			boxShadow    : '2px 2px 4px #aaa'
		},
		submitButton : {
			backgroundColor : '#cd2c82',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#D9388E'
			}
		},
		movePageButton : {
			backgroundColor : '#292929',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#4B4B4B'
			}
		},
		forgotPasswordLabel :
		{
			fontSize     : '0.6rem',
			color        : '#999898',
			textAlign    : 'center',
			marginBottom : '16px'
		},
		link :
		{
			color  : 'var(--submit-button-color)',
			cursor : 'pointer'
		},
		loadingWrapper :
		{
			width           : '100%',
			height          : 'calc( 100% - 40px)',
			display         : 'flex',
			paddingTop      : '250px',
			justifyContent  : 'center',
			alignItems      : 'flex-start',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		},
		loadingIndicator :
		{
			color : 'var(--text-color)'
		},
		errorMsg : {
			width        : '100%',
			color        : 'red',
			fontSize     : '0.8rem',
			textAlign    : 'center',
			marginBottom : '10px'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiBackdrop : {
			root : {
				backgroundColor : 'rgba(25,25,25,0.95)'
			}
		},
		MuiOutlinedInput : {
			input : {
				'&::-ms-reveal' : {
					display : 'none'
				}
			}
		}
	}
});

const ServiceGuestFreeTicket = ({
	classes
}) =>
{
	const intl = useIntl();
	const location = useLocation();
	const history = useHistory();

	const [ page, setPage ]= useState('ticketLogin');

	const [ errorMsg, setErrorMsg ] = useState('');

	const [ paramKey, setParamKey ]= useState('');

	const [ freeTicketEmail, setFreeTicketEmail ] = useState('');

	const [ createdExpiryTimestamp, setCreatedExpiryTimestamp ] = useState(null);

	const [ createdExpiryTerm, setCreatedExpiryTerm ] = useState(
		{ afterPurchase: {}, afterUse: {} }
	);

	const [ loading, setLoading ] = useState(false);

	const [ recaptchaToken, setRecaptchaToken ] = useState('');

	const updateFreeTicketEmail = (e) =>
	{
		setFreeTicketEmail(e.target.value);
	};

	// const changePage = (p) =>
	// {
	// 	setPage(p);
	// 	setErrorMsg('');
	// 	setTicketNumber('');
	// 	setFreeTicketEmail('');
	// };

	const moveToTicketPurchasePage = () =>
	{
		const serviceParamKey = paramKey
			? paramKey
			: DEFAULT_SERVICE_ACCOUNT_KEY;

		history.push({
			pathname : '/purchase',
			search   : `?id=${serviceParamKey}`,
			state    : {
				prevPage : 'freeTicket'
			}
		});
	};

	const handleIssueFreeTicket = () =>
	{

		if (recaptchaToken)
		{
			setErrorMsg('');

			const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

			if (!freeTicketEmail)
			{
				setErrorMsg(intl.formatMessage({
					id             : 'error.notEnteredEmail',
					defaultMessage : 'No email address entered'
				}));

				return;
			}
			else if (!emailPattern.test(freeTicketEmail))
			{
				setErrorMsg(intl.formatMessage({
					id             : 'error.incorrectEmail',
					defaultMessage : 'Incorrect email address'
				}));

				return;
			}

			setRecaptchaToken('');

			setLoading(true);

			const serviceTicketCreateAPI = functions.httpsCallable('serviceTicketCreate');

			const domain = getDomainWithoutSubdomain();

			serviceTicketCreateAPI({
				serviceAccountParamKey : paramKey,
				tickets                : [],
				email                  : freeTicketEmail,
				domain                 : domain,
				additionalEmail1       : '',
				additionalEmail2       : '',
				buyer                  : 'guest',
				salesChannel           : ISSUED_AT_GUEST_PAGE,
				paymentMethod          : PAYMENT_FREE
			}).then((response) =>
			{

				if (response.data?.success)
				{
					const createdTicket = response.data?.tickets[0];

					setLoading(false);
					setPage('showFreeTicket');
					setErrorMsg('');
					setCreatedExpiryTimestamp(createdTicket.expiryTimestamp);
					setCreatedExpiryTerm({
						afterPurchase : createdTicket.expiryTerm || {},
						afterUse      : createdTicket.expiryTermAfterUse || {}
					});
				}
				else if (response.data?.reason === 'alreadyRegistered')
				{
					setErrorMsg(intl.formatMessage({
						id             : 'error.issuedFreeTicket',
						defaultMessage : 'This email address has already been issued a free ticket'
					}));
					setLoading(false);
				}
				else
				{
					setErrorMsg(intl.formatMessage({
						id             : 'error.error',
						defaultMessage : 'An error has occurred'
					}));
					setLoading(false);
				}
			})
				.catch(() =>
				{
					setErrorMsg(intl.formatMessage({
						id             : 'error.error',
						defaultMessage : 'An error has occurred'
					}));
					setLoading(false);
				});
		}
	};

	const onRecapChange = (value) =>
	{
		if (value)
		{
			setRecaptchaToken(value);
		}
	};

	const onRecapExpired = () =>
	{
		setRecaptchaToken('');
	};

	// const handleSetPage = (p) =>
	// {
	// 	setErrorMsg('');
	// 	setLoading(false);
	// 	setTicketNumber('');
	// 	setPage(p);
	// };

	const currentDomain = getDomainWithoutSubdomain();

	let recaptchaKey = '6LfrL80pAAAAAC3UYQgyRTaNVaNHjWK69KsGYU6E';

	if (currentDomain === 'online-call.com')
	{
		recaptchaKey = '6Lc_Kc0pAAAAADsTdwctp2HhvCNql4_iLulDNVbH';
	}
	else if (currentDomain === 'localhost:4443')
	{
		recaptchaKey = '6Lf8S4wjAAAAAGpL9ToCsz2WJGP4UPbbNTLTncgD';
	}

	useEffect(() =>
	{
		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		let paramsObj = {};

		if (paramsValue)
		{
			paramsObj = parseUrlParams({
				paramsValues : paramsValue,
				keys         : [ 'id' ]
			});
		}

		if (!paramsObj['id'] || paramsObj['id'] === 'null')
		{
			paramsObj['id'] = DEFAULT_SERVICE_ACCOUNT_KEY;
		}

		const parts = window.location.hostname.split('.');

		// subdomainがある場合、subdomainなしに移動
		if (parts.length > 2 || (parts[parts.length - 1] === 'localhost' && parts.length > 1))
		{
			const domain = getDomainWithoutSubdomain();

			window.location.replace(`https://${domain}/purchase?id=${paramsObj['id']}`);
		}

		setParamKey(paramsObj['id']);

	}, [ location.search ]);

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>
				<ServiceAppBar
					free
					appBarHeight={40}
					disabled={page === 'showFreeTicket'}
				/>
				{ loading ?
					<Box className={classes.loadingWrapper}>
						<CircularProgress className={classes.loadingIndicator}/>
					</Box>
					: page === 'showFreeTicket' ?
						<Box className={classes.content}>
							<Box className={`${classes.contentArea} ${classes.contentAreaComplete}`}>
								<PurchaseComplete
									serviceParamKey={paramKey}
									ticketCode={''}
									ticketExpiryTimestamp={createdExpiryTimestamp}
									ticketExpiryTerm={createdExpiryTerm}
									ticketMinutes={5}
									ticketEmail={freeTicketEmail}
									free
								/>
							</Box>
						</Box>
						:
						<Box className={classes.content}>
							<Box className={classes.contentArea}>

								<Box className={classes.serviceLogo}>
									<img
										className={classes.serviceLogoImg}
										alt='service logo'
										src='images/service_logo_small.svg'
									/>
								</Box>
								<Box className={classes.titleArea}>
									<Typography className={classes.issueTicketTitleText}>
										<FormattedMessage
											id='freeservice1.serviceDescription1'
											defaultMessage='call an interpreter  with one touch'
										/>
									</Typography>
									<Typography className={classes.issueTicketTitleText}>
										<FormattedMessage
											id='freeservice1.serviceDescription2'
											defaultMessage='LIVE INTERPRETATION ONLINE'
										/>
									</Typography>
								</Box>
								{errorMsg &&
								<p className={classes.errorMsg}>{errorMsg}</p>
								}
								<Box className={classes.ticketCodeSection}>
									<Typography className={classes.contentText}>
										<FormattedMessage
											id='freeservice1.serviceDescription3'
											defaultMessage='You can use it once for free just by entering phone number and e-mail adless'
										/>
									</Typography>
									<Box className={classes.freeCondLine}>
										<Box className={classes.startRectangle}/>
										<Typography className={classes.freeCondText}>
											<FormattedMessage
												id='freeservice1.serviceDescription4'
												defaultMessage='One-time free use [up to 5 minutes]'
											/>
										</Typography>
										<Box className={classes.endRectangle}/>
									</Box>

									<Box className={classes.freeTicketInputBox}>
										<Typography className={classes.contentText}>
											<FormattedMessage
												id='service.email'
												defaultMessage='Email Address'
											/>
										</Typography>
										<Typography className={classes.contentTextSmall}>
											<FormattedMessage
												id='freeservice1.forSending'
												defaultMessage='*We will send you the ticket code'
											/>
										</Typography>
										<input
											value={freeTicketEmail}
											className={`${classes.inputText} ${classes.inputTextTicket}`}
											onChange={(e) => updateFreeTicketEmail(e)}
											type='email'
										/>

										<Box className={classes.recaptchaBox}>
											<ReCAPTCHA
												sitekey={recaptchaKey}
												onChange={onRecapChange}
												onExpired={onRecapExpired}
											/>
										</Box>

										<Button
											className={`${classes.button} ${classes.submitButton}`}
											onClick={handleIssueFreeTicket}
											disabled={!recaptchaToken || !freeTicketEmail}
											color='primary'
										>
											<FormattedMessage
												id='freeservice1.freeTicketIssueButton'
												defaultMessage='USE FREE TICKET'
											/>
										</Button>
									</Box>
								</Box>

								<Box className={classes.movePageSection}>
									<Typography className={classes.movePageText}>
										<FormattedMessage
											id='service.descriptionBuyTicket'
											defaultMessage='Click here to '
										/>
									</Typography>

									<Button
										className={`${classes.button} ${classes.movePageButton}`}
										onClick={moveToTicketPurchasePage}
										color='primary'
									>
										<FormattedMessage
											id='service.buyTicketButton'
											defaultMessage='BUY INTERPRETATING TICKET'
										/>
									</Button>
								</Box>
							</Box>
						</Box>
				}
			</Box>
		</MuiThemeProvider>
	);
};

ServiceGuestFreeTicket.propTypes =
{
	classes : PropTypes.object.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ServiceGuestFreeTicket)));
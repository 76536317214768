import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../RoomContext';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

// firestore
import { firestore } from '../../../lib/firebase';

// dayjs
import dayjs from 'dayjs';

const styles = () =>
	({
		root :
		{
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper :
		{
			width           : '90vw',
			padding         : '1% 1%',
			backgroundColor : '#FFF'
		},
		titleSection :
		{
			width    : '100%',
			position : 'relative'
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleText :
		{
			color    : 'var(--text-color)',
			fontSize : '1.1rem'
		},
		messageSection : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			marginBottom   : '10px'
		},
		message : {
			fontSize : '0.8rem',
			color    : 'var(--text-color)'
		},
		setDateSection : {
			marginTop      : '20px',
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		inputDate : {
			width    : '230px',
			fontSize : '0.9rem'
		},
		updateMessageSection : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'
		},
		updatedTime : {
			marginTop : '10px',
			fontSize  : '1rem',
			color     : '#e26b04'
		},
		updateMessage : {
			fontSize : '0.9rem',
			color    : 'var(--text-color)'
		},
		btnSection : {
			marginTop      : '30px',
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-around'
		},
		proceedBtn : {
			fontSize        : '1rem',
			padding         : '3px 24px',
			backgroundColor : '#e26b04',
			color           : '#FFF',
			'&:hover'       :
			{
				backgroundColor : '#e26b04',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		stopBtn : {
			fontSize        : '1rem',
			padding         : '3px 24px',
			backgroundColor : 'var(--text-color)',
			color           : '#FFF',
			'&:hover'       :
			{
				backgroundColor : 'var(--text-color)',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		errMsg :
		{
			color        : 'red',
			fontSize     : '0.9rem',
			lineHeight   : '0.9rem',
			marginBottom : '3px'
		},
		errorBox :
		{
			marginTop : '8px'
		},
		loading :
		{
			width           : '100%',
			height          : '100%',
			minWidth        : '25vw',
			minHeight       : '40vh',
			zIndex          : 9999999,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiInput : {
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				},
				'&.Mui-disabled' : {
					backgroundColor : '#F6F6F6'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		}
	}
});

const showNumberHint = (accountIdentifier, numberHint) =>
{
	if (accountIdentifier && numberHint)
	{
		return `${accountIdentifier.toUpperCase()}********${numberHint}`;
	}
	else
	{
		return '';
	}
};

const showExpiryTime = (expiryTimestamp) =>
{

	if (expiryTimestamp)
	{
		const dayjsTime = dayjs(expiryTimestamp);

		return dayjsTime.format('YYYY/MM/DD H:mm');
	}
	else
	{
		return '　-　';
	}
};

const todayStr = dayjs().format('YYYY-MM-DDT00:00'); // today

const ExpiryDialog = ({
	classes,
	show,
	type,
	closeMethod,
	target
}) =>
{

	const [ page, setPage ] = useState('home');

	const [ loading, setLoading ] = useState(false);

	const [ message, setMessage ] = useState('');

	const [ expiryDate, setExpiryDate ] = useState(null);

	const [ updatedExpiryTimestamp, setUpdatedExpiryTimestamp ] = useState('');

	const closeDialog = () =>
	{
		setLoading(false);
		setMessage('');
		closeMethod();
		setExpiryDate(null);
		setUpdatedExpiryTimestamp('');
	};

	const onExpiryDateChanged = (e) =>
	{
		if (e.target?.value)
		{
			setExpiryDate(e.target.value);
		}
	};

	const handleUpdateExpiryDate = () =>
	{

		if (expiryDate)
		{
			setLoading(true);

			setMessage('');
			setUpdatedExpiryTimestamp('');

			const timestampValue = dayjs(expiryDate, 'YYYY-MM-DDT00:00').valueOf();

			if (type === 'connection-expiry')
			{
				firestore.collection('connections').doc(target.id)
					.update({
						expiryTimestamp : timestampValue
					})
					.then(() =>
					{
						setPage('complete');

						setMessage('有効期限設定が完了しました');

						setUpdatedExpiryTimestamp(timestampValue);

						setLoading(false);
					})
					.catch(() =>
					{
						setMessage('エラーが発生しました');
						setLoading(false);
					});
			}

			else if (type === 'expiry')
			{
				firestore.collection('serviceTickets').doc(target.id)
					.update({
						expiryTimestamp : timestampValue
					})
					.then(() =>
					{
						setPage('complete');

						setMessage('有効期限設定が完了しました');

						setUpdatedExpiryTimestamp(timestampValue);

						setLoading(false);
					})
					.catch(() =>
					{
						setMessage('エラーが発生しました');
						setLoading(false);
					});
			}
			else if (type === 'use')
			{
				firestore.collection('serviceTickets').doc(target.id)
					.update({
						expiryTimestampForUse : timestampValue
					})
					.then(() =>
					{
						setPage('complete');

						setMessage('使用期限設定が完了しました');

						setUpdatedExpiryTimestamp(timestampValue);

						setLoading(false);
					})
					.catch(() =>
					{
						setMessage('エラーが発生しました');
						setLoading(false);
					});
			}
			else
			{
				setMessage('エラーが発生しました');
				setLoading(false);
			}

		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={show}
				onClose={loading ? () => {} : closeDialog}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				{ page === 'complete' ?
					<DialogContent>
						<DialogTitle id='form-dialog-title'>
							<div className={classes.titleSection}>
								<div className={classes.title}>
									<div className={classes.titleText}>
										{type === 'useAndLogin' ? '初回使用期限設定' : '有効期限変更'}
									</div>
								</div>
							</div>
						</DialogTitle>
						<Box className={classes.updateMessageSection}>
							<Typography>
								使用期限を設定しました。
							</Typography>
							<Typography>
								購入日時 : {showExpiryTime(target.createdAt)}
							</Typography>
							<Typography>
								コード : {showNumberHint(target.accountParamKey, target.numberHint)}
							</Typography>
							<Typography className={classes.updatedTime}>
								使用期限 : {showExpiryTime(updatedExpiryTimestamp)}
							</Typography>
						</Box>
						<Box className={classes.btnSection}>

							<Button
								className={classes.stopBtn}
								onClick={closeDialog}
								color='primary'
							>
								閉じる
							</Button>
						</Box>
					</DialogContent>
					:
					<DialogContent>
						{ loading ?
							<Box className={classes.loading}>
								<CircularProgress />
							</Box>
							:
							<>
								<DialogTitle id='form-dialog-title'>
									<div className={classes.titleSection}>
										<div className={classes.title}>
											<div className={classes.titleText}>
												有効期限設定
											</div>
										</div>
									</div>
								</DialogTitle>
								{ message &&
								<Box className={classes.messageSection}>
									<Typography className={classes.message}>
										{message}
									</Typography>
								</Box>
								}
								<Box className={classes.updateMessageSection}>
									<Typography>
										有効期限を選択して実行してください。
									</Typography>
									<Typography>
										購入日時 : {showExpiryTime(target.createdAt)}
									</Typography>
									<Typography>
										コード : {showNumberHint(target.accountParamKey, target.numberHint)}
									</Typography>
								</Box>

								<Box className={classes.setDateSection}>
									<FormControl variant='outlined'>
										<TextField
											className={classes.inputDate}
											variant='outlined'
											type='datetime-local'
											defaultValue={expiryDate}
											key={expiryDate}
											onSelect={(e) => onExpiryDateChanged(e)}
											onBlur={(e) => onExpiryDateChanged(e)}
											InputProps={{ inputProps: { min: todayStr, max: '2999-12-31T23:59' } }}
										/>
									</FormControl>
								</Box>
								<Box className={classes.btnSection}>
									<Button
										className={classes.proceedBtn}
										onClick={handleUpdateExpiryDate}
										color='primary'
									>
										実行
									</Button>

									<Button
										className={classes.stopBtn}
										onClick={closeDialog}
										color='primary'
									>
										中止
									</Button>
								</Box>
							</>
						}
					</DialogContent>
				}
			</Dialog>
		</MuiThemeProvider>
	);
};

ExpiryDialog.propTypes =
{
	classes     : PropTypes.object.isRequired,
	show        : PropTypes.bool.isRequired,
	type        : PropTypes.string.isRequired,
	target      : PropTypes.object,
	closeMethod : PropTypes.func.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(ExpiryDialog)));
// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// material ui
import { Box, Button, Typography } from '@material-ui/core';

const styles = () =>
	({
		root : {
			width           : '100%',
			minHeight       : '100%',
			backgroundColor : '#1E1E1E',
			display         : 'flex',
			justifyContent  : 'center',
			paddingTop      : '30px'
		},
		content : {
			width          : '100%',
			height         : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			maxWidth       : '600px',
			margin         : '0 15px'
		},
		introduction : {
			width    : '100%',
			maxWidth : '600px'
		},
		title : {
			fontSize : '1.3rem',
			color    : '#FFF'
		},
		introductionText : {
			fontSize : '0.8rem',
			color    : '#FFF'
		},
		image : {
			margin    : '10px 40px',
			minHeight : '250px'
		},
		buttons : {
			margin         : '10px 40px',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			width          : '100%'
		},
		startBtn : {
			width           : '100%',
			maxWidth        : '600px',
			padding         : '4px 0',
			marginBottom    : '10px',
			color           : '#000',
			backgroundColor : '#FED70A',
			fontSize        : '1.2rem',
			'&:hover'       : {
				color           : '#000',
				backgroundColor : '#FED70A',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		historyBtn : {
			width           : '100%',
			maxWidth        : '600px',
			padding         : '2px 0',
			color           : '#FFF',
			backgroundColor : '#757575',
			fontSize        : '1rem',
			'&:hover'       : {
				color           : '#FFF',
				backgroundColor : '#757575',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		infomation : {
			margin         : '30px 0 10px 0',
			display        : 'flex',
			justifyContent : 'space-between',
			width          : '100%',
			maxWidth       : '600px'
		},
		infomationBox : {
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center',
			border         : '1px solid #FFF',
			padding        : '10px 20px',
			margin         : '0',
			minHeight      : '80px'
		},
		infomationBoxLeft : {
			width       : '33%',
			marginRight : '5%'
		},

		infomationBoxRight : {
			width : '62%'
		},
		informationText : {
			color      : 'white',
			fontSize   : '1.1rem',
			lineHeight : '1.1rem',
			padding    : '4px 0',
			textAlign  : 'center'

		},
		informationTextSmall : {
			color     : 'white',
			fontSize  : '0.7rem',
			textAlign : 'center'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const LandingPage = ({
	classes,
	startFunction
}) =>
{

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>
				<Box className={classes.content}>
					<Box className={classes.introduction}>
						<Typography variant='h2' className={classes.title}>
							多言語通訳サポート
						</Typography>
						<Typography variant='h2' className={classes.introductionText}>
							観光名所やレストラン、医療機関や交通機関などで
						</Typography>
						<Typography variant='h2' className={classes.introductionText}>
							お困りごとが合った時に多言語で通訳サポートするサービスです。
						</Typography>
					</Box>
					<Box className={classes.image}/>
					<Box className={classes.buttons}>
						<Button
							className={classes.startBtn}
							onClick={startFunction}
						>
							今すぐ利用
						</Button>
						<Button className={classes.historyBtn}>
							利用履歴の確認
						</Button>
					</Box>
					<Box className={classes.infomation}>
						<Box className={`${classes.infomationBoxLeft} ${classes.infomationBox}`}>
							<Typography className={classes.informationText}>
								24時間
							</Typography>
							<Typography className={classes.informationText}>
								365日対応!
							</Typography>
						</Box>
						<Box className={`${classes.infomationBoxRight} ${classes.infomationBox}`}>
							<Typography className={classes.informationText}>
								対応言語は5ヵ国語
							</Typography>
							<Typography className={classes.informationTextSmall}>
								英語、中国語(北京語)
							</Typography>
							<Typography className={classes.informationTextSmall}>
								韓国語、スペイン語、ポルトガル語
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</MuiThemeProvider>
	);
};

LandingPage.propTypes =
{
	classes       : PropTypes.object.isRequired,
	startFunction : PropTypes.func.isRequired
};

export default withStyles(styles)(LandingPage);

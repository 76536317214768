const initialState =
{
	id                    : null,
	picture               : null,
	browser               : null,
	roles                 : [],
	canSendMic            : false,
	canSendWebcam         : false,
	canShareScreen        : false,
	canShareFiles         : false,
	audioDevices          : null,
	webcamDevices         : null,
	webcamInProgress      : false,
	audioInProgress       : false,
	screenShareInProgress : false,
	displayNameInProgress : false,
	loginEnabled          : false,
	raisedHand            : false,
	raisedHandInProgress  : false,
	loggedIn              : false,
	isSpeaking            : false,
	isAutoMuted           : true,
	user                  : {},
	host                  : {},
	hostListening         : null,
	logoutByHostListening : false,
	hostLogin             : 0, // 0: not logged in, 1:operator 2: admin
	settings              : {},
	rooms                 : [],
	guestInput1           : { type: '', key: '', value: '' },
	guestInput2           : { type: '', key: '', value: '' },
	serviceStopped        : false,
	useMobileView         : false,
	useMobileOrientation  : '',
	serviceTicketLogin    : {
		login            : false,
		ticketId         : '',
		ticketParamKey   : '',
		ticketNumberHint : '',
		expiryTimestamp  : null
	},
	serviceGuestLoggedIn : false,
	connectInfos         : {},
	hostLoginData        : {},
	userAgent            : ''
};

const me = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_ME':
		{
			const {
				peerId,
				loginEnabled
			} = action.payload;

			return {
				...state,
				id : peerId,
				loginEnabled
			};
		}

		case 'SET_BROWSER':
		{
			const { browser } = action.payload;

			return { ...state, browser };
		}

		case 'LOGGED_IN':
		{
			const { flag } = action.payload;

			return { ...state, loggedIn: flag };
		}

		case 'ADD_ROLE':
		{
			const roles = [ ...state.roles, action.payload.roleId ];

			return { ...state, roles };
		}

		case 'REMOVE_ROLE':
		{
			const roles = state.roles.filter((roleId) =>
				roleId !== action.payload.roleId);

			return { ...state, roles };
		}

		case 'SET_PICTURE':
			return { ...state, picture: action.payload.picture };

		case 'SET_MEDIA_CAPABILITIES':
		{
			const {
				canSendMic,
				canSendWebcam,
				canShareScreen,
				canShareFiles
			} = action.payload;

			return {
				...state,
				canSendMic,
				canSendWebcam,
				canShareScreen,
				canShareFiles
			};
		}

		case 'SET_AUDIO_DEVICES':
		{
			const { devices } = action.payload;

			return { ...state, audioDevices: devices };
		}

		case 'SET_AUDIO_OUTPUT_DEVICES':
		{
			const { devices } = action.payload;

			return { ...state, audioOutputDevices: devices };
		}

		case 'SET_WEBCAM_DEVICES':
		{
			const { devices } = action.payload;

			return { ...state, webcamDevices: devices };
		}

		case 'SET_AUDIO_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, audioInProgress: flag };
		}

		case 'SET_WEBCAM_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, webcamInProgress: flag };
		}

		case 'SET_SCREEN_SHARE_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, screenShareInProgress: flag };
		}

		case 'SET_RAISED_HAND':
		{
			const { flag } = action.payload;

			return { ...state, raisedHand: flag };
		}

		case 'SET_RAISED_HAND_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, raisedHandInProgress: flag };
		}

		case 'SET_DISPLAY_NAME_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, displayNameInProgress: flag };
		}

		case 'SET_IS_SPEAKING':
		{
			const { flag } = action.payload;

			return { ...state, isSpeaking: flag };
		}

		case 'SET_AUTO_MUTED':
		{
			const { flag } = action.payload;

			return { ...state, isAutoMuted: flag };
		}

		case 'SET_STATUS':
		{
			const { flag } = action.payload;

			return { ...state, isReady: flag };
		}

		case 'SET_USER':
		{
			const { user } = action.payload;

			return { ...state, user: user };
		}

		case 'SET_HOST':
		{
			const { host } = action.payload;

			return { ...state, host: host };
		}

		case 'SET_HOST_LOGIN':
		{
			const { host, hostLogin } = action.payload;

			return { ...state,
				host      : host,
				hostLogin : hostLogin
			};
		}

		case 'SET_HOST_LISTENING':
		{
			const { hostListening } = action.payload;

			return { ...state, hostListening: hostListening };
		}

		case 'SET_LOGOUT_BY_HOST_LISTENING' :
		{
			const { flag } = action.payload;

			return { ...state, logoutByHostListening: flag };
		}

		case 'SET_HOST_FIRST_LOGIN_COMP':
		{
			const { hostPassChangeComp } = action.payload;

			return {
				...state,
				host :
				{
					...state.host,
					passChangeComplete : hostPassChangeComp
				}
			};
		}

		case 'SET_SETTINGS':
		{
			const { settings } = action.payload;

			return { ...state, settings: settings };
		}

		case 'SET_ROOMS':
		{
			const { rooms } = action.payload;

			return { ...state, rooms: rooms };
		}

		case 'SET_GUEST_INPUT1':
		{
			const { guestInput1 } = action.payload;

			return { ...state, guestInput1: guestInput1 };
		}

		case 'SET_GUEST_INPUT2':
		{
			const { guestInput2 } = action.payload;

			return { ...state, guestInput2: guestInput2 };
		}

		case 'SET_SERVICE STOPPED':
		{
			const { flag } = action.payload;

			return { ...state, serviceStopped: flag };
		}

		case 'SET_USE_MOBILE_VIEW':
		{
			const { useMobileView } = action.payload;

			return {
				...state,
				useMobileView        : useMobileView.view,
				useMobileOrientation : useMobileView.orientation
			};
		}

		case 'SET_HOST_SUPPORT_CALL_LIST':
		{
			const { supportCallList } = action.payload;

			return {
				...state,
				host : {
					...state.host,
					supportCallList : supportCallList
				}
			};
		}

		case 'SET_POST_EVENTS' :
		{
			const { postEvents } = action.payload;

			return {
				...state,
				settings :
				{
					...state.settings,
					postEvents : [ ...postEvents ]
				}
			};
		}

		case 'UPDATE_PERSONAL_CALL_KEY' :
		{
			const { personalCallKey } = action.payload;

			return {
				...state,
				host :
				{
					...state.host,
					personalCallKey : personalCallKey
				}
			};
		}

		case 'SET_SERVICE_TICKET_LOGIN' :
		{
			const { serviceTicketLogin } = action.payload;

			return {
				...state,
				serviceTicketLogin : serviceTicketLogin
			};

		}

		case 'SET_SERVICE_GUEST_LOGGED_IN' :
		{
			const { open } = action.payload;

			return {
				...state,
				serviceGuestLoggedIn : open
			};

		}

		case 'SET_CONNECTION_INFOS' :
		{
			const { connectInfos } = action.payload;

			return {
				...state,
				connectInfos : connectInfos
			};
		}

		case 'SET_HOST_LOGIN_DATA' :
		{
			const { hostLoginData } = action.payload;

			return {
				...state,
				hostLoginData
			};
		}

		case 'SET_USER_AGENT' :
		{
			const { userAgent } = action.payload;

			return { ...state, userAgent: userAgent };
		}

		default:
			return state;
	}
};

export default me;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../RoomContext';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Mui icon
import ImageIcon from '@material-ui/icons/Image';

// Components
import Dropzone from 'react-dropzone';

// firebase
import { storage } from '../../../lib/firebase';

// Components
import WorkTimeSetting from './WorkTimeSetting';

const styles = () =>
	({
		root :
		{
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper :
		{
			width           : '90vw',
			padding         : '1% 1%',
			backgroundColor : '#666666'
		},
		titleSection :
		{
			width    : '100%',
			position : 'relative'
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleText :
		{
			color    : 'white',
			fontSize : '1.1rem'
		},
		promoteBtn :
		{
			width           : '40%',
			border          : '1px solid #8eba14',
			backgroundColor : '#8eba14',
			color           : 'white'
		},
		cancelBtn :
		{
			width           : '40%',
			border          : '1px solid #fff',
			backgroundColor : 'transparent',
			color           : 'white',
			marginRight     : '8%'
		},
		topData : {
			width          : '100%',
			height         : '1.75rem',
			display        : 'flex',
			margin         : '20px 0',
			justifyContent : 'space-between',
			lineHeight     : 1
		},
		workingTimeData : {
			width           : '100%',
			backgroundColor : '#FFF',
			display         : 'flex',
			margin          : '20px 0',
			padding         : '10px 0 15px 0',
			justifyContent  : 'center',
			borderRadius    : '10px'
		},
		inputLabel : {
			width         : '50%',
			fontSize      : '0.8rem',
			paddingTop    : '10px',
			// whiteSpace    : 'nowrap',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'flex-start'
		},
		label : {
			color        : 'white',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '100%',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#777777',
				boxShadow       : 'none'
			}
		},
		inputFieldBtnPos :
		{
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '80px',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#777777',
				boxShadow       : 'none'
			}
		},
		inputList : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			color        : 'var(--text-color)',
			minWidth     : '12rem'
		},
		inputListSmall : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 0.3rem',
			color        : 'var(--text-color)',
			minWidth     : '4rem'

		},
		settingLineStart : {
			display        : 'flex',
			justifyContent : 'start'
		},
		buttonPosInput :
		{
			marginRight : '5px'
		},
		imageBox : {
			width          : '100%',
			height         : '6rem',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			padding        : '2% 1.5%',
			'&> img'       : {
				objectFit : 'contain'
			}
		},
		errMsg :
		{
			color        : 'red',
			fontSize     : '0.9rem',
			lineHeight   : '0.9rem',
			marginBottom : '3px'
		},
		errorBox :
		{
			marginTop : '8px'
		},
		dialogButtons :
		{
			width          : '100%',
			marginTop      : '15px',
			display        : 'flex',
			justifyContent : 'center'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiInput : {
			root : {
				backgroundColor : '#666666'
			},
			input : {
				backgroundColor  : '#FFF',
				'&.Mui-disabled' : {
					backgroundColor : '#777777'
				}
			},
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		}
	}
});

const EditSkillGroupDialog = ({
	classes,
	show,
	skillGroup,
	newItem,
	skillGroups,
	updateSkillGroup,
	addSkillGroup,
	subdomain,
	closeMethod
}) =>
{
	const [ errors, setErrors ] = useState({
		show   : false,
		errors : []
	});

	const [ skillId, setSkillId ] = useState('');
	const [ skillName, setSkillName ] = useState('');
	const [ iconText, setIconText ] = useState('');
	const [ showOwnLobby, setShowOwnLobby ] = useState(false);
	const [ browserTitle, setBrowserTitle ] = useState('');
	const [ btnText, setBtnText ] = useState('');
	const [ btnColor, setBtnColor ] = useState('');
	const [ btnPadding, setBtnPadding ] = useState('0.8vh 2vw');

	// BG File for PC
	const [ uploadBgPCFile, setUploadBgPCFile ] = useState();
	const [ bgPCUrl, setBgPCUrl ] = useState('');

	// BG File for SP
	const [ uploadBgSPFile, setUploadBgSPFile ] = useState();
	const [ bgSPUrl, setBgSPUrl ] = useState('');

	// background
	const [ bgPCGradient, setBgPCGradient ] = useState('#EEEEEE, #EEEEEE');
	const [ bgSPGradient, setBgSPGradient ] = useState('#EEEEEE, #EEEEEE');

	const [ btnPositionObj, setBtnPositionObj ] = useState({
		PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
		SP : { top: '49', bottom: '', verticCenter: false }
	});

	// buttonSizeSetting
	const [ btnPosDirectionSelector, setBtnPosDirectionSelector ] = useState({ verticalPC: 'top', horizontalPC: 'left', verticalSP: 'top' });

	// worktime 
	const [ customizedWorkTime, setCustomizedWorkTime ] = useState(false);
	// Holiday
	const [ holiday, setHoliday ] = useState([ 0, 0, 0, 0, 0, 0, 0, 0 ]);
	const [ nationalHoliday, setNationalHoliday ] = useState(0);
	// OPEN/CLOSE TIME
	const [ openTime, setOpenTime ] = useState('10:00');
	const [ closeTime, setCloseTime ] = useState('18:00');

	const errorRef = useRef();

	useEffect(() =>
	{
		if (newItem)
		{
			setBtnColor('F15A24');
		}
		else
		{
			setSkillId(skillGroup.skillId ? skillGroup.skillId : '');
			setSkillName(skillGroup.skillName ? skillGroup.skillName : '');
			setIconText(skillGroup.iconText ? skillGroup.iconText : '');
			setShowOwnLobby(skillGroup.showOwnLobby ? true : false);
			setBrowserTitle(skillGroup.browserTitle ? skillGroup.browserTitle : '');
			setBtnText(skillGroup.buttonText ? skillGroup.buttonText : '');
			setBtnColor(skillGroup.buttonColor ? skillGroup.buttonColor : '');
			setBtnPadding(skillGroup.buttonPadding ? skillGroup.buttonPadding : '0.8vh 2vw');
			setBgPCGradient(skillGroup.bgPCGradient ? skillGroup.bgPCGradient : '#EEEEEE, #EEEEEE');
			setBgSPGradient(skillGroup.bgSPGradient ? skillGroup.bgSPGradient : '#EEEEEE, #EEEEEE');
			setBgPCUrl(skillGroup.bgPCUrl ? skillGroup.bgPCUrl : '');
			setBgSPUrl(skillGroup.bgSPUrl ? skillGroup.bgSPUrl : '');
			setCustomizedWorkTime(
				skillGroup.customizedWorkTime ? skillGroup.customizedWorkTime : false
			);
			setHoliday(
				skillGroup.holiday ? skillGroup.holiday : [ 0, 0, 0, 0, 0, 0, 0, 0 ]
			);
			setNationalHoliday(
				skillGroup.nationalHoliday ? skillGroup.nationalHoliday : 0
			);
			setOpenTime(
				skillGroup.openTime ? skillGroup.openTime : '10:00'
			);
			setCloseTime(
				skillGroup.closeTime ? skillGroup.closeTime : '18:00'
			);
			setBtnPositionObj(
				skillGroup.btnPosition && skillGroup.btnPosition.PC && skillGroup.btnPosition.SP
					? skillGroup.btnPosition
					: {
						PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
						SP : { top: '49', bottom: '', verticCenter: false }
					});
			setBtnPosDirectionSelector(() =>
			{
				let verticalPCVal = 'top';

				let horizontalPCVal = 'left';

				let verticalSPVal = 'top';

				if (skillGroup.btnPosition
					&& skillGroup.btnPosition.PC
					&& skillGroup.btnPosition.SP
				)
				{
					if (skillGroup.btnPosition.PC.bottom)
					{
						verticalPCVal = 'bottom';
					}
					else if (skillGroup.btnPosition.PC.verticCenter)
					{
						verticalPCVal = 'center';
					}

					if (skillGroup.btnPosition.PC.right)
					{
						horizontalPCVal = 'right';
					}
					else if (skillGroup.btnPosition.PC.horizCenter)
					{
						horizontalPCVal = 'center';
					}

					if (skillGroup.btnPosition.SP.bottom)
					{
						verticalSPVal = 'bottom';
					}
					else if (skillGroup.btnPosition.SP.verticCenter)
					{
						verticalSPVal = 'center';
					}
				}

				return {
					verticalPC   : verticalPCVal,
					horizontalPC : horizontalPCVal,
					verticalSP   : verticalSPVal
				};
			});
		}

	}, [ newItem, skillGroup ]);

	const handleChangeSkillId = (e) =>
	{
		setSkillId(e.target.value);
	};

	const handleChangeSkillIName = (e) =>
	{
		setSkillName(e.target.value);
	};

	const handleChangeIconText = (e) =>
	{
		setIconText(e.target.value);
	};

	const handleChangeShowOwnLobby = (e) =>
	{
		setShowOwnLobby(e.target.value ? true : false);
	};

	const handleChangeBrowserTitle = (e) =>
	{
		setBrowserTitle(e.target.value);
	};

	const handleChangeBtnText = (e) =>
	{
		setBtnText(e.target.value);
	};

	const handleChangeBtnColor = (e) =>
	{
		const value = e.target.value;

		if (value.length <= 6)
		{
			setBtnColor(value);
		}
	};

	const handleChangeBtnPadding = (e) =>
	{
		setBtnPadding(e.target.value);
	};

	const handleChangeCustomizedWorkTime = (e) =>
	{
		setCustomizedWorkTime(e.target.value);
	};

	const handleChangeHolidayCallback = (day) =>
	{

		if (holiday[day] === 1)
		{
			holiday[day] = 0;
		}
		else
		{
			holiday[day] = 1;
		}
		setHoliday([ ...holiday ]);
	};

	const handleChangeNationalHolidayCallback = (e) =>
	{
		setNationalHoliday(Number(e.target.value));
	};

	const onDropBgPC = useCallback((acceptedFiles) =>
	{
		if (acceptedFiles.length > 0)
		{
			const src = URL.createObjectURL(acceptedFiles[0]);

			setBgPCUrl(src);
			setUploadBgPCFile(acceptedFiles[0]);
		}

	}, []);

	const onDropBgSP = useCallback((acceptedFiles) =>
	{

		if (acceptedFiles.length > 0)
		{
			const src = URL.createObjectURL(acceptedFiles[0]);

			setBgSPUrl(src);
			setUploadBgSPFile(acceptedFiles[0]);
		}

	}, []);

	const handleChangeBtnPositionObj = (screen, prop, value) =>
	{
		setBtnPositionObj((state) =>
		{
			const updatedState = { ...state };

			updatedState[screen][prop] = value;

			return {
				...updatedState
			};

		});
	};

	const uploadBgPC = async () =>
	{
		let url = bgPCUrl ? bgPCUrl : '';

		if (uploadBgPCFile && uploadBgPCFile.name)
		{
			const storageref = storage.ref(`/images/${subdomain}/skillGroups/${skillId}/logo_${uploadBgPCFile.name}`);
			const snapshot = await storageref.put(uploadBgPCFile);

			url = await snapshot.ref.getDownloadURL();
			setBgPCUrl(url);
		}

		return url;
	};

	const uploadBgSP = async () =>
	{
		let url = bgSPUrl ? bgSPUrl : '';

		if (uploadBgSPFile && uploadBgSPFile.name)
		{
			const storageref = storage.ref(`/images/${subdomain}/skillGroups/${skillId}/logo_${uploadBgSPFile.name}`);
			const snapshot = await storageref.put(uploadBgSPFile);

			url = await snapshot.ref.getDownloadURL();
			setBgSPUrl(url);
		}

		return url;
	};

	const handleChangeBtnPosDirection = (prop, val) =>
	{

		// set value
		setBtnPosDirectionSelector((state) =>
		{
			const stateToUpdate = { ...state };

			stateToUpdate[prop] = val;

			return { ...stateToUpdate };
		});

		if (val === 'center')
		{
			if (prop==='verticalPC')
			{

				setBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.top = '';
					stateToUpdate.PC.bottom = '';
					stateToUpdate.PC.verticCenter = true;

					return { ...stateToUpdate };
				});
			}
			else if (prop==='horizontalPC')
			{
				setBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.left = '';
					stateToUpdate.PC.right = '';
					stateToUpdate.PC.horizCenter = true;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.top = '';
					stateToUpdate.SP.bottom = '';
					stateToUpdate.SP.verticCenter = true;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'top')
		{
			if (prop==='verticalPC')
			{

				setBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.top = '50';
					stateToUpdate.PC.bottom = '';
					stateToUpdate.PC.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.top = '50';
					stateToUpdate.SP.bottom = '';
					stateToUpdate.SP.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'bottom')
		{
			if (prop==='verticalPC')
			{

				setBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.bottom = '50';
					stateToUpdate.PC.top = '';
					stateToUpdate.PC.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.bottom = '50';
					stateToUpdate.SP.top = '';
					stateToUpdate.SP.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'left')
		{
			if (prop==='horizontalPC')
			{

				setBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.left = '50';
					stateToUpdate.PC.right = '';
					stateToUpdate.PC.horizCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'right')
		{
			if (prop==='horizontalPC')
			{

				setBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.right = '50';
					stateToUpdate.PC.left = '';
					stateToUpdate.PC.horizCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
	};

	const handleSetSkillGroup = async () =>
	{
		const errorsAr = [];

		if (!skillId)
		{
			errorsAr.push('スキルIDを入力してください');
		}
		else
		{
			const skillIdReg = new RegExp(/^[a-z0-9-_]+$/);

			if (!skillIdReg.test(skillId))
			{
				errorsAr.push('スキルIDに使用できるのは半角英数字と-_だけです');
			}
		}

		if (newItem)
		{
			const skillGroupIndex = skillGroups.findIndex((lb) => lb.skillId === skillId);

			if (skillGroupIndex !== -1)
			{
				errorsAr.push('スキルIDが重複しています');
			}
		}

		if (!skillName)
		{
			errorsAr.push('スキル名を入力してください');
		}

		// eslint-disable-next-line
		const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

		if (errorsAr.length === 0)
		{
			let bgPCUrlVal = '';

			let bgSPUrlVal = '';

			if (showOwnLobby)
			{
				bgPCUrlVal = await uploadBgPC();
				bgSPUrlVal = await uploadBgSP();
			}

			if (newItem)
			{
				addSkillGroup({
					skillId            : skillId.trim(),
					skillName          : skillName.trim(),
					iconText           : iconText.trim(),
					showOwnLobby       : showOwnLobby,
					browserTitle       : browserTitle.trim(),
					buttonText         : btnText,
					buttonColor        : btnColor,
					buttonPadding      : btnPadding,
					bgPCUrl            : bgPCUrlVal,
					bgSPUrl            : bgSPUrlVal,
					btnPosition        : btnPositionObj,
					bgPCGradient       : bgPCGradient,
					bgSPGradient       : bgSPGradient,
					customizedWorkTime : customizedWorkTime,
					holiday            : holiday,
					nationalHoliday    : nationalHoliday,
					openTime           : openTime,
					closeTime          : closeTime

				});
			}
			else
			{
				updateSkillGroup({
					skillId       : skillId.trim(),
					skillName     : skillName.trim(),
					iconText      : iconText.trim(),
					showOwnLobby  : showOwnLobby,
					browserTitle  : browserTitle.trim(),
					buttonText    : btnText,
					buttonColor   : btnColor,
					buttonPadding : btnPadding,
					bgPCUrl       : bgPCUrlVal,
					bgSPUrl       : bgSPUrlVal,
					btnPosition   : btnPositionObj,
					bgPCGradient  : bgPCGradient,
					bgSPGradient  : bgSPGradient
				}, skillId.trim());
			}

			closeMethod();
		}
		else
		{
			setErrors({
				show   : true,
				errors : [ ...errorsAr ]
			});

			setTimeout(() =>
			{
				try
				{
					if (errorRef.current)
					{
						errorRef.current.scrollIntoView(false);
					}
				}
				catch
				{
					// do nothing
				}
			}, 200);

		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={show}
				onClose={() => {}}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle id='form-dialog-title'>
					<div className={classes.titleSection}>
						<div className={classes.title}>
							<div className={classes.titleText}>
								スキルグループ設定
							</div>
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					{errors.show === true &&
					<Box className={classes.errorBox}>
						{errors.errors.map((err, index) =>
							(<Typography className={classes.errMsg} key={index} ref={errorRef}>
								・{err}
							</Typography>)
						)}
					</Box>
					}

					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>スキルID</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={skillId}
								className={`${classes.inputField}`}
								onChange={handleChangeSkillId}
								type='text'
								disabled={!newItem}
							/>
						</Box>
					</Box>

					<Box className={`${classes.topData}`}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>スキル名</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={skillName}
								className={`${classes.inputField}`}
								onChange={handleChangeSkillIName}
								type='text'
							/>
						</Box>
					</Box>

					<Box className={`${classes.topData}`}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>アイコンテキスト</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={iconText}
								className={`${classes.inputField}`}
								onChange={handleChangeIconText}
								type='text'
							/>
						</Box>
					</Box>

					<Box className={`${classes.topData}`}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>ロビーページ表示</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<Select
								classes={{ root: classes.inputListSmall }}
								value={showOwnLobby}
								onChange={handleChangeShowOwnLobby}
							>
								<MenuItem value>表示</MenuItem>
								<MenuItem value={false}>非表示</MenuItem>
							</Select>
						</Box>
					</Box>

					<Box className={`${classes.topData}`}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>ブラウザタイトル</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={browserTitle}
								disabled={!showOwnLobby}
								className={`${classes.inputField}`}
								onChange={handleChangeBrowserTitle}
								type='text'
							/>
						</Box>
					</Box>

					<Box className={`${classes.topData}`}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>ボタンカラー</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={btnColor}
								disabled={!showOwnLobby}
								className={`${classes.inputField}`}
								onChange={handleChangeBtnColor}
								type='text'
							/>
						</Box>
					</Box>
					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>ボタンテキスト</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={btnText}
								disabled={!showOwnLobby}
								className={`${classes.inputField}`}
								onChange={handleChangeBtnText}
								type='text'
							/>
						</Box>
					</Box>

					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>ボタン内部余白(padding)</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={btnPadding}
								disabled={!showOwnLobby}
								className={`${classes.inputField}`}
								onChange={handleChangeBtnPadding}
								type='text'
							/>
						</Box>
					</Box>

					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>PC 縦ボタン位置</Typography>
						</Box>
						<Box className={classes.settingLineStart} >
							<Box className={classes.buttonPosInput}>
								<Select
									classes={{ root: classes.inputListSmall }}
									value={btnPosDirectionSelector.verticalPC}
									disabled={!showOwnLobby}
									onChange={(e) => handleChangeBtnPosDirection('verticalPC', e.target.value)}
								>
									<MenuItem value={'top'}>上(%)</MenuItem>
									<MenuItem value={'bottom'}>下(%)</MenuItem>
									<MenuItem value={'center'}>中央揃え</MenuItem>
								</Select>
							</Box>

							<Box>
								<input
									value={btnPositionObj.PC.verticCenter ? '' : btnPositionObj['PC'][btnPosDirectionSelector.verticalPC]}
									disabled={btnPosDirectionSelector.verticalPC === 'center' || !showOwnLobby}
									className={classes.inputFieldBtnPos}
									onChange={(e) => handleChangeBtnPositionObj(
										'PC',
										btnPosDirectionSelector.verticalPC,
										e.target.value
									)}
									type='number'
									min='0'
									max='100'
								/>
							</Box>
						</Box>
					</Box>

					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>PC 横ボタン位置</Typography>
						</Box>
						<Box className={classes.settingLineStart} >
							<Box className={classes.buttonPosInput}>
								<Select
									classes={{ root: classes.inputListSmall }}
									value={btnPosDirectionSelector.horizontalPC}
									disabled={!showOwnLobby}
									onChange={(e) => handleChangeBtnPosDirection('horizontalPC', e.target.value)}
								>
									<MenuItem value={'left'}>左(%)</MenuItem>
									<MenuItem value={'right'}>右(%)</MenuItem>
									<MenuItem value={'center'}>中央揃え</MenuItem>
								</Select>
							</Box>

							<Box>
								<input
									value={btnPositionObj.PC.horizCenter ? '' : btnPositionObj['PC'][btnPosDirectionSelector.horizontalPC]}
									disabled={btnPosDirectionSelector.horizontalPC === 'center' || !showOwnLobby}
									className={classes.inputFieldBtnPos}
									onChange={(e) => handleChangeBtnPositionObj(
										'PC',
										btnPosDirectionSelector.horizontalPC,
										e.target.value
									)}
									type='number'
									min='0'
									max='100'
								/>
							</Box>
						</Box>
					</Box>

					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>PC 背景グラディエーション</Typography>
						</Box>
						<Box>
							<input
								placeholder='#FFF, #FFF'
								value={bgPCGradient}
								disabled={!showOwnLobby}
								className={`${classes.inputField} ${classes.fullWidth}`}
								onChange={(e) => setBgPCGradient(e.target.value)}
								type='text'
							/>
						</Box>
					</Box>

					{ showOwnLobby &&
					<Box className={classes.screen}>
						<Box className={classes.imageNameGroup}>
							<ImageIcon className={classes.imageIcon}/>
							<Box className={classes.imageBorder}>
								<Typography className={classes.imageName}>
									PC 待受け背景ファイル
								</Typography>
								<Dropzone onDrop={onDropBgPC} className={classes.imageName}>
									{ ({ getRootProps, getInputProps }) => (
										<section>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												{uploadBgPCFile ?
													<Typography>{uploadBgPCFile.name}</Typography>
													:
													<Typography>ドラッグ or クリック</Typography>
												}
											</div>
										</section>
									)}
								</Dropzone>
							</Box>
						</Box>
						<Box className={classes.imageBox}>
							{ bgPCUrl &&
							<img
								alt='logo'
								src={bgPCUrl}
								height='90%'
								width='90%'
							/>
							}
						</Box>
					</Box>
					}

					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>スマホ 縦ボタン位置</Typography>
						</Box>
						<Box className={classes.settingLineStart} >

							<Box className={classes.buttonPosInput}>
								<Select
									classes={{ root: classes.inputListSmall }}
									value={btnPosDirectionSelector.verticalSP}
									disabled={!showOwnLobby}
									onChange={(e) => handleChangeBtnPosDirection('verticalSP', e.target.value)}
								>
									<MenuItem value={'top'}>上(%)</MenuItem>
									<MenuItem value={'bottom'}>下(%)</MenuItem>
									<MenuItem value={'center'}>中央揃え</MenuItem>
								</Select>
							</Box>

							<Box>
								<input
									value={btnPositionObj.SP.verticCenter ? '' : btnPositionObj['SP'][btnPosDirectionSelector.verticalSP]}
									disabled={btnPosDirectionSelector.verticalSP === 'center' || !showOwnLobby}
									className={classes.inputFieldBtnPos}
									onChange={(e) => handleChangeBtnPositionObj(
										'SP',
										btnPosDirectionSelector.verticalSP,
										e.target.value
									)}
									type='number'
									min='0'
									max='100'
								/>
							</Box>
						</Box>
					</Box>

					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>スマホ 背景グラディエーション</Typography>
						</Box>
						<Box>
							<input
								placeholder='#FFF, #FFF'
								value={bgSPGradient}
								disabled={!showOwnLobby}
								className={`${classes.inputField} ${classes.fullWidth}`}
								onChange={(e) => setBgSPGradient(e.target.value)}
								type='text'
							/>
						</Box>
					</Box>
					{ showOwnLobby &&
					<Box className={classes.screen}>
						<Box className={classes.imageNameGroup}>
							<ImageIcon className={classes.imageIcon}/>
							<Box className={classes.imageBorder}>
								<Typography className={classes.imageName}>
									スマホ待受け背景ファイル
								</Typography>
								<Dropzone onDrop={onDropBgSP} className={classes.imageName}>
									{ ({ getRootProps, getInputProps }) => (
										<section>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												{uploadBgSPFile ?
													<Typography>{uploadBgSPFile.name}</Typography>
													:
													<Typography>ドラッグ or クリック</Typography>
												}
											</div>
										</section>
									)}
								</Dropzone>
							</Box>
						</Box>
						<Box className={classes.imageBox}>
							{ bgSPUrl &&
							<img
								alt='logo'
								src={bgSPUrl}
								height='90%'
								width='90%'
							/>
							}
						</Box>
					</Box>
					}
					<Box className={`${classes.topData}`}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>スキルグループ固有の待受時間</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<Select
								classes={{ root: classes.inputListSmall }}
								value={customizedWorkTime}
								onChange={handleChangeCustomizedWorkTime}
							>
								<MenuItem value>ON</MenuItem>
								<MenuItem value={false}>OFF</MenuItem>
							</Select>
						</Box>
					</Box>
					{ customizedWorkTime &&
						<Box className={classes.workingTimeData}>
							<WorkTimeSetting
								holiday={holiday}
								handleChangeHolidayCallback={handleChangeHolidayCallback}
								openTime={openTime}
								setOpenTime={setOpenTime}
								closeTime={closeTime}
								setCloseTime={setCloseTime}
								nationalHoliday={nationalHoliday}
								handleChangeNationalHolidayCallback={
									handleChangeNationalHolidayCallback
								}
							/>
						</Box>
					}
				</DialogContent>
				<DialogActions>
					<Box className={classes.dialogButtons}>
						<Button
							className={classes.cancelBtn}
							onClick={closeMethod}
							color='primary'
						>
							<FormattedMessage
								id='label.close'
								defaultMessage='Close'
							/>
						</Button>
						<Button
							className={classes.promoteBtn}
							onClick={handleSetSkillGroup}
							color='primary'
						>
							設定
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

EditSkillGroupDialog.propTypes =
{
	classes          : PropTypes.object.isRequired,
	show             : PropTypes.bool.isRequired,
	skillGroup       : PropTypes.object,
	subdomain        : PropTypes.string.isRequired,
	newItem          : PropTypes.bool,
	skillGroups      : PropTypes.array,
	updateSkillGroup : PropTypes.func.isRequired,
	addSkillGroup    : PropTypes.func.isRequired,
	closeMethod      : PropTypes.func.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(EditSkillGroupDialog)));
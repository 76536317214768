import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../RoomContext';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = () =>
	({
		root :
		{
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper :
		{
			padding         : '1% 1%',
			width           : 500,
			minHeight       : 500,
			backgroundColor : '#666666'
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleText :
		{
			color    : 'white',
			fontSize : '1.1rem'
		},
		categoryList : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start'
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '100%',
			'&:focus'       : {
				outline : 'none'
			}
		},
		categoryValue : {
			width : '70%'
		},
		categoryValueText : {
			height      : '25px',
			lineHeight  : '25px',
			color       : 'white',
			fontSize    : '0.9rem',
			paddingLeft : '16px'
		},
		smallBtn : {
			padding : '4px 1px'
		},
		addItemBtn : {
			backgroundColor : '#F21818',
			color           : '#FFF',
			'&:hover'       :
			{
				backgroundColor : '#F21818',
				color           : '#FFF',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		deleteItemBtn : {
			backgroundColor : '#9ec317',
			color           : '#FFF',
			'&:hover'       :
			{
				backgroundColor : '#9ec317',
				color           : '#FFF',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		cancelBtn :
		{
			width           : '40%',
			border          : '1px solid #fff',
			backgroundColor : 'transparent',
			color           : 'white'
		},
		topData : {
			width          : '100%',
			height         : '1.75rem',
			display        : 'flex',
			margin         : '1% 0',
			justifyContent : 'space-between',
			lineHeight     : 1
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiBackdrop : {
			root : {
				backgroundColor : 'transparent'
			}
		},
		MuiDialogTitle : {
			root : {
				backgroundColor : 'transparent',
				color           : '#9ec317',
				width           : '100%'
			}
		},
		MuiDialogContent : {
			root : {
				color          : 'white',
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center'
			}
		},
		MuiDialogActions : {
			root : {
				justifyContent : 'space-around'
			}
		},
		MuiTypography : {
			colorTextSecondary : {
				color : 'white'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.6rem'
			}
		}
	}
});

const MemoCategoryialog = ({
	classes,
	show,
	memoCategories,
	setMemoCategories,
	closeMethod
}) =>
{
	const [ newCategoryValue, setNewCategoryValue ] = useState('');

	useEffect(() =>
	{
		if (show)
		{
			setNewCategoryValue('');
		}

	}, [ show ]);

	const deleteCategory = (cat) =>
	{
		setMemoCategories((state) =>
		{
			const newState = [ ...state ];

			return newState.filter((itm) => itm !== cat);
		});
	};

	const addCategory = () =>
	{
		if (newCategoryValue && !memoCategories.includes(newCategoryValue))
		{
			setMemoCategories((state) =>
			{
				return [ ...state, newCategoryValue ];
			});
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={show}
				onClose={() => {}}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle id='form-dialog-title'>
					<div className={classes.title}>
						<div className={classes.titleText}>
							メモカテゴリー一覧
						</div>
					</div>
				</DialogTitle>
				<DialogContent className={classes.categoryList}>
					{ memoCategories && memoCategories.map((cat, index) => (
						<Box className={classes.topData} key={index}>
							<Box className={classes.categoryValue}>
								<Typography className={classes.categoryValueText}>
									{cat}
								</Typography>
							</Box>
							<Button
								className={`${classes.smallBtn} ${classes.deleteItemBtn}`}
								onClick={() => deleteCategory(cat)}
								color='primary'
							>
								削除
							</Button>
						</Box>
					))}

					<Box className={classes.topData}>
						<Box className={classes.categoryValue}>
							<input
								value={newCategoryValue}
								className={`${classes.inputField}`}
								onChange={(e) => setNewCategoryValue(e.target.value)}
								type='text'
							/>
						</Box>
						<Button
							className={`${classes.smallBtn} ${classes.addItemBtn}`}
							onClick={addCategory}
							color='primary'
						>
							追加
						</Button>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.cancelBtn}
						onClick={closeMethod}
						color='primary'
					>
						<FormattedMessage
							id='label.close'
							defaultMessage='Close'
						/>
					</Button>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

MemoCategoryialog.propTypes =
{
	classes           : PropTypes.object.isRequired,
	show              : PropTypes.bool.isRequired,
	memoCategories    : PropTypes.object.isRequired,
	setMemoCategories : PropTypes.func.isRequired,
	closeMethod       : PropTypes.func.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(MemoCategoryialog)));
export const setRoomName = (name) =>
	({
		type    : 'SET_ROOM_NAME',
		payload : { name }

	});

export const setRoomNumber = (number) =>
	({
		type    : 'SET_ROOM_NUMBER',
		payload : { number }
	});

export const setRoomState = (state) =>
	({
		type    : 'SET_ROOM_STATE',
		payload : { state }

	});

export const setRoomActiveSpeaker = (peerId) =>
	({
		type    : 'SET_ROOM_ACTIVE_SPEAKER',
		payload : { peerId }
	});

export const setRoomLocked = () =>
	({
		type : 'SET_ROOM_LOCKED'
	});

export const setRoomUnLocked = () =>
	({
		type : 'SET_ROOM_UNLOCKED'
	});

export const setInLobby = (inLobby) =>
	({
		type    : 'SET_IN_LOBBY',
		payload : { inLobby }
	});

export const setSignInRequired = (signInRequired) =>
	({
		type    : 'SET_SIGN_IN_REQUIRED',
		payload : { signInRequired }
	});

export const setOverRoomLimit = (overRoomLimit) =>
	({
		type    : 'SET_OVER_ROOM_LIMIT',
		payload : { overRoomLimit }
	});

export const setAccessCode = (accessCode) =>
	({
		type    : 'SET_ACCESS_CODE',
		payload : { accessCode }
	});

export const setJoinByAccessCode = (joinByAccessCode) =>
	({
		type    : 'SET_JOIN_BY_ACCESS_CODE',
		payload : { joinByAccessCode }
	});

export const setSettingsOpen = (settingsOpen) =>
	({
		type    : 'SET_SETTINGS_OPEN',
		payload : { settingsOpen }
	});

export const setExtraVideoOpen = (extraVideoOpen) =>
	({
		type    : 'SET_EXTRA_VIDEO_OPEN',
		payload : { extraVideoOpen }
	});

export const setRolesManagerOpen = (rolesManagerOpen) =>
	({
		type    : 'SET_ROLES_MANAGER_OPEN',
		payload : { rolesManagerOpen }
	});

export const setRolesManagerPeer = (rolesManagerPeer) =>
	({
		type    : 'SET_ROLES_MANAGER_PEER',
		payload : { rolesManagerPeer }
	});

export const setHelpOpen = (helpOpen) =>
	({
		type    : 'SET_HELP_OPEN',
		payload : { helpOpen }
	});

export const setAboutOpen = (aboutOpen) =>
	({
		type    : 'SET_ABOUT_OPEN',
		payload : { aboutOpen }
	});

export const setSettingsTab = (tab) =>
	({
		type    : 'SET_SETTINGS_TAB',
		payload : { tab }
	});

export const setLockDialogOpen = (lockDialogOpen) =>
	({
		type    : 'SET_LOCK_DIALOG_OPEN',
		payload : { lockDialogOpen }
	});

export const setOnCallDialogOpen = (callDialogOpen) =>
	({
		type    : 'SET_ON_CALL_DIALOG_OPEN',
		payload : { callDialogOpen }
	});

export const setCalledSkillIName = (calledSkillIName) =>
	({
		type    : 'SET_CALLED_LOBBY_NAME',
		payload : { calledSkillIName }
	});

export const setCalledOriginName = (calledOriginName) =>
	({
		type    : 'SET_CALLED_ORIGIN_NAME',
		payload : { calledOriginName }
	});

export const setOnSupportCallDialogOpen = (supportCallDialogOpen) =>
	({
		type    : 'SET_ON_SUPPORT_CALL_DIALOG_OPEN',
		payload : { supportCallDialogOpen }
	});

export const setOnMemoDialogOpen = (memoDialogOpen) =>
	({
		type    : 'SET_ON_MEMO_DIALOG_OPEN',
		payload : { memoDialogOpen }
	});

export const setSaveMemoAtStart = (flag) =>
	({
		type    : 'SET_MEMO_SAVE_AT_START',
		payload : { flag }
	});

export const setSaveMemoAtLeaving = (flag) =>
	({
		type    : 'SET_SAVE_MEMO_AT_LEAVING',
		payload : { flag }
	});

export const setFileSharingSupported = (supported) =>
	({
		type    : 'FILE_SHARING_SUPPORTED',
		payload : { supported }
	});

export const toggleConsumerWindow = (consumerId) =>
	({
		type    : 'TOGGLE_WINDOW_CONSUMER',
		payload : { consumerId }
	});

export const setToolbarsVisible = (toolbarsVisible) =>
	({
		type    : 'SET_TOOLBARS_VISIBLE',
		payload : { toolbarsVisible }
	});

export const setDisplayMode = (mode) =>
	({
		type    : 'SET_DISPLAY_MODE',
		payload : { mode }
	});

export const addSelectedPeer = (peerId) =>
	({
		type    : 'ADD_SELECTED_PEER',
		payload : { peerId }
	});

export const removeSelectedPeer = (peerId) =>
	({
		type    : 'REMOVE_SELECTED_PEER',
		payload : { peerId }
	});

export const clearSelectedPeers = () =>
	({
		type : 'CLEAR_SELECTED_PEERS'
	});

export const setSpotlights = (spotlights) =>
	({
		type    : 'SET_SPOTLIGHTS',
		payload : { spotlights }
	});

export const clearSpotlights = () =>
	({
		type : 'CLEAR_SPOTLIGHTS'
	});

export const toggleJoined = () =>
	({
		type : 'TOGGLE_JOINED'
	});

export const toggleConsumerFullscreen = (consumerId) =>
	({
		type    : 'TOGGLE_FULLSCREEN_CONSUMER',
		payload : { consumerId }
	});

export const setWatchShareScreen = (flag) =>
	({
		type    : 'SET_WATCH_SHARE_SCREEN',
		payload : { flag }
	});

export const setLobbyPeersPromotionInProgress = (flag) =>
	({
		type    : 'SET_LOBBY_PEERS_PROMOTION_IN_PROGRESS',
		payload : { flag }
	});

export const incrementLobbyPeersLeft = () =>
	({
		type : 'INCREMENT_LOBBY_PEERS_LEFT'
	});

export const setMuteAllInProgress = (flag) =>
	({
		type    : 'MUTE_ALL_IN_PROGRESS',
		payload : { flag }
	});

export const setStopAllVideoInProgress = (flag) =>
	({
		type    : 'STOP_ALL_VIDEO_IN_PROGRESS',
		payload : { flag }
	});

export const setStopAllScreenSharingInProgress = (flag) =>
	({
		type    : 'STOP_ALL_SCREEN_SHARING_IN_PROGRESS',
		payload : { flag }
	});

export const setCloseMeetingInProgress = (flag) =>
	({
		type    : 'CLOSE_MEETING_IN_PROGRESS',
		payload : { flag }
	});

export const setClearChatInProgress = (flag) =>
	({
		type    : 'CLEAR_CHAT_IN_PROGRESS',
		payload : { flag }
	});

export const setClearFileSharingInProgress = (flag) =>
	({
		type    : 'CLEAR_FILE_SHARING_IN_PROGRESS',
		payload : { flag }
	});

export const setRoomPermissions = (roomPermissions) =>
	({
		type    : 'SET_ROOM_PERMISSIONS',
		payload : { roomPermissions }
	});

export const setUserRoles = (userRoles) =>
	({
		type    : 'SET_USER_ROLES',
		payload : { userRoles }
	});

export const setAllowWhenRoleMissing = (allowWhenRoleMissing) =>
	({
		type    : 'SET_ALLOW_WHEN_ROLE_MISSING',
		payload : { allowWhenRoleMissing }
	});

export const setHideSelfView = (hideSelfView) =>
	({
		type    : 'SET_HIDE_SELF_VIEW',
		payload : { hideSelfView }
	});

export const setAccessWarningDialogOpen = (accessWarningDialogOpen) =>
	({
		type    : 'SET_ACCESS_WARNING_DIALOG_OPEN',
		payload : { accessWarningDialogOpen }
	});

export const setHomeJoinDialogOpen = (homeJoinDialogOpen) =>
	({
		type    : 'SET_HOME_JOIN_DIALOG_OPEN',
		payload : { homeJoinDialogOpen }
	});

export const setInviteDialogOpen = (inviteDialogOpen, invitedRoomId) =>
	({
		type    : 'SET_INVITE_DIALOG_OPEN',
		payload : { inviteDialogOpen, invitedRoomId }
	});

export const setRoomInfo = (info) =>
	({
		type    : 'SET_ROOM_INFO',
		payload : { info }
	});

export const setRoomCall = (call) =>
	({
		type    : 'SET_ROOM_CALL',
		payload : { call }
	});

export const setReciprocalContentDialogOpen = (reciprocalContentDialogOpen) =>
	({
		type    : 'SET_RECIPROCAL_CONTENT_DIALOG_OPEN',
		payload : { reciprocalContentDialogOpen }
	});

export const setGroupChats = (groupChatHistory) =>
	({
		type    : 'SET_GROUP_CHAT',
		payload : { groupChatHistory }
	});

export const setMemo = (memo) =>
	({
		type    : 'SET_MEMO',
		payload : { memo }
	});

export const updateMemo = (newMemo) =>
	({
		type    : 'UPDATE_MEMO',
		payload : { newMemo }
	});

export const setCurrentMemo = (currentMemo) =>
	({
		type    : 'SET_CURRENT_MEMO',
		payload : { currentMemo }
	});

export const setMemos = (memos) =>
	({
		type    : 'SET_MEMOS',
		payload : { memos }
	});

export const setSaveCurrentMemoState = (flag) =>
	({
		type    : 'SAVE_CURRENT_MEMO_STATE',
		payload : { flag }
	});

export const setCalls = (calls) =>
	({
		type    : 'SET_CALLS',
		payload : { calls }
	});

export const setOrientation = (orientation) =>
	({
		type    : 'SET_ORIENTATION',
		payload : { orientation }
	});

export const setJoinRoomNoticeDialogOpen = (joinRoomNoticeDialogOpen) =>
	({
		type    : 'SET_NOTICE_JOIN_ROOM_DIALOG_OPEN',
		payload : { joinRoomNoticeDialogOpen }
	});

export const setJoiningByDirectMode = (joiningByDirectMode) =>
	({
		type    : 'SET_JOINING_BY_DIRECT_MODE',
		payload : { joiningByDirectMode }
	});

export const setIsLoading = (isLoading) =>
	({
		type    : 'SET_IS_LOADING',
		payload : { isLoading }
	});

export const setRoomCallStartTime = (callStartTime) =>
	({
		type    : 'SET_CALL_START_TIME',
		payload : { callStartTime }
	});

export const setMakeCallEnd = (bool) =>
	({
		type    : 'SET_MAKE_CALL_END',
		payload : bool
	});

export const incrementRecievedChats = () =>
	({
		type : 'INCREMENT_RECIEVED_CHATS'
	});

export const resetNumOfRecievedChats = () =>
	({
		type : 'RESET_NUM_OF_RECIEVED_CHATS'
	});

export const incrementMonitoringChats = () =>
	({
		type : 'INCREMENT_MONITORING_CHATS'
	});

export const resetNumOfMonitoringChats = () =>
	({
		type : 'RESET_NUM_OF_MONITORING_CHATS'
	});

export const setHideGuestButtomNavi = (hideGuestBottomNavi) =>
	({
		type    : 'SET_HIDE_GUEST_BOTTOM_NAVI',
		payload : { hideGuestBottomNavi }
	});

export const resetRoom = () =>
	({
		type : 'RESET_ROOM'
	});

export const resetRoomAtLeavingRoom = () =>
	({
		type : 'RESET_ROOM_AT_LEAVING_ROOM'
	});

export const setHostName = (val) =>
	({
		type    : 'SET_HOST_NAME',
		payload : val
	});

export const setMonitoring = (val) =>
	({
		type    : 'SET_MONITORING',
		payload : val
	});

export const setMonitoringStartAt = (val) =>
	({
		type    : 'SET_MONITORING_START_AT',
		payload : val
	});

export const setMonitoringTimer = (val) =>
	({
		type    : 'SET_MONITORING_TIMER',
		payload : val
	});

export const incrementMonitoringTimer = () =>
	({
		type : 'INCREMENT_MONITORING_TIMER'
	});

export const setClientType = (val) =>
	({
		type    : 'SET_CLIENT_TYPE',
		payload : val
	});

export const setVideoHostId = (videoHostId) =>
	({
		type    : 'SET_VIDEO_HOST_ID',
		payload : { videoHostId }
	});

export const setJoinRequestDialogOpen = (flag) =>
	({
		type    : 'SET_JOIN_REQUEST_DIALOG_OPEN',
		payload : { flag }
	});

export const setJoinRequestInfo = (info) =>
	({
		type    : 'SET_JOIN_REQUEST_INFO',
		payload : { info }
	});

export const setJoinRequestAccepted = (flag) =>
	({
		type    : 'SET_JOIN_REQUEST_ACCEPTED',
		payload : { flag }
	});

export const setJoinMeetingRequestAccepted = (flag) =>
	({
		type    : 'SET_JOIN_MEETING_REQUEST_ACCEPTED',
		payload : { flag }
	});

export const setJoinAcceptDialogOpen = (flag) =>
	({
		type    : 'SET_JOIN_ACCEPT_DIALOG_OPEN',
		payload : { flag }
	});

export const setJoinAcceptInfo = (info) =>
	({
		type    : 'SET_JOIN_ACCEPT_INFO',
		payload : { info }
	});

export const setGoBackToPrevRoom = (obj) =>
	({
		type    : 'SET_GO_BACK_TO_PREV_ROOM',
		payload : { obj }
	});

export const setScreenShareSize = (val) =>
	({
		type    : 'SET_SCREEN_SHARE_SIZE',
		payload : val
	});

export const setMemoChatDialogState = (obj) =>
	({
		type    : 'SET_MEMO_CHAT_DIALOG_STATE',
		payload : obj
	});

export const setUnreadMemoComments = (num) =>
	({
		type    : 'SET_UNREAD_MEMO_COMMENTS',
		payload : num
	});

export const setInvitingSubhost = (flag) =>
	({
		type    : 'SET_INVITING_SUBHOST',
		payload : { flag }
	});

export const setShowHungUpView = (flag) =>
	({
		type    : 'SET_SHOW_HUNG_UP_VIEW',
		payload : { flag }
	});

export const setPersonalCallTargetName = (personalCallTargetName) =>
	({
		type    : 'SET_PERSINAL_CALL_TARGET_NAME',
		payload : { personalCallTargetName }
	});

export const setGuestNotificationDialog = (guestNotificationDialogState) =>
	({
		type    : 'SET_GUEST_NOTIFICATION_DIALOG',
		payload : { guestNotificationDialogState }
	});

export const closeGuestNotificationDialog = () =>
	({
		type : 'CLOSE_GUEST_NOTIFICATION_DIALOG'
	});

export const setServiceGuest = (serviceGuest) =>
	({
		type    : 'SET_SERVICE_GUEST',
		payload : { serviceGuest }
	});

export const setConnectionGuest = (connectionGuest) =>
	({
		type    : 'SET_CONNECTION_GUEST',
		payload : { connectionGuest }
	});

export const setQueueDialogOpen = (val) =>
	({
		type    : 'SET_QUEUE_DIALOG_OPEN',
		payload : { val }

	});

export const setQueueInfos = (obj) =>
	({
		type    : 'SET_INVITE_QUEUE_INFOS',
		payload : { obj }

	});

export const setCheckingQueue = (flag) =>
	({
		type    : 'SET_CHECKING_QUEUE',
		payload : { flag }
	});
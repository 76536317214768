import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';

// Mui
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dropzone from 'react-dropzone';

// components 
import ConnectStatusBar from '../Parts/ConnectStatusBar';

// util 
import { getDomainWithoutSubdomain } from '../../../../utils';

// dayjs
import dayjs from 'dayjs';
import CloudUpload from '@material-ui/icons/CloudUpload';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const styles = () =>
	({
		root :
		{
			width          : '100%',
			height         : '100%',
			minWidth       : '300px',
			position       : 'relative',
			color          : 'var(--text-color)',
			overflowX      : 'auto',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'

		},
		content : {
			maxWidth        : '1100px',
			minWidth        : '300px',
			width           : '100%',
			height          : '100%',
			backgroundColor : '#FFF'
		},
		section : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			margin         : '15px 0'
		},
		headerTitle : {
			fontSize    : '1.5rem',
			width       : '100%',
			paddingLeft : '10px',
			color       : '#231815'
		},
		dropzone : {
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			height          : '300px',
			backgroundColor : '#FAFAFA',
			width           : '90%',
			maxWidth        : '800px',
			color           : 'var(--text-color)',
			fontSize        : '1rem',
			border          : 'dashed 2px var(--text-color)',
			marginTop       : '20px'
		},
		dropzoneContent : {
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center',
			width          : '100%',
			height         : '100%'
		},
		dropzoneIcon : {
			height : '50px',
			width  : '50px',
			color  : 'var(--text-color)'
		},
		dropzoneBtn : {
			color           : 'var(--text-color)',
			border          : '1px solid var(--text-color)',
			backgroundColor : '#FFF',
			padding         : '2px 5px',
			'&:hover'       : {
				filter : 'saturate(105%) brightness(105%)'
			}
		},
		dropzoneTextPointer : {
			cursor : 'pointer'
		},
		table : {
			margin : '15px 0'
		},
		actionBtn : {
			color           : '#FFF',
			border          : '1px solid #00B79C',
			backgroundColor : '#00B79C',
			padding         : '4px 15px',
			'&:hover'       : {
				backgroundColor : '#00B79C',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		backBtn : {
			color           : '#FFF',
			border          : '1px solid #989797',
			backgroundColor : '#989797',
			padding         : '3px 20px',
			'&:hover'       : {
				backgroundColor : '#989797',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		errorMsg : {
			width        : '100%',
			height       : '1.2rem',
			color        : 'red',
			marginBottom : '8px',
			fontSize     : '0.8rem',
			textAlign    : 'center'
		},
		loading : {
			width           : '100vw',
			height          : '100vh',
			zIndex          : 9999999,
			position        : 'fixed',
			top             : 0,
			left            : 0,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const domain = getDomainWithoutSubdomain();

const showDate = (timestamp) =>
{
	if (timestamp === 0 || timestamp === '0')
	{
		return '';
	}
	else if (timestamp)
	{
		const dayjsTime = dayjs(timestamp);

		return dayjsTime.format('YYYY.MM.DD H:mm');
	}
	else
	{
		return '';
	}
};

const showConnectionUrl = (paramKey, key) =>
{
	return `https://${domain}/connect?/account=${paramKey}&id=${key}`;
};

const ConnectPurchase = ({
	classes,
	connectAccount
}) =>
{

	const [ loading, setLoading ] = useState(false);

	const [ pageStatus, setPageStatus ] = useState('init');

	const [ fileArray, setFileArray ] = useState([]);

	const [ fileResult, setFileResult ] = useState([]);

	// csv File
	const [ csvFile, setCsvFile ] = useState();

	const [ createdConnections, setCreatedConnections ] = useState([]);

	const csvFileToArray = (string) =>
	{
		const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
		const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

		const array = csvRows.map((row) =>
		{
			const values = row.split(',');

			const obj = {};

			csvHeader.forEach((header, index) =>
			{
				// remove space
				obj[header.trim()] = values[index].trim();
			});

			return obj;
		});

		setFileArray(array);
	};

	const onDropCsv = useCallback((acceptedFiles) =>
	{
		acceptedFiles.forEach((file) =>
		{
			const fileReader = new FileReader();

			fileReader.onload = () =>
			{
				const text = fileReader.result;

				// update files contents
				csvFileToArray(text);
			};

			try
			{
				fileReader.readAsText(file);
			}
			catch (e)
			{
				// eslint-disable-next-line no-console
				console.log('error:', e);
			}

		});
	}, []);

	useEffect(() =>
	{
		if (fileArray && fileArray.length > 0)
		{

			const resultList = [];

			fileArray.forEach((fileItem) =>
			{
				const fileItemId = fileItem['entry_id'] || '';

				const emailValue = fileItem['email'];

				try
				{
					const startValue = Number(fileItem['start']);
					const endValue = Number(fileItem['end']);

					const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

					if (!emailValue)
					{
						resultList.push({ status: 'noEmail', entryId: fileItemId, email: emailValue, start: startValue, end: endValue });
					}
					else if (!emailPattern.test(emailValue))
					{
						resultList.push({ status: 'emailNotCorrect', entryId: fileItemId, email: emailValue, start: startValue, end: endValue });
					}
					else if (!startValue)
					{
						resultList.push({ status: 'noStart', entryId: fileItemId, email: emailValue, start: startValue, end: endValue });
					}
					else
					{
						resultList.push({ status: 'success', entryId: fileItemId, email: emailValue, start: startValue, end: endValue });
					}
				}
				catch
				{
					resultList.push({ status: 'noStart', entryId: fileItemId, email: emailValue, start: fileItem['start'], end: fileItem['end'] });
				}

			});

			setFileResult(resultList);

		}
	}, [ fileArray ]);

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>
				{
					loading ?
						<Box className={classes.loading}>
							<CircularProgress />
						</Box>
						: pageStatus === 'init' ?
							<Box className={classes.content} >
								<Box className={classes.section}>
									<Typography className={classes.headerTitle} variant='h1'>
										CSV読み込み
									</Typography>

									<ConnectStatusBar activeCount={1}/>

									<Box className={classes.section}>

										<Dropzone
											onDrop={onDropCsv}
											accept={{
												'.csv' : []
											}}
										>
											{ ({ getRootProps, getInputProps }) => (
												<section className={classes.dropzone}>
													<div {...getRootProps()}>
														<input {...getInputProps()} />
														{csvFile ?

															<Typography
																className={classes.dropzoneTextPointer}
															>
																{csvFile.name}
															</Typography>
															:
															<Box className={classes.dropzoneContent}>
																<CloudUpload className={classes.dropzoneIcon}/>
																<Typography>CSVファイルをドロップまたは</Typography>
																<Button className={classes.dropzoneBtn}>ファイルを選択</Button>
															</Box>
														}
													</div>
												</section>
											)}
										</Dropzone>

									</Box>

									<Box className={classes.section}>
										<Button className={classes.actionBtn}>
											CSV読み込み
										</Button>
									</Box>
								</Box>
							</Box>
							: pageStatus === 'confirm' ?
								<Box className={classes.content}>
									<Box className={classes.section}>
										<Typography className={classes.headerTitle} variant='h1'>
											ユーザーURL発行の確認
										</Typography>

										<ConnectStatusBar activeCount={2}/>
									</Box>
									<Box className={classes.section}>
										<TableContainer component={Paper}>
											<Table className={classes.table} aria-label='call target table'>
												<TableHead>
													<TableRow>
														<TableCell align='left'>番号</TableCell>
														<TableCell align='left'>顧客番号</TableCell>
														<TableCell align='left'>利用開始日</TableCell>
														<TableCell align='left'>利用終了日</TableCell>
														<TableCell align='left'>メールアドレス</TableCell>
														<TableCell align='left'>ステータス</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{	fileResult.map((item, index) => (
														<TableRow key={index}>
															<TableCell align='left'>
																<Typography>
																	{index+1}
																</Typography>
															</TableCell>
															<TableCell align='left'>
																<Typography>
																	{item.entryId}
																</Typography>
															</TableCell>
															<TableCell align='left'>
																<Typography>
																	{showDate(item.start)}
																</Typography>
															</TableCell>
															<TableCell align='left'>
																<Typography>
																	{showDate(item.end)}
																</Typography>
															</TableCell>
															<TableCell align='left'>
																{item.status === 'success' ? <Typography>成功</Typography>
																	: item.status === 'noEmail' ? <Typography className={classes.errorText}>メールなし</Typography>
																		: item.status === 'noEmail' ? <Typography className={classes.errorText}>メール値エラー</Typography>
																			: item.status === 'noStart' ? <Typography className={classes.errorText}>開始日なし</Typography> : null}
															</TableCell>
														</TableRow>
													))
													}
												</TableBody>
											</Table>
										</TableContainer>
									</Box>

									<Box className={classes.section}>
										<Button className={classes.backBtn}>
											CSV読み込み
										</Button>
										<Button className={classes.actionBtn}>
											ユーザーURL発行
										</Button>
									</Box>
								</Box>
								:
								<Box className={classes.content}>
									<Box className={classes.section}>
										<Typography className={classes.headerTitle} variant='h1'>
											CSVダウンロード
										</Typography>
										<ConnectStatusBar activeCount={3}/>
									</Box>
									<Box className={classes.section}>
										<Box className={classes.table}>
											<TableContainer component={Paper}>
												<Table aria-label='simple table'>
													<TableHead>
														<TableRow>
															<TableCell align='center'>顧客番号</TableCell>
															<TableCell align='center'>利用開始日</TableCell>
															<TableCell align='center'>利用終了日</TableCell>
															<TableCell align='center'>メールアドレス</TableCell>
															<TableCell align='center'>ユーザーURL</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{createdConnections.map((row, index) => (
															<TableRow key={index}>
																<TableCell align='center'>{row.entryId}</TableCell>
																<TableCell align='center'>{showDate(row.expiryTimestamp)}</TableCell>
																<TableCell align='center'>{showDate(row.validTimestamp)}</TableCell>
																<TableCell align='center'>{showConnectionUrl(connectAccount.paramKey, row.connectionKey)}</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
										</Box>
									</Box>
								</Box>
				}
			</Box>
		</MuiThemeProvider>
	);
};

ConnectPurchase.propTypes =
{
	classes        : PropTypes.object.isRequired,
	connectAccount : PropTypes.object
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ConnectPurchase)));
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// Message
import { FormattedMessage } from 'react-intl';

// util 
import { getDomainWithoutSubdomain } from '../../../../utils';

const styles = () =>
	({
		message : {
			color    : 'white',
			fontSize : '1rem',
			padding  : '2px 0'
		},
		messageFirst : {
			margin : '40px 0 15px 0'
		},
		purchaseButton : {
			margin     : '20px 0 40px 0',
			background : '#cd2c82',
			border     : '1px solid #cd2c82',
			color      : '#fff',
			padding    : '4px 25px',
			'&:hover'  :
			{
				backgroundColor : '#fff',
				color           : '#cd2c82',
				opacity         : 0.8
			}
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiBackdrop : {
			root : {
				backgroundColor : 'transparent'
			}
		},
		MuiDialogContent : {
			root : {
				color          : 'white',
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center'
			}
		},
		MuiPaper : {
			rounded : {
				backgroundColor : 'rgba(0, 0, 0, 0.7)',
				borderRadius    : '0.6rem'
			}
		}
	}
});

const NoTalkTimeDialog = ({
	classes,
	open,
	serviceParamKey,
	type
}) =>
{
	const moveToPurchase = () =>
	{

		const domain = getDomainWithoutSubdomain();

		if (type === 'service' && serviceParamKey)
		{
			localStorage.removeItem('serviceConnectionId');
			window.location.replace(`https://${domain}/purchase?id=${serviceParamKey}`);
		}
		else if (type === 'connect')
		{
			window.location.replace(`https://${domain}/connect`);
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				fullWidth
				open={open}
				onClose={() => {}}
				scroll={'paper'}
				aria-labelledby='scroll-dialog-title'
				aria-describedby='scroll-dialog-description'
			>
				<DialogContent className={classes.dialogContent}>

					<Typography className={`${classes.message} ${classes.messageFirst}`}>
						<FormattedMessage
							id='service1.whenUsingService'
							defaultMessage='The number of available minutes has reached 0'
						/>
					</Typography>
					<Typography className={classes.message}>
						<FormattedMessage
							id='service1.whenUsingServiceParagraph1'
							defaultMessage='Please purchase the ticket'
						/>
					</Typography>
					<Typography className={classes.message}>
						<FormattedMessage
							id='service1.whenUsingServiceParagraph2'
							defaultMessage='again to use it'
						/>
					</Typography>

					<Button
						className={classes.purchaseButton}
						onClick={moveToPurchase}
					>
						<FormattedMessage
							id='service.buyTicketButton'
							defaultMessage='Purchase'
						/>
					</Button>

				</DialogContent>
			</Dialog>
		</MuiThemeProvider>
	);
};

NoTalkTimeDialog.propTypes =
{
	classes         : PropTypes.object.isRequired,
	open            : PropTypes.bool.isRequired,
	serviceParamKey : PropTypes.bool.isRequired,
	type            : PropTypes.string.isRequired
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(NoTalkTimeDialog)));
